<template>
  <div class="rounds-map-drawer">
    <v-navigation-drawer
      permanent
      :mini-variant="mini"
      @update:mini-variant="updateMini"
      hide-overlay
      mini-variant-width="152"
      width="485"
      class="elevation-1"
    >
      <v-layout align-center>
        <v-flex pl-2 class="subtitle-1 text--primary">{{ rounds.length }} Tournée(s)</v-flex>
        <v-spacer />
        <v-flex v-show="!mini">
          <v-select
            :items="sortingFields"
            :value="sort"
            @input="changeSortField"
            item-text="text"
            item-value="value"
            hide-details
            :menu-props="{ 'content-class': 'rounds-map-drawer-sorter' }"
            class="pa-0 ma-0 rounds-map-drawer-select"
          >
            <span slot="prepend-inner" class="font-weight-bold">Trier par : </span>
            <v-btn icon @click.stop="changeOrdering" class="mx-0" slot="append-outer">
              <v-icon v-if="!desc">fas fa-sort-alpha-down</v-icon>
              <v-icon v-else>fas fa-sort-alpha-down-alt</v-icon>
            </v-btn>
          </v-select>
        </v-flex>
        <v-btn icon @click.stop="$emit('update:mini', !mini)" class="mx-0">
          <v-icon v-show="!mini">fas fa-caret-left</v-icon>
          <v-icon v-show="mini">fas fa-caret-right</v-icon>
        </v-btn>
      </v-layout>
      <v-divider v-show="!mini" />
      <div class="rounds-map-drawer-list" @click.stop.prevent ref="roundsList">
        <v-progress-linear
          :indeterminate="true"
          v-show="!loaded"
          class="ma-0 rounds-map-drawer-list-loader"
          color="primary"
          height="2"
        ></v-progress-linear>
        <template v-for="(round, index) in rounds">
          <v-layout
            :key="'map-round-item-' + round.id"
            align-center
            :id="`map-round-${round.id}`"
            class="rounds-map-drawer-list-round"
          >
            <v-flex>
              <v-layout wrap align-center>
                <v-flex xs12>
                  <v-layout align-center>
                    <v-flex shrink @click="showMessage(round.id)">
                      <v-checkbox
                        :input-value="roundsSelectedIds"
                        @change="toggleRoundSelected(round.id, $event)"
                        :value="round.id"
                        :disabled="isDisabled(round.id)"
                        hide-details
                        class="mt-0 ml-3 pt-0"
                        color="primary"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex shrink @click="showRoundOnMap(round.id)">
                      <v-chip
                        label
                        :color="getBgColor(round.id)"
                        :text-color="getTxtColor(round.id)"
                        small
                        class="rounds-map-drawer-list-id"
                      >
                        {{ round.id }}
                      </v-chip>
                    </v-flex>
                    <v-flex shrink v-show="!mini" @click="showRoundOnMap(round.id)" class="special-badge ml-2">
                      <v-badge color="transparent" overlap>
                        <template slot="badge" v-if="round.lastStepLateStatus !== LATE_STATUS.Undetermined">
                          <v-icon :color="getClockColorFromStatus(round.lastStepLateStatus)" small>
                            fas fa-clock
                          </v-icon>
                          <v-tooltip v-if="round.lateStatus === LATE_STATUS.LateContractual" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="ml-1" v-bind="attrs" v-on="on" color="error" small>
                                fas fa-exclamation
                              </v-icon>
                            </template>
                            <span>Une étape est en retard</span>
                          </v-tooltip>
                        </template>
                        <v-chip
                          :color="getChipColorFromStatus(round.status)"
                          text-color="white"
                          class="rounds-grid-status"
                        >
                          {{ getStatusLabel(round.status) }}
                        </v-chip>
                      </v-badge>
                    </v-flex>
                    <v-spacer @click="showRoundOnMap(round.id)" />
                    <v-flex class="text-xs-center" shrink v-show="!mini" px-2 @click="showRoundOnMap(round.id)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-switch v-if="areDriverTracksDisplayed(round.id)" @change="showDriverTrackOnMap(round.id)" />
                          </div>
                        </template>
                        <div>
                          Afficher le tracé chauffeur
                        </div>
                      </v-tooltip>
                    </v-flex>
                    <v-flex class="text-xs-center" shrink v-show="!mini" px-2 @click="showRoundOnMap(round.id)">
                      <v-icon small class="mr-1">fas fa-map-marker-alt</v-icon><b>{{ round.stepCount }}</b>
                    </v-flex>
                    <v-flex class="text-xs-center" shrink v-show="!mini" @click="showRoundOnMap(round.id)">
                      <v-progress-circular
                        :value="round.progressPercentage * 100"
                        color="grey darken-2"
                        :rotate="-90"
                        size="38"
                        class="rounds-grid-progress"
                      >
                        {{ getPercentage(round.progressPercentage) }}
                      </v-progress-circular>
                    </v-flex>
                    <v-flex shrink v-show="!mini">
                      <v-btn icon @click.stop="toggleExpand(round.id)">
                        <v-icon v-if="round.id === expandedRoundId" small>fas fa-chevron-up</v-icon>
                        <v-icon v-else small>fas fa-chevron-down</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 v-show="!mini" ml-3 class="rounds-map-drawer-list-row" @click="showRoundOnMap(round.id)">
                  <v-layout wrap>
                    <v-flex :xs6="hasDriverData" :xs12="!hasDriverData" pl-3>Libellé</v-flex>
                    <v-flex xs6 pl-2 v-if="hasDriverData">Chauffeur</v-flex>
                    <v-flex :xs6="hasDriverData" :xs12="!hasDriverData" pl-3 class="font-weight-bold">
                      {{ round.label }}
                    </v-flex>
                    <v-flex xs6 pl-2 class="font-weight-bold" v-if="hasDriverData">{{ round.driverLabel }}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  v-show="!mini"
                  pt-1
                  ml-3
                  class="rounds-map-drawer-list-row"
                  @click="showRoundOnMap(round.id)"
                >
                  <v-layout wrap align-center>
                    <v-flex xs6 pl-3>Départ</v-flex>
                    <v-flex xs6 pl-2>Arrivée</v-flex>
                    <v-flex xs6 pl-3 class="font-weight-bold">
                      {{ getDate(round.estimatedStartDateTime) }}<br />
                      {{ getTime(round.estimatedStartDateTime) }}
                    </v-flex>
                    <v-flex xs6 pl-2 class="font-weight-bold">
                      {{ getDate(round.estimatedEndDateTime) }}<br />
                      {{ getTime(round.estimatedEndDateTime) }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-divider :key="'expanded-divider-before-' + round.id" v-if="round.expanded && !mini" />

          <v-layout :key="'expanded-' + round.id">
            <v-expand-transition>
              <map-menu-detail
                v-show="round.id === expandedRoundId && !mini"
                :transport-steps="round.transportSteps"
                :round-id="round.id"
                :restrict-height="index < rounds.length - 1"
                class="rounds-map-drawer-list-detail"
              />
            </v-expand-transition>
          </v-layout>

          <v-divider :key="'expanded-divider-after-' + round.id" v-show="!mini" />
        </template>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar" top>Un maximum de {{ maxSelected }} tournées peut être sélectionnées</v-snackbar>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import MomentMixin from '@/mixins/moment';
import StatusMixin from '@/mixins/status';
import NumbersMixin from '@/mixins/numbers';
import MapMenuDetail from './map-menu-detail';

export default {
  name: 'map-menu',
  mixins: [MomentMixin, StatusMixin, NumbersMixin],
  components: { MapMenuDetail },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxSelected: 5,
      snackbar: false,
      expandedRoundId: null,
      debouncedScrollTo: null,
      debouncedRoundSelect: null,
      driverTracksTab: [],
      getTracksTimer: null,
    };
  },
  mounted() {
    this.debouncedScrollTo = debounce(() => this.scrollTo(), 700);
    this.debouncedRoundSelect = debounce(async (id, value) => {
      if (value && value.length && value.includes(id)) {
        await this.selectRound(id);
      } else {
        this.deselectRound(id);
        this.deselectSelectedDriverRound(id);
        const index = this.driverTracksTab.findIndex((r) => r === id);
        this.driverTracksTab.splice(index, 1);
      }
    }, 50);
  },
  computed: {
    ...mapState({
      loaded: (state) => state.rounds.loaded,
      sort: (state) => state.rounds.dataPagination.sortBy[0],
      desc: (state) => state.rounds.dataPagination.sortDesc[0],
    }),
    ...mapGetters(['rounds', 'hasDriverData', 'sortingFields']),
    ...mapState({
      tracksRefreshRate: (state) => state.context.settings.refreshInMinutes * 60000,
    }),
    ...mapState('map', {
      roundsSelected: (state) => state.roundsSelected,
      roundIdFocused: (state) => state.roundIdFocused,
      stepSelected: (state) => state.stepSelected,
      roundsDriverSelected: (state) => state.roundsDriverSelected,
    }),
    roundsSelectedIds() {
      return this.roundsSelected.map((r) => r.id);
    },
  },

  methods: {
    ...mapActions('map', ['focusOnRound', 'resetSelectedStep', 'resetRoundFocus', 'deselectRound', 'selectRound', 'getDriverSmoothRoute', 'deselectSelectedDriverRound']),
    ...mapMutations(['setDataPaginationSort']),

    async toggleRoundSelected(id, value) {
      try {
        this.loading = true;
        await this.debouncedRoundSelect(id, value);
      } finally {
        this.loading = false;
      }
    },

    areDriverTracksDisplayed(id) {
      const isRoundSelected = this.roundsSelected.find((r) => r.id === id);
      const round = this.rounds.find((r) => r.id === id);
      if (!isRoundSelected || round.status === this.STATUS.Planned) {
        return false;
      }
      return true;
    },

    updateMini(value) {
      this.$emit('update:mini', value);
    },

    getBgColor(id) {
      return this.roundsSelected.find((r) => r.id === id)?.color || '#e1e1e1';
    },
    getTxtColor(id) {
      return this.getBgColor(id) === '#e1e1e1' ? 'black' : 'white';
    },
    isDisabled(id) {
      return this.roundsSelectedIds.length >= this.maxSelected && !this.roundsSelectedIds.includes(id);
    },
    toggleExpand(roundId, force = false) {
      if (this.expandedRoundId === roundId && !force) this.expandedRoundId = null;
      else this.expandedRoundId = roundId;

      if (force) this.updateMini(false);
    },
    scrollTo() {
      if (!this.roundIdFocused && !this.roundsSelected.length) return;
      this.$nextTick(() => {
        const targetId = this.roundIdFocused || this.roundsSelected[0].id;
        const targetElement = this.$el.querySelector(`#map-round-${targetId}`);
        if (!targetElement) return;
        this.$refs.roundsList.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
      });
    },
    showRoundOnMap(id) {
      if (this.roundIdFocused === id) {
        this.resetRoundFocus();
      } else {
        this.focusOnRound(id);
        this.resetSelectedStep();
      }
    },
    async registerGetDriverTrackAutoRefresh(transportRefreshRate = 0) {
      clearTimeout(this.getTracksTimer);
      this.getTracksTimer = setTimeout(async () => {
        if (this.driverTracksTab.length === 0) {
          return;
        }
        for (let i = 0; i < this.driverTracksTab.length; i++) {
          await this.getDriverSmoothRoute(this.driverTracksTab[i]);
        }
        this.registerGetDriverTrackAutoRefresh(this.tracksRefreshRate);
      }, transportRefreshRate);
    },
    showDriverTrackOnMap(id) {
      const roundIsSelected = this.roundsDriverSelected.find((r) => r.id === id);
      if (!roundIsSelected) {
        this.driverTracksTab.push(id);
        this.registerGetDriverTrackAutoRefresh(0);
      }
      else {
        const index = this.driverTracksTab.findIndex((r) => r === id);
        this.driverTracksTab.splice(index, 1);
        this.deselectSelectedDriverRound(id);
      }
    },
    showMessage(id) {
      this.snackbar = this.isDisabled(id);
    },
    changeSortField(sortField) {
      this.setDataPaginationSort({ sort: sortField, desc: this.desc });
    },
    changeOrdering() {
      this.setDataPaginationSort({ sort: this.sort, desc: !this.desc });
    },
  },
  watch: {
    rounds: {
      handler() {
        this.debouncedScrollTo();
      },
      deep: true,
    },
    stepSelected(newval) {
      if (newval) {
        this.toggleExpand(this.roundIdFocused, newval.forceExpand);
      }
      this.debouncedScrollTo();
    },
    mini() {
      this.debouncedScrollTo();
    },
  },
};
</script>
