var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-snackbar',{staticClass:"text-left",attrs:{"value":_vm.value,"timeout":-1,"top":"","right":"","vertical":"","outlined":""},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',{staticClass:"files-download-card",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline px-0 py-0",attrs:{"primary-title":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"files-download-title font-weight-medium"},[_vm._v("Téléchargement des feuilles de tournées")]),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1)])]),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-container',{staticClass:"px-0 py-0"},[_c('v-container',[_c('div',{staticClass:"subtitle-2"},[_vm._v("Téléchargements "),(_vm.filesStillToProcessCount > 0)?_c('span',[_vm._v("("+_vm._s(_vm.filesStillToProcessCount)+")")]):_vm._e()]),(_vm.filesStillToProcessCount)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',{staticClass:"grey lighten-5 overflow-y-auto files-download-grid-container"},[_vm._l((_vm.filesProcessing),function(file){return _c('v-row',{key:file.uid,staticClass:"files-download-grid-row",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"files-download-grid-row-label",attrs:{"cols":"6"}},[_vm._v("Tournée "+_vm._s(file.roundId))]),_c('v-col',{staticClass:"caption font-italic",attrs:{"cols":"3"}},[_vm._v("en cours")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":16},on:{"click":function($event){return _vm.stopProcessingFile(file.uid)}}},[_vm._v(" fa-solid fa-circle-stop ")])],1)]}}],null,true)},[_c('span',[_vm._v("Arrêter")])]),_c('v-progress-circular',{attrs:{"indeterminate":"","size":16,"width":2,"value":20}})],1)],1)}),_vm._l((_vm.filesToProcess),function(file){return _c('v-row',{key:file.roundId,staticClass:"files-download-grid-row",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"files-download-grid-row-label",attrs:{"cols":"6"}},[_vm._v("Tournée "+_vm._s(file.roundId))]),_c('v-col',{staticClass:"caption font-italic",attrs:{"cols":"3"}},[_vm._v("en attente")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":16},on:{"click":function($event){return _vm.removeFileToProcess(file.roundId)}}},[_vm._v(" fa-solid fa-circle-xmark ")])],1)]}}],null,true)},[_c('span',[_vm._v("Annuler")])])],1)],1)})],2)],1),_c('v-container',[_c('div',{staticClass:"subtitle-2"},[_vm._v("Historique")]),_c('v-container',{staticClass:"grey lighten-5 overflow-y-auto files-download-grid-container"},[_vm._l((_vm.filesOnError),function(file){return _c('v-row',{key:file.uid,staticClass:"files-download-grid-row",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"files-download-grid-row-label",attrs:{"cols":"6"}},[_vm._v("Tournée "+_vm._s(file.roundId))]),_c('v-col',{staticClass:"caption font-italic",attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.getDateFormatted(file)))]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":16}},[_vm._v(" fa-solid fa-circle-exclamation ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(file.error))])])],1)],1)}),_vm._l((_vm.filesGenerated),function(file){return _c('v-row',{key:file.uid,staticClass:"files-download-grid-row",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"files-download-grid-row-label",attrs:{"cols":"6"}},[_vm._v("Tournée "+_vm._s(file.roundId))]),_c('v-col',{staticClass:"caption font-italic",attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.getDateFormatted(file)))]),_c('v-col',{attrs:{"cols":"3"}})],1)})],2)],1),_c('v-container',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"justify":"end","text":"","color":"primary","disabled":_vm.processedFilesCount == 0},on:{"click":function($event){return _vm.clearHistory()}}},[_vm._v(" Effacer l'historique ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }