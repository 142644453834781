/* eslint-disable no-undef */
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.min.js';

L.AwesomeNumberMarkers = L.Icon.extend({
  options: {
    iconSize: [35, 45],
    iconAnchor: [17, 42],
    popupAnchor: [1, -32],
    className: 'awesome-marker',
    icon: 'home',
    markerColor: 'blue',
    numberColor: 'white',
    number: '',
  },

  createIcon: function () {
    const div = document.createElement('div');

    div.innerHTML = this._createInner();
    this._setIconStyles(div);

    return div;
  },

  _createInner: function () {
    const options = this.options;
    let styleAttr = '';
    let classAttr = '';
    let svgElem = '';

    if (options.numberColor) {
      styleAttr = "style='color: " + options.numberColor + "' ";
    }

    if (options.markerColor.startsWith('#')) {
      svgElem = `
      <svg width="36px" height="36px" viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="${options.markerColor}">
            <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
                <g id="Icons" transform="translate(37.000000, 169.000000)">
                    <g id="map-marker" transform="translate(78.000000, 468.000000)">
                        <g transform="translate(10.000000, 6.000000)">
                            <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
                            <circle cx="14" cy="14" r="7"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </g>
      </svg>`;
    }
    if (options.number >= 10) classAttr = 'class="large-number"';
    return `<i ${classAttr} ${styleAttr}><b>${options.number}</b></i>${svgElem}`;
  },

  _setIconStyles: function (img) {
    const options = this.options;
    const size = L.point(options.iconSize);
    const anchor = L.point(options.iconAnchor);

    if (!options.markerColor.startsWith('#'))
      img.className = `awesome-marker-icon-${options.markerColor} ${options.className}`;
    else img.className = `awesome-marker-icon-default ${options.className}`;

    if (anchor) {
      img.style.marginLeft = -anchor.x + 'px';
      img.style.marginTop = -anchor.y + 'px';
    }

    if (size) {
      img.style.width = size.x + 'px';
      img.style.height = size.y + 'px';
    }
  },
});
