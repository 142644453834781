<template>
  <v-layout wrap align-center>
    <v-flex xs12 sm12 md4>
      <h4>{{ label }}</h4>
    </v-flex>
    <v-flex xs12 sm12 md8>
      <v-autocomplete
        v-model="model.addressId"
        label="Nom de l'adresse"
        clearable
        clear-icon="fas fa-times"
        :items="addresses"
        :search-input.sync="addressesSearch"
        :loading="addressesLoading"
        hide-no-data
        @input="setSelectedAddress"
        item-text="name"
        item-value="addressId"
      />
    </v-flex>
    <v-flex xs12 sm12 md5 offset-md4>
      <v-autocomplete
        v-if="!model.addressId"
        v-model="model.country"
        label="Pays"
        clearable
        clear-icon="fas fa-times"
        @input="debouncedUpdate"
        :items="countries"
        item-text="text"
        item-value="code"
      />
      <v-text-field v-else label="Pays" :value="selectedAddress.country" disabled single-line />
    </v-flex>
    <v-flex xs12 sm12 md4 offset-md4 pr-2>
      <v-autocomplete
        v-if="!model.addressId"
        :disabled="model.country === null"
        v-model="model.postalCode"
        label="Code postal"
        clearable
        clear-icon="fas fa-times"
        :items="postCodes"
        cache-items
        :search-input.sync="postCodeSearch"
        :loading="postCodesLoading"
        hide-no-data
        @input="debouncedUpdate"
        item-text="text"
        item-value="postCode"
      >
        <template v-slot:selection="{ item }">
          {{ item.postCode }}
        </template>
      </v-autocomplete>
      <v-text-field v-else label="Code postal" :value="selectedAddress.postalCode" disabled single-line />
    </v-flex>
    <v-flex xs12 sm12 md4 pl-2>
      <v-autocomplete
        v-if="!model.addressId"
        :disabled="model.country === null"
        v-model="model.city"
        label="Ville"
        clearable
        clear-icon="fas fa-times"
        :items="cities"
        cache-items
        :search-input.sync="cityNameSearch"
        :loading="citiesLoading"
        hide-no-data
        @input="debouncedUpdate"
        item-text="text"
        item-value="name"
      >
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
      </v-autocomplete>
      <v-text-field v-else label="Ville" :value="selectedAddress.city" disabled single-line />
    </v-flex>
    <v-flex xs12 sm12 md8 offset-md4>
      <v-text-field
        :disabled="model.country === null || model.addressId"
        v-model="streetModel"
        label="Rue"
        single-line
        clearable
        clear-icon="fas fa-times"
        @input="debouncedUpdate"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'filter-address',
  props: {
    value: {
      type: Object,
      default: () => ({
        street: null,
        postalCode: null,
        city: null,
        country: null,
        addressId: null,
      }),
    },
    label: {
      type: String,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      model: this.value,
      cityNameSearch: null,
      cities: [],
      citiesLoading: false,
      postCodeSearch: null,
      postCodes: [],
      postCodesLoading: false,
      addressesSearch: null,
      addressesLoading: false,
      debouncedUpdate: null,
      debouncedGetCities: null,
      debouncedGetPostCodes: null,
      debouncedGetAddresses: null,
      selectedAddress: {
        country: null,
        postalCode: null,
        city: null,
        street: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapState('filters', ['addresses']),

    streetModel() {
      return this.model.addressId ? this.selectedAddress.street : this.model.street;
    },
  },
  async created() {
    if (this.model.addressId) {
      const address = await this.tryGetAddress(this.model.addressId);
      if (address) {
        const { city, streetName, name } = address;
        this.selectedAddress = {
          name,
          country: `${city.country.code} - ${city.country.label}`,
          postalCode: city.postCode,
          city: city.name,
          street: streetName,
        };
        this.addressesSearch = name;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.debouncedUpdate = debounce(() => {
        if (this.model.addressId) {
          this.model.country = null;
          this.model.postalCode = null;
          this.model.city = null;
          this.model.street = null;
        } else if (!this.model.country) {
          this.selectedAddress = {
            name: null,
            country: null,
            postalCode: null,
            city: null,
            street: null,
          };
          this.model = {
            street: null,
            postalCode: null,
            city: null,
            country: null,
            addressId: null,
          };
        } else {
          this.model.addressId = null;
        }
        this.$emit('input', this.model);
      }, 500);

      this.debouncedGetCities = debounce(async (cityName) => {
        this.citiesLoading = true;
        this.cities = await this.tryGetCities({ cityName });
        this.citiesLoading = false;
      }, 500);

      this.debouncedGetPostCodes = debounce(async (postCode) => {
        this.postCodesLoading = true;
        this.postCodes = await this.tryGetCities({ postCode });
        this.postCodesLoading = false;
      }, 500);
      this.debouncedGetAddresses = debounce(async (addressName) => {
        await this.tryGetAddresses({ addressName });
      }, 500);
    });
  },
  methods: {
    ...mapActions('filters', ['getCities', 'getAddresses']),
    ...mapActions('api', ['getAddress']),

    setSelectedAddress(addressId) {
      const { city, streetName, name } = this.addresses.find((a) => a.addressId === addressId);
      this.selectedAddress = {
        name,
        country: `${city.country.code} - ${city.country.label}`,
        postalCode: city.postCode,
        city: city.name,
        street: streetName,
      };
      this.debouncedUpdate();
    },

    async tryGetCities(params) {
      try {
        const response = await this.getCities({ ...params, countryCode: this.model.country });
        return (response?.data || []).map((c) => ({ ...c, text: `${c.postCode} - ${c.name}` }));
      } catch {
        return [];
      }
    },

    async tryGetAddresses(params) {
      try {
        this.addressesLoading = true;
        const response = await this.getAddresses(params);
        return (response?.data || []).map((a) => ({ ...a }));
      } catch {
        return [];
      } finally {
        this.addressesLoading = false;
      }
    },

    async tryGetAddress(addressId) {
      try {
        this.addressesLoading = true;
        const response = await this.getAddress({ addressId });
        return response || null;
      } catch {
        // Do nothing
      } finally {
        this.addressesLoading = false;
      }
    },
  },
  watch: {
    value() {
      this.model = Object.assign({}, this.value);
    },
    postCodeSearch(prefix) {
      if (!this.postCodeSearch || !this.model.country || !prefix || prefix.length < 2) {
        if (!prefix) this.postCodes = [];
        return;
      }
      this.debouncedGetPostCodes(prefix);
    },
    cityNameSearch(prefix) {
      if (!this.cityNameSearch || !this.model.country || !prefix || prefix.length < 2) {
        if (!prefix) this.cities = [];
        return;
      }
      this.debouncedGetCities(prefix);
    },
    addressesSearch(prefix) {
      if (prefix === this.selectedAddress.name) return;

      this.debouncedGetAddresses(prefix);
    },
  },
};
</script>
