<template>
  <v-layout wrap align-center>
    <v-flex xs12 sm12 md4>
      <h4>{{ label }}</h4>
    </v-flex>
    <v-flex xs12 sm12 md3>
      <v-menu
        v-model="displayStart"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            v-model="dateStart"
            :label="labelStart"
            prepend-icon="fas fa-calendar"
            clearable
            readonly
            :disabled="disabled"
          />
        </template>

        <v-date-picker
          v-model="date.start"
          :max="getAlowedDate(date.end)"
          @input="updateStart"
          :locale="lang"
          :disabled="disabled"
        />
      </v-menu>
    </v-flex>
    <v-flex xs12 sm12 md3 ml-4>
      <v-menu
        v-model="displayEnd"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :disabled="disabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            v-model="dateEnd"
            :label="labelEnd"
            prepend-icon="fas fa-calendar"
            clearable
            readonly
            :disabled="disabled"
          />
        </template>
        <v-date-picker
          v-model="date.end"
          :min="getAlowedDate(date.start)"
          @input="updateEnd"
          :locale="lang"
          :disabled="disabled"
        />
      </v-menu>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import MomentMixin from '@/mixins/moment';

export default {
  name: 'filter-date',
  mixins: [MomentMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        start: null,
        end: null,
      }),
    },
    label: {
      type: String,
      default: null,
    },
    labelStart: {
      type: String,
      default: null,
    },
    labelEnd: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayStart: false,
      displayEnd: false,
      date: {
        start: this.value.start,
        end: this.value.end,
      },
      dateStart: this.getDate(this.value.start),
      dateEnd: this.getDate(this.value.end),
    };
  },
  methods: {
    updateEnd() {
      this.displayEnd = false;
      this.dateEnd = this.getDate(this.date.end);
      this.$emit('input', this.date);
    },
    updateStart() {
      this.displayStart = false;
      this.dateStart = this.getDate(this.date.start);
      if (!this.dateEnd) {
        this.dateEnd = this.dateStart;
        this.date.end = this.date.start;
      }
      this.$emit('input', this.date);
    },
    getAlowedDate(date) {
      return !date ? null : moment(date).format('YYYY-MM-DD');
    },
  },
  watch: {
    dateStart() {
      if (this.dateStart) return;
      this.date.start = null;
      this.$emit('input', this.date);
    },
    dateEnd() {
      if (this.dateEnd) return;
      this.date.end = null;
      this.$emit('input', this.date);
    },
    value() {
      if (this.value.start || this.value.end) return;
      this.dateStart = null;
      this.dateEnd = null;
    },
  },
};
</script>
