<template>
  <div ref="detail" :style="maxHeightStyle">
    <v-timeline dense>
      <template v-for="transportStep in transportSteps">
        <v-timeline-item
          :key="'map-detail-row-number-' + transportStep.stepRowNumber"
          right
          :color="getTimelineStatusColor(transportStep)"
          fill-dot
          :id="`map-detail-${transportStep.roundTransportStepId}`"
          class="rounds-map-menu-detail-timeline py-1"
          :class="getStepClasses(transportStep)"
          small
        >
          <template slot="icon">
            <v-avatar @click="showStep(transportStep.roundTransportStepId)">
              <span :class="[getTimelineStatusTextColor(transportStep)]">{{ transportStep.stepRowNumber }}</span>
            </v-avatar>
          </template>
          <v-layout align-center wrap>
            <v-flex xs12>
              <v-layout align-center wrap>
                <v-flex grow @click="showStep(transportStep.roundTransportStepId)">
                  <v-icon size="20" :color="transportStep.isPickup ? 'badge-step-pickup' : 'badge-step-delivery'">
                    fas fa-circle
                  </v-icon>
                  <span class="round-detail-step-letter">
                    <span v-if="transportStep.isPickup">E</span>
                    <span v-else>L</span>
                  </span>
                  <b>{{ transportStep.stepAddress.name }}</b>
                </v-flex>
                <v-spacer @click="showStep(transportStep.roundTransportStepId)" />
                <v-flex shrink px-2>
                  <a
                    v-if="transportStep.groupedStepsCount === 1"
                    @click.prevent.stop="openShipmentDetailUrl(transportStep)"
                    class="rounds-map-menu-detail-link"
                  >
                    {{ transportStep.missionNumber }}
                  </a>
                </v-flex>
                <v-flex
                  shrink
                  px-2
                  v-if="transportStep.groupedStepsCount > 1"
                  @click="showStep(transportStep.roundTransportStepId)"
                >
                  x{{ transportStep.groupedStepsCount }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 @click="showStep(transportStep.roundTransportStepId)">
              {{ transportStep.stepAddress.streetName }} {{ transportStep.stepAddress.postCode }}
              {{ transportStep.stepAddress.cityName }}
            </v-flex>
            <v-flex xs12 @click="showStep(transportStep.roundTransportStepId)">
              {{ displayDate(transportStep) }} <b>{{ displayTime(transportStep) }}</b>
            </v-flex>
          </v-layout>
        </v-timeline-item>
        <div
          class="last-rounds-map-menu-detail-realized"
          :key="'map-last-step-realized-' + transportStep.roundTransportStepId"
          v-if="transportStep.roundTransportStepId === lastStepIdRealized"
        >
          <v-icon color="black" class="last-rounds-map-menu-detail-realized-arrow" small
            >fas fa-angle-double-down</v-icon
          >
        </div>
      </template>
    </v-timeline>
    <v-snackbar v-model="snackbar" top> L'étape sélectionnée n'a pas de géolocalisation </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MomentMixin from '@/mixins/moment';
import TimelineColorsMixin from '@/mixins/timeline-colors';
import ShipmentMixin from '@/mixins/shipment';
import debounce from 'lodash.debounce';

export default {
  name: 'map-menu-detail',
  mixins: [MomentMixin, TimelineColorsMixin, ShipmentMixin],
  props: {
    transportSteps: {
      type: Array,
      default: () => [],
    },
    restrictHeight: {
      type: Boolean,
      default: false,
    },
    rowHeight: {
      type: Number,
      default: 70,
    },
    maxRows: {
      type: Number,
      default: 5,
    },
    roundId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      debouncedScrollTo: null,
    };
  },
  mounted() {
    this.debouncedScrollTo = debounce(() => this.scrollTo(), 100);
  },
  computed: {
    ...mapState({
      canDisplayRoundRealizedAndEstimatedHours: (state) => state.context.rights.displayRealizedAndEstimatedHours,
    }),
    ...mapState('map', ['stepSelected']),

    lastStepIdRealized() {
      let transportStepId = null;
      for (let index = 0, len = this.transportSteps.length; index < len; index++) {
        const transportStep = this.transportSteps[index];
        if (!this.isPickupRealized(transportStep) && !this.isDeliveryRealized(transportStep)) {
          break;
        }
        transportStepId = index === this.transportSteps.length - 1 ? null : transportStep.roundTransportStepId;
      }
      return transportStepId;
    },

    associatedSteps() {
      if (!this.stepActive) return [];
      const step = this.transportSteps.find((t) => t.roundTransportStepId === this.stepActive);
      if (!step) return [];

      const missionNumber = step.missionNumber;
      const searchForDeliveries = step.isPickup === true;
      return this.transportSteps.reduce((prev, curr) => {
        if (curr.isPickup && searchForDeliveries) return prev;
        if (!curr.isPickup && !searchForDeliveries) return prev;
        if (curr.missionNumber !== missionNumber) return prev;
        return [...prev, curr.roundTransportStepId];
      }, []);
    },
    stepActive() {
      return this.stepSelected?.id;
    },
    maxHeightStyle() {
      return {
        maxHeight: this.restrictHeight ? `${this.rowHeight * this.maxRows}px` : 'none',
      };
    },
  },
  methods: {
    ...mapActions('map', ['selectStep']),

    openShipmentDetailUrl(transportStep) {
      if (!transportStep) return;
      const url = this.getShipmentDetailUrl(transportStep);
      if (url) window.open(url, '_blank');
    },

    displayDate(transportStep) {
      if (!this.canDisplayRoundRealizedAndEstimatedHours)
        return this.getPlannedDate(transportStep.stepDate);
      if (transportStep.stepDate.effectiveDate) this.getDate(transportStep.stepDate.effectiveDate);
      if (transportStep.stepDate.estimatedDate) this.getDate(transportStep.stepDate.estimatedDate);
      return this.getPlannedDate(transportStep.stepDate);
    },
    displayTime(transportStep) {
      if (!this.canDisplayRoundRealizedAndEstimatedHours)
        return this.getPlannedTime(transportStep.stepDate);
      if (transportStep.stepDate.effectiveDate) this.getTime(transportStep.stepDate.effectiveDate);
      if (transportStep.stepDate.estimatedDate) this.getTime(transportStep.stepDate.estimatedDate);
      return this.getPlannedTime(transportStep.stepDate);
    },
    scrollTo() {
      if (!this.stepActive) return;
      this.$nextTick(() => {
        const targetElement = this.$el.querySelector(`#map-detail-${this.stepActive}`);
        if (!targetElement) return;
        this.$refs.detail.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
      });
    },
    showStep(id) {
      const transportStep = this.transportSteps.find((ts) => ts.roundTransportStepId === id);
      if (!transportStep) return;
      const bounds = {
        Longitude: transportStep.stepAddress.longitude,
        Latitude: transportStep.stepAddress.latitude,
      };
      if (!bounds.Longitude || !bounds.Latitude) {
        this.snackbar = true;
        return;
      }
      this.selectStep({
        id: this.roundId,
        step: {
          id: id,
          forceExpand: true,
          bounds: [bounds],
        },
      });
    },

    getStepClasses(transportStep) {
      const isSelected = this.stepActive === transportStep.roundTransportStepId;
      const isAssociatedStep = this.associatedSteps.indexOf(transportStep.roundTransportStepId) > -1;
      const isPickup = transportStep.isPickup === true;
      return [
        isSelected ? 'active' : '',
        isAssociatedStep ? 'associated' : '',
        isPickup ? 'pickup' : 'delivery',
        this.getTimelineStatusBorderColor(transportStep),
      ];
    },
  },
  watch: {
    transportSteps() {
      this.debouncedScrollTo();
    },
    stepSelected() {
      this.debouncedScrollTo();
    },
  },
};
</script>
<style lang="scss">
.rounds-map-menu-detail-timeline.associated {
  &.pickup {
    background-color: var(--v-badge-step-pickup-lighten5);
  }

  &.delivery {
    background-color: var(--v-badge-step-delivery-lighten4);
  }
}
</style>
