import { cloneDeep, arrayValuesToQs } from '@/business/utils';

export default class QueryBase {
  static get SerializerFn() {
    return (params) =>
      Object.entries(params)
        .filter(([, value]) => {
          if (!value) return false;
          if (value instanceof Array && !value.length) return false;
          return true;
        })
        .map(([key, value]) => {
          const isArray = value instanceof Array;
          let val = isArray ? arrayValuesToQs(value) : value;
          if (!isArray && typeof value.toJSON === 'function') {
            val = value.toJSON();
          }
          return `${key}=${val}`;
        })
        .join('&');
  }

  toQs(clonedThis = this.getClone()) {
    return QueryBase.SerializerFn(clonedThis);
  }

  setPropsFromModel(instance) {
    if (!instance) return;
    Object.keys(instance).forEach((k) => {
      this[k] = cloneDeep(instance[k]);
    });
  }

  getClone() {
    const obj = {};
    Object.keys(this).forEach((k) => {
      obj[k] = cloneDeep(this[k]);
      this.getCloneIterator(k, obj[k], obj);
    });
    return obj;
  }

  /* Meant to be override
   * Function invoked on iterator during getClone process
   */
  getCloneIterator(/* key, value, clonedObj */) {}
}

class ProjectableQueryBase extends QueryBase {
  constructor({ fields }) {
    super();
    this.fields = fields || '~';
  }
}

class PagedResourceQueryBase extends ProjectableQueryBase {
  constructor(params = {}) {
    super(params);
    const { startIndex, count, sort, desc } = params;
    this.startIndex = startIndex || 0;
    this.count = count || 10;
    this.sort = sort || [];
    this.desc = desc || [];
  }
}

class SearchPatternQuery extends PagedResourceQueryBase {
  constructor(params) {
    super(params);
    const { pattern, patternFields } = params;
    this.pattern = pattern || null;
    this.patternFields = patternFields || [];

    Object.defineProperty(this, '_applySearchPatternTo', {
      value: ['pattern'],
      writable: true, // important!
      configurable: true,
      enumerable: false, // could be omitted
    });
    Object.defineProperty(this, '_defaultSearchPattern', {
      value: SearchPatternQuery.SearchPatterns.Exact,
      writable: true, // important!
      configurable: true,
      enumerable: false, // could be omitted
    });
    Object.defineProperty(this, '_searchPatterns', {
      value: {},
      writable: true, // important!
      configurable: true,
      enumerable: false, // could be omitted
    });
  }

  /*
   * Extends toQs function behavior to apply search pattern on wanted fields
   */
  getCloneIterator(key, value, clonedObj) {
    if (value && typeof value === 'string' && this.applySearchPatternTo.includes(key)) {
      const pattern = (this.searchPatterns || {})[key] || this.defaultSearchPattern;
      clonedObj[key] = pattern.replace('[str]', value.replace(/\*/g, ''));
    }
  }

  static get SearchPatterns() {
    return {
      Exact: '[str]',
      StartsWith: '[str]*',
      EndsWith: '*[str]',
      Contains: '*[str]*',
    };
  }

  /* Below is getters and setters region
   * Important :
   * - by design, they are not enumerable which is convenient since we parse the class properties to create a query string
   * - setters are meant to be used in derived classes to adapt the search patterns on fields
   * For object/array properties (searchPatterns/applySearchPatternTo) :
   * - DO NOT replace the reference without perfectly knowing the inheritance schema
   */

  /*
   * Provide a general behavior for all fields affected by search pattern
   * Example of valid configuration:
   * this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.Exact;
   */
  get defaultSearchPattern() {
    return this._defaultSearchPattern;
  }
  set defaultSearchPattern(val) {
    this._defaultSearchPattern = val;
  }

  /*
   * Provide a list of fields name affected by search pattern
   * Example of valid configuration:
   * this.applySearchPatternTo.push('code', 'label');
   */
  get applySearchPatternTo() {
    return this._applySearchPatternTo;
  }
  set applySearchPatternTo(val) {
    this._applySearchPatternTo = val;
  }

  /*
   * Provide a specific behavior for one/several fields affected by search pattern
   * If a field present in applySearchPatternTo is not provided here, system will fallback on defaultSearchPattern global config
   * Example of valid configuration :
   * this.searchPatterns['name'] = SearchPatternQuery.SearchPatterns.Exact;
   */
  get searchPatterns() {
    return this._searchPatterns;
  }
  set searchPatterns(val) {
    this._searchPatterns = val;
  }
}

class SimpleCodeLabelSearchQuery extends SearchPatternQuery {
  constructor(params) {
    const { code, label, searchCodeAndLabel } = params;
    const pattern = code || label;
    const patternFields = searchCodeAndLabel && !!pattern ? ['code', 'label'] : null;

    if (patternFields) {
      params.pattern = pattern;
      params.patternFields = patternFields;
      params.code = null;
      params.label = null;
    }
	if (!params.fields) {
      params.fields = ['code', 'label'];
    }
    super(params);
    this.applySearchPatternTo.push('pattern', 'code', 'label');
    this.searchPatterns['pattern'] = SearchPatternQuery.SearchPatterns.StartsWith;
    this.searchPatterns['code'] = SearchPatternQuery.SearchPatterns.StartsWith;
    this.searchPatterns['label'] = SearchPatternQuery.SearchPatterns.StartsWith;
  }
}

export { SimpleCodeLabelSearchQuery, SearchPatternQuery, PagedResourceQueryBase, ProjectableQueryBase, QueryBase };
