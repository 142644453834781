/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
import moment from 'moment';

const getLatitudeFromObj = (obj) => {
  let prop = '';
  if (obj.hasOwnProperty('Y')) {
    prop = 'Y';
  }
  if (obj.hasOwnProperty('Latitude')) {
    prop = 'Latitude';
  }
  if (obj.hasOwnProperty('latitude')) {
    prop = 'latitude';
  }
  if (obj.hasOwnProperty('lat')) {
    prop = 'lat';
  }
  if (!prop) {
    throw new Error('Object does not contain a "latitude" property');
  }
  const result = Number(obj[prop]);
  if (isNaN(result)) {
    throw new Error(`Object property '${prop}' must be a number`);
  }
  return result;
};

const getLongitudeFromObj = (obj) => {
  let prop = '';
  if (obj.hasOwnProperty('X')) {
    prop = 'X';
  }
  if (obj.hasOwnProperty('Longitude')) {
    prop = 'Longitude';
  }
  if (obj.hasOwnProperty('longitude')) {
    prop = 'longitude';
  }
  if (obj.hasOwnProperty('lng')) {
    prop = 'lng';
  }
  if (!prop) {
    throw new Error('Object does not contain a "longitude" property');
  }
  const result = Number(obj[prop]);
  if (isNaN(result)) {
    throw new Error(`Object property '${prop}' must be a number`);
  }
  return result;
};

/*
 Convert cordinates array or X,Y array in lealet LatLng array
*/
const getLatLongFromCoordinates = (coordinates, arbitraryTimeForCoordWithoutDate = false) => {
  if (!coordinates) {
    throw new Error('Missing coordinates');
  }
  if (typeof coordinates !== 'object') {
    throw new Error('Coordinates parameter should be given as an Object or an Objects Array');
  }
  if (!L) return [];

  if (coordinates instanceof Array) {
    let latLng = [];

    coordinates.forEach((coordinate, index) => {
      let leafLeafLetExtendedCoordinates = new L.LatLng(
        getLatitudeFromObj(coordinate),
        getLongitudeFromObj(coordinate),
      );
      if (coordinate.GPSServerLocalDateTime) {
        if (arbitraryTimeForCoordWithoutDate && coordinate.GPSServerLocalDateTime.UnixTimeSpan === '-62135596800') {
          // Default date has been returned by API (01/01/0001)
          const now = moment();

          if (index > 0) now.add(index * 5, 's');
          leafLeafLetExtendedCoordinates.date = now;
        } else {
          leafLeafLetExtendedCoordinates.date = moment(coordinate.GPSServerLocalDateTime.UnixTimeSpan * 1000);
        }
      }
      latLng.push(leafLeafLetExtendedCoordinates);
    });
    // [ { lat: number, lng: number } ]
    return latLng;
  }
  // { lat: number, lng: number }
  let leafLeafLetExtendedCoordinates = new L.LatLng(getLatitudeFromObj(coordinates), getLongitudeFromObj(coordinates));
  if (coordinates.GPSServerLocalDateTime) {
    leafLeafLetExtendedCoordinates.date = moment(coordinates.GPSServerLocalDateTime.UnixTimeSpan * 1000);
  }
  return leafLeafLetExtendedCoordinates;
};

export default {
  getLatLongFromCoordinates,
};
