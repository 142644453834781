import GetCountriesModel from '../models/countries';
import { SimpleCodeLabelSearchQuery } from './query';

export default class GetCountriesQuery extends SimpleCodeLabelSearchQuery {
  constructor(params) {
    if (!params.count) params.count = 100;
    super(params);
    this.setPropsFromModel(new GetCountriesModel(params));
  }
}
