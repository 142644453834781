<template>
  <v-layout wrap align-center>
    <v-flex xs12 sm12 md4>
      <h4>{{ label }}</h4>
    </v-flex>
    <v-flex xs12 sm12 md8>
      <v-text-field
        v-model="model"
        :label="labelText"
        :type="type"
        clearable
        clear-icon="fas fa-times"
        :rules="rules"
        @input="update"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'filter-textbox',
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: null,
    },
    labelText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
  methods: {
    update() {
      this.$emit('input', this.model);
    },
  },
};
</script>
