export default class GetRoundsModel {
  constructor({
    roundId,
    roundLateStatuses,
    roundStatuses,
    roundLabel,
    roundReferenceDateFrom,
    roundReferenceDateTo,
    roundEstimatedStartDateFrom,
    roundEstimatedStartDateTo,
    roundEstimatedEndDateFrom,
    roundEstimatedEndDateTo,
    transportMissionNumber,
    transportOrdererCodes,
    transportCustomerCodes,
    transportReference1,
    transportReference2,
    transportReference3,
    transportPickupStepStreetName,
    transportPickupStepPostCode,
    transportPickupStepCityName,
    transportPickupStepCountryCode,
    transportDeliveryStepStreetName,
    transportDeliveryStepPostCode,
    transportDeliveryStepCityName,
    transportDeliveryStepCountryCode,
    packageCode,
    packageBarCode,
    packageNature,
    packageReference1,
    packageReference2,
    packageReference3,
    packageReference4,
    packageReference5,
    packageReference6,
    packageReference7,
    packageReference8,
    roundDriverId,
    transportPickupStepName,
    transportDeliveryStepName,
    transportPickupStepAddressId,
    transportDeliveryStepAddressId,
  } = {}) {
    this.roundId = roundId || null;
    this.roundLateStatuses = roundLateStatuses || [];
    this.roundStatuses = roundStatuses || [];
    this.roundLabel = roundLabel || null;
    this.roundReferenceDateFrom = roundReferenceDateFrom || null;
    this.roundReferenceDateTo = roundReferenceDateTo || null;
    this.roundEstimatedStartDateFrom = roundEstimatedStartDateFrom || null;
    this.roundEstimatedStartDateTo = roundEstimatedStartDateTo || null;
    this.roundEstimatedEndDateFrom = roundEstimatedEndDateFrom || null;
    this.roundEstimatedEndDateTo = roundEstimatedEndDateTo || null;
    this.transportMissionNumber = transportMissionNumber || null;
    this.transportOrdererCodes = transportOrdererCodes || [];
    this.transportCustomerCodes = transportCustomerCodes || [];
    this.transportReference1 = transportReference1 || null;
    this.transportReference2 = transportReference2 || null;
    this.transportReference3 = transportReference3 || null;
    this.transportPickupStepStreetName = transportPickupStepStreetName || null;
    this.transportPickupStepPostCode = transportPickupStepPostCode || null;
    this.transportPickupStepCityName = transportPickupStepCityName || null;
    this.transportPickupStepCountryCode = transportPickupStepCountryCode || null;
    this.transportDeliveryStepStreetName = transportDeliveryStepStreetName || null;
    this.transportDeliveryStepPostCode = transportDeliveryStepPostCode || null;
    this.transportDeliveryStepCityName = transportDeliveryStepCityName || null;
    this.transportDeliveryStepCountryCode = transportDeliveryStepCountryCode || null;
    this.packageCode = packageCode || null;
    this.packageBarCode = packageBarCode || null;
    this.packageNature = packageNature || null;
    this.packageReference1 = packageReference1 || null;
    this.packageReference2 = packageReference2 || null;
    this.packageReference3 = packageReference3 || null;
    this.packageReference4 = packageReference4 || null;
    this.packageReference5 = packageReference5 || null;
    this.packageReference6 = packageReference6 || null;
    this.packageReference7 = packageReference7 || null;
    this.packageReference8 = packageReference8 || null;
    this.roundDriverId = roundDriverId || null;
    this.transportPickupStepName = transportPickupStepName || null;
    this.transportDeliveryStepName = transportDeliveryStepName || null;
    this.transportPickupStepAddressId = transportPickupStepAddressId || null;
    this.transportDeliveryStepAddressId = transportDeliveryStepAddressId || null;
  }
}
