import GetRoundsModel from './api/models/rounds';

const state = () => ({
  rounds: [],
  roundsSelected: [],
  loaded: false,
  pagination: null,
  dataPagination: {
    page: 1,
    itemsPerPage: 100,
    sortBy: ['estimatedStartDateTime'],
    mustSort: true,
    sortDesc: [true],
    multiSort: false,
    // groupBy: [''],
    // groupDesc: [true, false],
  },
});

const getters = {
  rounds: (state) => state.rounds,
  hasDriverData: (_, getters) => getters.rounds?.length && !!getters.rounds.find((r) => r.driverLabel !== null),
  sortingFields: (_, getters) => {
    const fields = [
      { text: 'Statut', value: 'status' },
      { text: 'Code', value: 'id' },
      { text: 'Libellé', value: 'label' },
      { text: 'Départ', value: 'estimatedStartDateTime' },
      { text: 'Arrivée', value: 'estimatedEndDateTime' },
    ];
    if (getters.hasDriverData) {
      fields.push({ text: 'Chauffeur', value: 'driverLabel' });
    }
    fields.push({ text: 'Etapes', value: 'stepCount' }, { text: 'Avancement', value: 'progressPercentage' });
    return fields;
  },
};

const actions = {
  async getRounds({ commit, dispatch }, request = new GetRoundsModel()) {
    commit('ROUNDS_LOADING', false);
    let response;
    try {
      response = await dispatch('api/getRounds', request);
    } finally {
      commit('ROUNDS_LOADED', response);
    }
  },
};

const mutations = {
  ROUNDS_LOADING(state) {
    state.loaded = false;
  },
  ROUNDS_LOADED(state, response) {
    state.loaded = true;
    if (response) {
      state.rounds = response.data || [];
      state.pagination = response.paging;
    }
  },

  setRoundsSelected(state, roundsSelected) {
    state.roundsSelected = roundsSelected;
  },
  setDataPagination(state, pagination) {
    state.dataPagination = pagination;
  },
  setDataPaginationSort(state, { sort, desc }) {
    state.dataPagination.sortBy = [sort];
    state.dataPagination.sortDesc = [desc];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
