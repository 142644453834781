<template>
  <v-snackbar :value="value" @input="$emit('input', $event)" :timeout="-1" top right vertical class="text-left"
    outlined>
    <v-card elevation="0" class="files-download-card">
      <v-card-title class="headline px-0 py-0" primary-title>
        <div class="d-flex">
          <div class="files-download-title font-weight-medium">Téléchargement des feuilles de tournées</div>
          <div>
            <v-btn icon @click="$emit('input', false)">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0 py-0">
        <v-container class="px-0 py-0">
          <v-container>
            <div class="subtitle-2">Téléchargements
              <span v-if="filesStillToProcessCount > 0">({{ filesStillToProcessCount }})</span>
            </div>
            <v-progress-linear v-if="filesStillToProcessCount" indeterminate color="primary" class="mb-0">
            </v-progress-linear>
            <v-container class="grey lighten-5 overflow-y-auto files-download-grid-container">
              <v-row no-gutters v-for="file in filesProcessing" :key="file.uid" align="center"
                class="files-download-grid-row">
                <v-col cols="6" class="files-download-grid-row-label">Tournée {{ file.roundId }}</v-col>
                <v-col cols="3" class="caption font-italic">en cours</v-col>
                <v-col cols="3">
                  <v-tooltip bottom z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon :size="16" @click="stopProcessingFile(file.uid)">
                          fa-solid fa-circle-stop
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Arrêter</span>
                  </v-tooltip>
                  <v-progress-circular indeterminate :size="16" :width="2" :value="20"></v-progress-circular>
                </v-col>
              </v-row>
              <v-row no-gutters v-for="file in filesToProcess" :key="file.roundId" align="center"
                class="files-download-grid-row">
                <v-col cols="6" class="files-download-grid-row-label">Tournée {{ file.roundId }}</v-col>
                <v-col cols="3" class="caption font-italic">en attente</v-col>
                <v-col cols="3">
                  <v-tooltip bottom z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon :size="16" @click="removeFileToProcess(file.roundId)">
                          fa-solid fa-circle-xmark
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Annuler</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
          <v-container>
            <div class="subtitle-2">Historique</div>
            <v-container class="grey lighten-5 overflow-y-auto files-download-grid-container">
              <v-row no-gutters v-for="file in filesOnError" :key="file.uid" align="center"
                class="files-download-grid-row">
                <v-col cols="6" class="files-download-grid-row-label">Tournée {{ file.roundId }}</v-col>
                <v-col cols="3" class="caption font-italic">{{ getDateFormatted(file) }}</v-col>
                <v-col cols="3">
                  <v-tooltip bottom z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon color="error" :size="16">
                          fa-solid fa-circle-exclamation
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ file.error }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row no-gutters v-for="file in filesGenerated" :key="file.uid" align="center"
                class="files-download-grid-row">
                <v-col cols="6" class="files-download-grid-row-label">Tournée {{ file.roundId }}</v-col>
                <v-col cols="3" class="caption font-italic">{{ getDateFormatted(file) }}</v-col>
                <v-col cols="3"></v-col>
              </v-row>
            </v-container>
          </v-container> 
          <v-container>
            <v-container>
              <v-row justify="end">
                <v-btn justify="end" text color="primary" @click="clearHistory()" :disabled="processedFilesCount == 0">
                Effacer l'historique
              </v-btn>
              </v-row>
            </v-container>
          </v-container>         
        </v-container>
      </v-card-text>
    </v-card>
  </v-snackbar>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      roundsSelected: (state) => state.rounds.roundsSelected,
      rounds: (state) => state.rounds.rounds,
    }),
    ...mapState('files', ['filesToProcess', 'filesProcessing', 'filesOnError', 'filesGenerated', 'loadingFileData']),
    ...mapState('filters', {
      filterOrdererCodes: (state) => state.filters.transportOrdererCodes,
      filterCustomerCodes: (state) => state.filters.transportCustomerCodes,
    }),
    filesStillToProcessCount() {
      return this.filesProcessing.length + this.filesToProcess.length;
    },
    processedFilesCount() {
      return this.filesGenerated.length + this.filesOnError.length;
    },
  },
  methods: {
    ...mapActions('files', ['askRoundsSheetsGeneration', 'clearProcessedFiles', 'removeFileToProcess', 'stopProcessingFile']),

    getDateFormatted(file) {
      return (file.lastUpdate || file.generationStartDate)?.format('HH:mm') || '';
    },

    launchRoundsSheetsGeneration() {
      try {
        const roundsIds = this.roundsSelected.map((r) => r.id);
        if (!roundsIds.length) return;

        const ordererCode = this.filterOrdererCodes.length === 1 ? this.filterOrdererCodes[0] : null;
        this.askRoundsSheetsGeneration({ roundsIds, ordererCode, customerCodes: this.filterCustomerCodes });
      } catch (err) {
        console.error(err);
      }
    },

    clearHistory() {
      try {
        this.clearProcessedFiles();
      } catch (err) {
        console.error(err);
      }
    }
  },
};
</script>
<style lang="scss">
.files-download-title {
  word-break: break-word;
}

.files-download-card {
  width: 350px;
}

.files-download-grid-container {
  height: calc(100vh / 5);
}

.files-download-grid-row {
  height: 36px;
}

.files-download-grid-row-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-progress-circular.v-progress-circular--visible.small {
  width: 20px !important;
  height: 20px !important;
  vertical-align: text-bottom;
}
.v-progress-circular {
  font-size: 10px;
}
</style>
