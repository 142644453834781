<script>
/* eslint-disable no-undef */
import {
  PruneCluster,
  PruneClusterForLeaflet,
} from 'exports-loader?exports=PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js';
import findRealParent from '../utils/findRealParent';
import '../marker/awesome-number-markers';
import './marker-cluster';

export default {
  render() {
    return '';
  },
  name: 'clusteredMarkers',
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      parentContainer: null,
      pruneCluster: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.parentContainer = findRealParent(this.$parent);
      this.createClusteredMarkers();
    });
  },
  beforeDestroy() {
    if (this.pruneCluster) this.parentContainer.mapObject.removeLayer(this.pruneCluster);
  },

  methods: {
    createClusteredMarkers() {
      if (!this.markers.length) return;
      if (this.pruneCluster) this.parentContainer.mapObject.removeLayer(this.pruneCluster);
      this.pruneCluster = new PruneClusterForLeaflet();
      this.pruneCluster.PrepareLeafletMarker = this.prepareLeafletMarker.bind(this);
      this.pruneCluster.BuildLeafletClusterIcon = this.buildLeafletClusterIcon.bind(this);
      this.markers.forEach((marker) => {
        let clusteredMarker = this.createClusteredMarker(marker);
        this.pruneCluster.RegisterMarker(clusteredMarker);
      });
      this.pruneCluster.userLayer = true;
      this.pruneCluster.Cluster.Size = 35;
      this.parentContainer.mapObject.addLayer(this.pruneCluster);
    },
    buildLeafletClusterIcon(cluster) {
      var e = new L.Icon.MarkerCluster();
      e.stats = cluster.stats;
      e.population = cluster.population;
      return e;
    },
    prepareLeafletMarker(marker, data, category) {
      if (marker.preparedLeafletMarker) {
        return;
      }
      if (data.icon) {
        if (typeof data.icon === 'function') {
          marker.setIcon(data.icon(data, category));
        } else {
          marker.setIcon(data.icon);
        }
      }
      if (data.tooltip) {
        if (typeof data.tooltip === 'string') marker.bindPopup(data.tooltip);
        else marker.bindPopup(data.tooltip.text, data.tooltip.options);
        if (data.tooltip.allwaysOpen) {
          marker.on('add', () => {
            marker.openPopup();
          });
          marker.on('click', () => {
            marker.openPopup();
          });
        }
      }
      if (data.action) marker.on('click', data.action);
      marker.preparedLeafletMarker = true;
    },
    createClusteredMarker(marker) {
      let clusteredMarker = new PruneCluster.Marker(marker.latLng.lat, marker.latLng.lng);
      if (marker.color || marker.number) {
        if (marker.number > 0) {
          clusteredMarker.data.icon = new L.AwesomeNumberMarkers({
            number: marker.number,
            markerColor: marker.color,
            spin: true,
          });
        } else {
          clusteredMarker.data.icon = L.AwesomeMarkers.icon({
            icon: marker.icon,
            markerColor: marker.color,
            prefix: 'fa',
          });
        }
        clusteredMarker.data.color = marker.color;
        clusteredMarker.category = marker.color;
      }
      if (marker.tooltip) {
        clusteredMarker.data.tooltip = marker.tooltip;
      }
      if (marker.action) clusteredMarker.data.action = marker.action;
      return clusteredMarker;
    },
  },
  watch: {
    markers() {
      this.$nextTick(() => {
        this.createClusteredMarkers();
      });
    },
  },
};
</script>
