import BusinessStatus from './business-status';

export default {
  mixins: [BusinessStatus],
  methods: {
    isPickupRealized(step) {
      return step.isPickup && ['E', 'L', 'T', 'f', 'F'].indexOf(step.transportStatus) >= 0;
    },
    isDeliveryRealized(step) {
      return !step.isPickup && ['L', 'T', 'f', 'F'].indexOf(step.transportStatus) >= 0;
    },
    isStepRealized(step) {
      if (
        (step.stepLateStatus === this.LATE_STATUS.NotLate &&
          (this.isPickupRealized(step) || this.isDeliveryRealized(step))) ||
        step.stepLateStatus === this.LATE_STATUS.LatePlanning ||
        step.stepLateStatus === this.LATE_STATUS.LateContractual
      )
        return true;
      return false;
    },
  },
};
