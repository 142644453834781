<template>
  <v-dialog v-model="show" width="890" height="80%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon @click="show = true" class="white--text">
        <v-icon>fas fa-filter</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline py-0" primary-title>
        Filtres d'affichage
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0" v-if="filters">
        <v-layout wrap fill-height>
          <v-flex xs12 sm3 class="rounds-filters-list">
            <v-list class="py-0">
              <v-list-item @click="display(0)" :class="displayFilter === 0 ? 'active' : ''">
                <v-list-item-icon>
                  <v-icon x-small v-if="hasFilters(0)">fas fa-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="mr-4">
                  <v-layout>
                    <v-flex>Tournées</v-flex>
                    <v-spacer />
                    <v-icon small v-if="hasErrors(0)" :color="hasErrors(0) ? 'error' : null">
                      fas fa-exclamation
                    </v-icon>
                  </v-layout>
                </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-group v-model="menuListOrdersActive" append-icon="fa-angle-down">
                <template v-slot:activator>
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-title @click="displayForPackage(1)">Ordres</v-list-item-title>
                </template>
                <v-divider />
                <v-list-item @click="display(1)" :class="displayFilter === 1 ? 'active' : ''">
                  <v-list-item-icon>
                    <v-icon x-small v-if="hasFilters(1)">fas fa-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pl-4">
                    <v-layout>
                      <v-flex>Missions</v-flex>
                      <v-spacer />
                      <v-icon small v-if="hasErrors(1)" :color="hasErrors(1) ? 'error' : null">
                        fas fa-exclamation
                      </v-icon>
                    </v-layout>
                  </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="display(2)" :class="displayFilter === 2 ? 'active' : ''">
                  <v-list-item-icon>
                    <v-icon x-small v-if="hasFilters(2)">fas fa-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pl-4">
                    <v-layout>
                      <v-flex>Adresses</v-flex>
                      <v-spacer />
                      <v-icon small v-if="hasErrors(2)" :color="hasErrors(2) ? 'error' : null">
                        fas fa-exclamation
                      </v-icon>
                    </v-layout>
                  </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="display(3)" :class="displayFilter === 3 ? 'active' : ''">
                  <v-list-item-icon>
                    <v-icon x-small v-if="hasFilters(3)">fas fa-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pl-4">
                    <v-layout>
                      <v-flex>Colisage</v-flex>
                      <v-spacer />
                      <v-icon small v-if="hasErrors(3)" :color="hasErrors(3) ? 'error' : null">
                        fas fa-exclamation
                      </v-icon>
                    </v-layout>
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-divider />
            </v-list>
          </v-flex>
          <v-flex xs12 sm9 class="px-4 py-2 rounds-filters-inputs" v-show="displayFilter === 0">
            <filter-date
              :lang="lang"
              :disabled="roundEstimatedStartDateTime.start !== null || roundEstimatedEndDateTime.start !== null"
              v-model="roundReferenceDateTime"
              label="Date de référence"
              label-start="A partir de"
              label-end="Jusqu'à"
            />
            <filter-date
              :lang="lang"
              :disabled="roundReferenceDateTime.start !== null"
              v-model="roundEstimatedStartDateTime"
              label="Date estimée de début"
              label-start="A partir de"
              label-end="Jusqu'à"
            />
            <filter-date
              :lang="lang"
              :disabled="roundReferenceDateTime.start !== null"
              v-model="roundEstimatedEndDateTime"
              label="Date estimée de fin"
              label-start="A partir de"
              label-end="Jusqu'à"
            />
            <filter-checkbox-group v-model="filters.roundLateStatuses" label="Etat" :list="roundLateStatusesList" />
            <filter-checkbox-group v-model="filters.roundStatuses" label="Statut" :list="roundStatusesList" />
            <filter-textbox
              :value="filters.roundId"
              @input="filters.roundId = Number($event) || null"
              label="Code de la tournée"
              type="number"
            />
            <filter-textbox v-model="filters.roundLabel" label="Libellé de la tournée" />
          </v-flex>
          <v-flex xs12 sm9 class="px-4 py-2 rounds-filters-inputs" v-show="displayFilter === 1">
            <filter-textbox v-model="filters.transportMissionNumber" label="Numéro de mission" :type="'number'" />

            <filter-autocomplete
              :value="filters.transportCustomerCodes"
              @input="customersCodesChanged"
              @search="debouncedGetCustomers"
              label="Clients"
              :list="customers"
              no-data-text="Précisez votre recherche"
              :loading="customersLoading"
              multiple
            />
            <filter-autocomplete
              v-model="filters.transportReference1"
              @search="debouncedGetReferenceValues($event, 1)"
              :label="reference1Label || 'Référence 1'"
              :list="reference1Values"
              :no-data-text="transportCustomerReferenceError"
              :hide-no-data="!onlyOneCustomerIsSelected"
              combobox
            />
            <filter-autocomplete
              v-model="filters.transportReference2"
              @search="debouncedGetReferenceValues($event, 2)"
              :label="reference2Label || 'Référence 2'"
              :list="reference2Values"
              :no-data-text="transportCustomerReferenceError"
              :hide-no-data="!onlyOneCustomerIsSelected"
              combobox
            />
            <filter-autocomplete
              v-model="filters.transportReference3"
              @search="debouncedGetReferenceValues($event, 3)"
              :label="reference3Label || 'Référence 3'"
              :list="reference3Values"
              :no-data-text="transportCustomerReferenceError"
              :hide-no-data="!onlyOneCustomerIsSelected"
              combobox
            />
            <filter-autocomplete
              v-model="filters.transportOrdererCodes"
              @search="debouncedGetOrderers"
              :list="orderers"
              label="Donneurs d'ordre"
              no-data-text="Précisez votre recherche"
              :loading="orderersLoading"
              multiple
            />
          </v-flex>
          <v-flex xs12 sm9 class="px-4 py-2 rounds-filters-inputs" v-show="displayFilter === 2">
            <filter-address v-model="pickupAddress" :countries="countries" label="Adresse d'enlèvement" />
            <filter-address
              v-model="deliveryAddress"
              :countries="countries"
              label="Adresse de livraison"
              class="mt-2"
            />
          </v-flex>
          <v-flex xs12 sm9 class="px-4 py-2 rounds-filters-inputs" v-show="displayFilter === 3">
            <filter-textbox v-model="filters.packageBarCode" label="Code à barres" />
            <filter-textbox v-model="filters.packageNature" label="Nature de colis" />
            <filter-textbox v-model="filters.packageCode" label="Code" />
            <filter-textbox
              v-model="filters.packageReference1"
              label="Référence 1"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference2"
              label="Référence 2"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference3"
              label="Référence 3"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference4"
              label="Référence 4"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference5"
              label="Référence 5"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference6"
              label="Référence 6"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference7"
              label="Référence 7"
              :rules="[checkReferenceValueWithRestrictions]"
            />
            <filter-textbox
              v-model="filters.packageReference8"
              label="Référence 8"
              :rules="[checkReferenceValueWithRestrictions]"
            />
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-toolbar dark color="primary" class="rounds-filters-dialog-results">
          <filter-results :filters-result="filters" @reset:filter="resetFilter" @reset:all="resetAll" />
        </v-toolbar>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-0">
        <v-checkbox
          v-if="localStorageAvailable"
          v-model="saveOnLocalStorage"
          label="Mémoriser les filtres"
          color="primary"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancel">Annuler</v-btn>
        <v-btn text color="primary" @click="apply">Appliquer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { references } from '@/business';
import { cloneDeep } from '@/business/utils';
import StatusMixin from '@/mixins/status';

import FilterDate from './inputs/filter-date';
// import FilterSelect from './inputs/filter-select';
import FilterTextbox from './inputs/filter-textbox';
import FilterAutocomplete from './inputs/filter-autocomplete';
import FilterAddress from './inputs/filter-address';
import FilterCheckboxGroup from './inputs/filter-checkbox-group';
import FilterResults from './filter-results';

export default {
  name: 'filter-dialog',
  mixins: [StatusMixin],
  components: {
    FilterDate,
    // FilterSelect,
    FilterTextbox,
    FilterAutocomplete,
    FilterAddress,
    FilterCheckboxGroup,
    FilterResults,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    localStorageAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filters: null,
      displayFilter: 0,
      show: false,
      menuListOrdersActive: true,
      debouncedGetCustomers: null,
      debouncedGetOrderers: null,
      debouncedGetReferenceValues: null,
      reference1Values: [],
      reference2Values: [],
      reference3Values: [],
    };
  },
  mounted() {
    // Clone filters from store state
    this.filters = cloneDeep(this.filtersData);

    // Debounced method to get customers from API
    this.debouncedGetCustomers = debounce(
      (search) => this.getCustomers({ code: search, searchCodeAndLabel: true }),
      500,
    );

    // Debounced method to get orderers from API
    this.debouncedGetOrderers = debounce((search) => this.getOrderers({ name: search }), 500);

    // Debounced method to get reference values from API
    this.debouncedGetReferenceValues = debounce((search, referenceLevel) => {
      if (!this.onlyOneCustomerIsSelected) return;

      const response = this.getReferenceValues({ value: search, referenceLevel });
      if (!response) return;

      const references = (response.data || []).map((r) => ({ value: r.value, text: r.value }));
      if (referenceLevel === 1) this.reference1Values = references;
      else if (referenceLevel === 2) this.reference2Values = references;
      else if (referenceLevel === 3) this.reference3Values = references;
    }, 500);
  },
  computed: {
    ...mapGetters(['lang']),
    ...mapState({
      maxDaysForSearch: (state) => state.context.settings.maxDaysSearchLimit,
      minCharsForSearch: (state) => state.context.settings.minCharsSearchWithPercent,
    }),
    ...mapGetters('filters', ['getEmptyFilters']),
    ...mapState('filters', {
      reference1Label: (state) => state.reference1Label,
      reference2Label: (state) => state.reference2Label,
      reference3Label: (state) => state.reference3Label,
      filtersData: (state) => state.filters,

      customers: (state) => state.customers.map((c) => ({ value: c.code, text: `${c.code} - ${c.label}`, uid: c.uid })),
      customersLoading: (state) => state.customersLoading,
      orderers: (state) => state.orderers.map((o) => ({ value: o.code, text: `${o.code} - ${o.name}` })),
      orderersLoading: (state) => state.orderersLoading,
      countries: (state) =>
        state.countries
          .sort((c1, c2) => (c1.code === 'FR' ? -1 : c2.code === 'FR' ? 1 : c1.label.localeCompare(c2.label)))
          .map((c) => ({ code: c.code, text: `${c.code} - ${c.label}` })),
    }),

    onlyOneCustomerIsSelected() {
      return this.filters?.transportCustomerCodes?.length === 1;
    },

    roundReferenceDateTime: {
      get: function () {
        return {
          start: this.filters.roundReferenceDateFrom,
          end: this.filters.roundReferenceDateTo,
        };
      },
      set: function (newValue) {
        this.filters.roundReferenceDateFrom = newValue.start;
        this.filters.roundReferenceDateTo = newValue.end;
      },
    },
    roundEstimatedStartDateTime: {
      get: function () {
        return {
          start: this.filters.roundEstimatedStartDateFrom,
          end: this.filters.roundEstimatedStartDateTo,
        };
      },
      set: function (newValue) {
        this.filters.roundEstimatedStartDateFrom = newValue.start;
        this.filters.roundEstimatedStartDateTo = newValue.end;
      },
    },
    roundEstimatedEndDateTime: {
      get: function () {
        return {
          start: this.filters.roundEstimatedEndDateFrom,
          end: this.filters.roundEstimatedEndDateTo,
        };
      },
      set: function (newValue) {
        this.filters.roundEstimatedEndDateFrom = newValue.start;
        this.filters.roundEstimatedEndDateTo = newValue.end;
      },
    },
    roundStatusesList() {
      return Object.values(this.STATUS).map((value) => ({ value, text: this.getStatusLabel(value) }));
    },
    roundLateStatusesList() {
      return Object.values(this.LATE_STATUS)
        .map((value) => ({ value, text: this.getLateStatusLabel(value) }))
        .filter((v) => v.text);
    },

    transportCustomerReferenceError() {
      return this.onlyOneCustomerIsSelected ? 'Aucune référence trouvée' : '';
    },
    pickupAddress: {
      get: function () {
        return {
          city: this.filters.transportPickupStepCityName,
          country: this.filters.transportPickupStepCountryCode,
          postalCode: this.filters.transportPickupStepPostCode,
          street: this.filters.transportPickupStepStreetName,
          addressId: this.filters.transportPickupStepAddressId,
        };
      },
      set: function (newValue) {
        this.filters.transportPickupStepCityName = newValue.city;
        this.filters.transportPickupStepCountryCode = newValue.country;
        this.filters.transportPickupStepPostCode = newValue.postalCode;
        this.filters.transportPickupStepStreetName = newValue.street;
        this.filters.transportPickupStepAddressId = newValue.addressId;
      },
    },
    deliveryAddress: {
      get: function () {
        return {
          city: this.filters.transportDeliveryStepCityName,
          country: this.filters.transportDeliveryStepCountryCode,
          postalCode: this.filters.transportDeliveryStepPostCode,
          street: this.filters.transportDeliveryStepStreetName,
          addressId: this.filters.transportDeliveryStepAddressId,
        };
      },
      set: function (newValue) {
        this.filters.transportDeliveryStepCityName = newValue.city;
        this.filters.transportDeliveryStepCountryCode = newValue.country;
        this.filters.transportDeliveryStepPostCode = newValue.postalCode;
        this.filters.transportDeliveryStepStreetName = newValue.street;
        this.filters.transportDeliveryStepAddressId = newValue.addressId;
      },
    },
    saveOnLocalStorage: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions('filters', ['getCustomers', 'getOrderers', 'getReferenceValues', 'resetReferences']),

    resetReferencesValues() {
      this.reference1Values = [];
      this.reference2Values = [];
      this.reference3Values = [];
      this.resetReferences();
    },

    customersCodesChanged(codes) {
      this.filters.transportCustomerCodes = codes;
      if (!codes || codes.length > 1) {
        this.resetReferencesValues();
      }
    },

    checkReferenceValueWithRestrictions(value) {
      try {
        const result = references.checkReferenceValueWithRestrictions({
          value,
          date: this.filters.roundReferenceDateFrom || this.filters.roundEstimatedStartDateFrom,
          maxDays: this.maxDaysForSearch,
          minChars: this.minCharsForSearch,
        });
        if (result.success) return true;
        return false;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    cancel() {
      this.show = false;
    },
    apply() {
      if (!this.checkAllPackageReference()) return;
      this.show = false;
      this.$emit('apply-filters', { filters: cloneDeep(this.filters) });
    },
    checkAllPackageReference() {
      return (
        this.checkReferenceValueWithRestrictions(this.filters.packageReference1) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference2) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference3) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference4) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference5) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference6) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference7) === true &&
        this.checkReferenceValueWithRestrictions(this.filters.packageReference8) === true
      );
    },
    display(displayFilter) {
      this.displayFilter = displayFilter;
    },
    displayForPackage(displayFilter) {
      if (this.menuListOrdersActive) return;
      this.displayFilter = displayFilter;
    },
    resetAll() {
      this.filters = this.getEmptyFilters();
    },
    resetFilter(key) {
      const emptyFilters = this.getEmptyFilters();
      if (typeof emptyFilters[key] === 'undefined') return;
      this.$set(this.filters, key, emptyFilters[key]);
    },
    hasFilters(tabIndex) {
      switch (tabIndex) {
        case 0:
          return (
            this.filters.roundId !== null ||
            this.filters.roundLateStatuses.length ||
            this.filters.roundStatuses.length ||
            this.filters.roundLabel ||
            this.filters.roundReferenceDateFrom ||
            this.filters.roundReferenceDateTo ||
            this.filters.roundEstimatedStartDateFrom ||
            this.filters.roundEstimatedStartDateTo ||
            this.filters.roundEstimatedEndDateFrom ||
            this.filters.roundEstimatedEndDateTo
          );
        case 1:
          return (
            this.filters.transportMissionNumber ||
            this.filters.transportOrdererCodes.length ||
            this.filters.transportCustomerCodes.length ||
            this.filters.transportReference1 ||
            this.filters.transportReference2 ||
            this.filters.transportReference3
          );
        case 2:
          return (
            this.filters.transportPickupStepStreetName ||
            this.filters.transportPickupStepPostCode ||
            this.filters.transportPickupStepCityName ||
            this.filters.transportPickupStepCountryCode ||
            this.filters.transportDeliveryStepStreetName ||
            this.filters.transportDeliveryStepPostCode ||
            this.filters.transportDeliveryStepCityName ||
            this.filters.transportDeliveryStepCountryCode
          );
        case 3:
          return (
            this.filters.packageCode ||
            this.filters.packageBarCode ||
            this.filters.packageNature ||
            this.filters.packageReference1 ||
            this.filters.packageReference2 ||
            this.filters.packageReference3 ||
            this.filters.packageReference4 ||
            this.filters.packageReference5 ||
            this.filters.packageReference6 ||
            this.filters.packageReference7 ||
            this.filters.packageReference8
          );
      }
    },
    hasErrors(tabIndex) {
      switch (tabIndex) {
        case 0:
        case 1:
        case 2:
          return false;
        case 3:
          return !this.checkAllPackageReference();
      }
    },
  },
  watch: {
    show(showIt) {
      this.displayFilter = 0;

      if (showIt) {
        this.filters = cloneDeep(this.filtersData);
      }
    },
    filtersData: {
      handler() {
        this.filters = cloneDeep(this.filtersData);
      },
      deep: true,
    },
  },
};
</script>
