<template>
  <div class="round-timeline" :class="setClass()">
    <div class="round-timeline-prev-step" v-if="canPrev">
      <v-avatar color="white" size="18" @click="movePrevStep" class="round-timeline-step-btn">
        <v-icon size="12">fas fa-arrow-left</v-icon>
      </v-avatar>
    </div>
    <div class="round-timeline-steps">
      <template v-for="step in currentSteps.reverse()">
        <div :key="'step' + step.roundTransportStepId"
             :style="getStyleForPosition(step)"
             :class="['round-timeline-steps-point', getTimelineStatusColor(step), getTimelineStatusBorderColor(step)]"
             @click="setActive(step.roundTransportStepId)"></div>
        <div class="round-timeline-steps-point-number"
             :key="'number' + step.roundTransportStepId"
             :style="getStyleForPosition(step)"
             v-if="value === step.roundTransportStepId">
          {{ step.stepRowNumber }}
        </div>
      </template>
    </div>
    <div class="round-timeline-next-step" v-if="canNext">
      <v-avatar color="white" size="18" @click="moveNextStep" class="round-timeline-step-btn">
        <v-icon size="12">fas fa-arrow-right</v-icon>
      </v-avatar>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import TimelineColorsMixin from '@/mixins/timeline-colors';

  export default {
    name: 'grid-timeline',
    mixins: [TimelineColorsMixin],
    props: {
      transportSteps: {
        type: Array,
        required: true,
      },
      value: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        maxStepDisplayed: 10,
        dateNow: moment(),
        nbPrevSteps: 0,
      };
    },
    computed: {
      canNext() {
        return this.transportSteps.length - this.nbPrevSteps - this.currentSteps.length > 0;
      },
      canPrev() {
        return this.nbPrevSteps >= this.maxStepDisplayed;
      },
      currentSteps() {
        return this.transportSteps.slice(this.nbPrevSteps, this.nbPrevSteps + this.maxStepDisplayed);
      },
      currentFirstStepMoment() {
        let firstStepDate = this.getMomentFromStep(this.currentSteps[0]);
        this.currentSteps.forEach((step) => {
          let stepDate = this.getMomentFromStep(step);
          if (firstStepDate === null) {
            firstStepDate = stepDate;
          } else if (stepDate !== null && stepDate < firstStepDate) {
            firstStepDate = stepDate;
          }
        });
        return firstStepDate;
      },
      currentLastStepMoment() {
        let lastStepDate = this.getMomentFromStep(this.currentSteps[this.currentSteps.length - 1]);
        this.currentSteps.forEach((step) => {
          let stepDate = this.getMomentFromStep(step);
          if (lastStepDate === null) {
            lastStepDate = stepDate;
          } else if (stepDate !== null && stepDate > lastStepDate) {
            lastStepDate = stepDate;
          }
        });
        return lastStepDate;
      },
    },
    watch: {
      transportSteps() {
        this.dateNow = moment();
      },
      value() {
        const find = this.transportSteps.findIndex((ts) => ts.roundTransportStepId === this.value);
        if (!this.value || find === -1) return;
        let inc = Math.floor(find / this.maxStepDisplayed);
        if (inc === 0) return;
        this.nbPrevSteps = 0;
        while (inc--) {
          this.moveNextStep();
        }
      },
    },
    methods: {
      setActive(roundTransportStepId) {
        this.$emit('input', roundTransportStepId);
        this.$emit('point-click');
      },
      moveNextStep() {
        if (this.canNext) this.nbPrevSteps += this.currentSteps.length;
      },
      movePrevStep() {
        if (this.canPrev) this.nbPrevSteps -= this.maxStepDisplayed;
      },
      setClass() {
        if (this.canPrev && this.canNext) return 'prev-next';
        else if (this.canPrev) return 'prev';
        else if (this.canNext) return 'next';
      },
      getStyleForPosition(step) {
        let style = '';
        // compteur permettant de compter le nombre de date � "D�s que possible" et de d�caler l'affichage de celles-ci sur la timeline
        let cmp = 0;
        let percent = 0;
        const currentStepMoment = this.getMomentFromStep(step);
        if (
          (this.currentFirstStepMoment === null && this.currentLastStepMoment === null) ||
          currentStepMoment === null
        ) {
          percent = 100 - cmp;
          cmp += 1;
        } else {
          const maxMinutes = moment
            .duration(this.currentLastStepMoment.diff(this.currentFirstStepMoment))
            .asMinutes();
          const minutes = moment.duration(currentStepMoment.diff(this.currentFirstStepMoment)).asMinutes();
          percent = maxMinutes > 0 ? (minutes * 100) / maxMinutes : 0;
        }
        if (this.currentSteps.length === 1) percent = 100;
        style += `left: calc(${percent}%;`;
        return style;
      },
      getMomentFromStep(step) {
        if (step.stepDate.estimatedDate) return moment(step.stepDate.estimatedDate);
        else if (step.stepDate.plannedEnd === null && step.stepDate.plannedStart === null) {
          return null;
        } else {
          return moment(step.stepDate.plannedEnd?.dateTime || step.stepDate.plannedStart?.dateTime);
        }
      },
    },
  };
</script>
