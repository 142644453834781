import axios from 'axios';
import GenerateRoundDocumentReportRequest from './models/reports';
import GetCitiesQuery from './queries/cities';
import GetCountriesQuery from './queries/countries';
import { GetCustomersQuery, GetCustomerReferencesQuery } from './queries/customers';
import ItineraryRequest from './queries/maps';
import GetOrderersQuery from './queries/orderers';
import QueryBase from './queries/query';
import GetRoundsQuery from './queries/rounds';
import { GetAddressesQuery, GetAddressQuery } from './queries/addresses';

const state = () => ({
  apiContext: {
    baseUrl: '/api/',
    paramsSerializer: (params) => {
      console.info('Given request params is a QueryBase :', params instanceof QueryBase, params);
      return params instanceof QueryBase ? params.toQs() : QueryBase.SerializerFn(params);
    },
  },
  mapsApiContext: {
    token: '',
    baseUrl: '',
    appSourceId: 'operator-rounds-web',
  },
});

const getters = {
  $api:
    (state, _, rootState, rootGetters) =>
    ({ useResponseInterceptor = true, responseType = null } = {}) => {
      const instance = axios.create({
        baseURL: state.apiContext.baseUrl,
        paramsSerializer: state.apiContext.paramsSerializer,
      });
      instance.interceptors.request.use(function (config) {
        const token = rootGetters.apiToken;
        if (token) {
          config.headers.RoundAppToken = token;
        }
        if (responseType) {
          config.responseType = responseType;
        }
        return config;
      });
      if (!useResponseInterceptor) return instance;

      instance.interceptors.response.use(
        (response) => {
          return response?.data || null;
        },
        (error) => {
          // TODO: handle error
          console.error(error.message, error.response);
          // TODO: handle unauthorized
          if (error.response.status === 401) {
            console.info('API returned unauthorized, unsetting app token');
            rootState.context.settings.appToken = null;
          }
          return null;
        },
      );
      return instance;
    },

  $mapsApi: (state) => {
    if (!state.mapsApiContext.baseUrl) return null;
    const instance = axios.create({ baseURL: state.mapsApiContext.baseUrl });
    instance.interceptors.response.use(
      (response) => response.data,
      () => null,
    );
    return instance;
  },

  isMapContextInitialized: (state) => {
    return !!state.mapsApiContext?.token && !!state.mapsApiContext?.baseUrl;
  },

  getItineraryRequest: (state, _, __, rootGetters) => (addresses) =>
    new ItineraryRequest({
      token: state.mapsApiContext.token,
      source: state.mapsApiContext.appSourceId,
      lang: rootGetters.lang,
      addresses,
    }),
};

/* eslint-disable no-unused-vars */
const actions = {
  async getCustomers({ getters }, req) {
    console.info('api/getCustomers: ', req);
    return getters.$api().get('customers', { params: new GetCustomersQuery(req) });
  },

  async getOrderers({ getters }, req) {
    console.info('api/getOrderers: ', req);
    return getters.$api().get('orderers', { params: new GetOrderersQuery(req) });
  },

  async getReferenceValues({ getters }, req) {
    console.info('api/getReferenceValues : ', req);
    return getters
      .$api()
      .get(`customers/${req.customerUid}/references`, { params: new GetCustomerReferencesQuery(req) });
  },

  async getAddresses({ getters }, req) {
    console.info('api/getAddresses: ', req);
    return getters.$api().get('addresses', { params: new GetAddressesQuery(req) });
  },

  async getAddress({ getters }, req) {
    console.info('api/getAddress: ', req);
    return getters.$api().get('addresses/by-address-id', { params: new GetAddressQuery(req) });
  },

  async getCountries({ getters }, req) {
    console.info('api/getCountries: ', req);
    return getters.$api().get('countries', { params: new GetCountriesQuery(req) });
  },

  async getCities({ getters }, req) {
    console.info('api/getCities : ', req);
    return getters.$api().get('cities', { params: new GetCitiesQuery(req) });
  },

  async getRounds({ getters }, req) {
    console.info('api/getRounds: ', req);
    return getters.$api().get('rounds/summary', { params: new GetRoundsQuery(req) });
  },

  async getAccount({ dispatch, getters }) {
    console.info('api/getAccount');
    const response = await getters.$api().get('me');
    if (response && response.hasAccessToEurekaMaps) {
      dispatch('initMapContext');
    }
    return response;
  },

  async calculateItinerary({ getters }, bounds) {
    console.info('api/calculateItinerary');
    if (!getters.$mapsApi) return null;

    const request = getters.getItineraryRequest(bounds);
    return getters.$mapsApi.post('CalculateItinerary', request);
  },

  async generateRoundSheet({ getters }, req) {
    console.info('api/generateRoundSheet', req);
    return getters.$api().post('round-document-reports', new GenerateRoundDocumentReportRequest(req));
  },

  async getRoundSheetGenerationStatus({ getters }, uid) {
    console.info('api/getRoundSheetGenerationStatus', uid);
    return getters.$api().get(`round-document-reports/${uid}`);
  },

  async getRoundSheetFile({ getters }, uid) {
    console.info('api/getRoundSheetFile', uid);
    const response = await getters
      .$api({ useResponseInterceptor: false, responseType: 'blob' })
      .get(`round-document-reports/${uid}/data`);
    return { type: response?.headers['content-type'] || 'application/octet-stream', data: response?.data };
  },

  async initMapContext({ commit, getters }) {
    console.info('api/initMapContext');
    const response = await getters.$api().get('me/eureka-maps');
    if (response) commit('SET_MAP_CONTEXT', response);
    return response;
  },

  async getDriverSmoothRoute({ getters }, { id, startDate, endDate }) {
    console.info('api/getDriverSmoothRoute');
    return getters.$api().get(`rounds/${id}/driver-smooth-route?startDate=${startDate}&endDate=${endDate}`);
  },
};

const mutations = {
  SET_MAP_CONTEXT(state, apiContext) {
    state.mapsApiContext.token = apiContext.eurekaMapsToken;
    state.mapsApiContext.baseUrl = apiContext.eurekaMapsUrl;
    state.mapsApiContext.copyright = apiContext.eurekaMapsCopyright;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
