<template>
  <v-card class="round-details" elevation="0">
    <v-card-text class="round-detail-content pa-0">
      <div
        class="round-detail-headers"
        :class="canDisplayRoundRealizedAndEstimatedHours ? 'realized-estimated' : ''"
        ref="header"
        v-if="!hideHeader"
      >
        <div class="round-detail-header text-center">Mission</div>
        <div class="round-detail-header text-left customer-step-name-header"><b>Client</b><br />Nom de l'étape</div>
        <div class="round-detail-header text-center">Adresse de l'étape</div>
        <div class="round-detail-header text-center">Etapes regroupées</div>
        <div class="round-detail-header text-center planned-hour">Heure prévue</div>
        <div class="round-detail-header text-center" v-if="canDisplayRoundRealizedAndEstimatedHours">Heure estimée</div>
        <div class="round-detail-header text-center" v-if="canDisplayRoundRealizedAndEstimatedHours">
          Heure réalisée
        </div>
      </div>
      <div class="round-detail" ref="detail">
        <v-timeline dense ref="details">
          <template v-for="transportStep in transportSteps">
            <v-timeline-item
              :key="'detail-row-number-' + transportStep.stepRowNumber"
              right
              :color="getTimelineStatusColor(transportStep)"
              fill-dot
              :id="`detail-${transportStep.roundTransportStepId}`"
              class="round-detail-timeline"
              :class="getStepClasses(transportStep)"
              small
            >
              <template slot="icon">
                <v-avatar @click="select(transportStep.roundTransportStepId)">
                  <span :class="getTimelineStatusTextColor(transportStep)">
                    {{ transportStep.stepRowNumber }}
                  </span>
                </v-avatar>
              </template>
              <div
                class="round-detail-row"
                :class="canDisplayRoundRealizedAndEstimatedHours ? 'realized-estimated' : ''"
                @click="select(transportStep.roundTransportStepId)"
              >
                <div class="round-detail-col">
                  <a
                    v-if="transportStep.groupedStepsCount === 1"
                    :href="getShipmentDetailUrl(transportStep)"
                    class="round-detail-link"
                    target="_blank"
                  >
                    {{ transportStep.missionNumber }}
                  </a>
                </div>
                <div class="round-detail-col row">
                  <v-icon
                    size="20"
                    :color="transportStep.isPickup ? 'badge-step-pickup' : 'badge-step-delivery'"
                    class="align-self-center"
                  >
                    fas fa-circle
                  </v-icon>
                  <span class="round-detail-step-letter align-self-center">
                    <span v-if="transportStep.isPickup">E</span>
                    <span v-else>L</span>
                  </span>
                  <div class="flex col">
                    <div>
                      <b>{{ transportStep.customerCode }}</b>
                    </div>
                    <div>{{ transportStep.stepAddress.name }}</div>
                  </div>
                </div>
                <div class="round-detail-col text-center">
                  {{ transportStep.stepAddress.streetName }}
                  <br v-if="transportStep.stepAddress.streetName" />
                  {{ transportStep.stepAddress.postCode }}
                  {{ transportStep.stepAddress.cityName }}
                </div>
                <div class="round-detail-col text-center">{{ transportStep.groupedStepsCount }}</div>
                <div class="round-detail-col text-center">
                  {{ getPlannedDate(transportStep.stepDate) }}<br />
                  <b>{{ getPlannedTime(transportStep.stepDate) }}</b>
                </div>
                <div class="round-detail-col text-center" v-if="canDisplayRoundRealizedAndEstimatedHours">
                  {{ getDate(transportStep.stepDate.estimatedDate) }}<br />
                  <b>{{ getTime(transportStep.stepDate.estimatedDate) }}</b>
                </div>
                <div class="round-detail-col text-center" v-if="canDisplayRoundRealizedAndEstimatedHours">
                  {{ getDate(transportStep.stepDate.effectiveDate) }}<br />
                  <b>{{ getTime(transportStep.stepDate.effectiveDate) }}</b>
                </div>
              </div>
            </v-timeline-item>
            <div
              class="last-round-detail-realized"
              :key="'last-step-realized-' + transportStep.roundTransportStepId"
              v-if="transportStep.roundTransportStepId === lastStepIdRealized"
            >
              <v-icon color="black" class="last-round-detail-realized-arrow" small> fas fa-angle-double-down </v-icon>
            </div>
          </template>
        </v-timeline>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import MomentMixin from '@/mixins/moment';
import TimelineColorsMixin from '@/mixins/timeline-colors';
import ShipmentMixin from '@/mixins/shipment';

export default {
  name: 'grid-line-detail',
  mixins: [MomentMixin, TimelineColorsMixin, ShipmentMixin],
  props: {
    transportSteps: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
    maxRow: {
      type: Number,
      default: 10,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setMaxHeight();
    this.scrollTo();
    if (!this.hideHeader) this.$refs.detail.style.marginTop = `${this.$refs.header.offsetHeight}px`;
  },
  computed: {
    ...mapState({
      canDisplayRoundRealizedAndEstimatedHours: (state) => state.context.rights.displayRealizedAndEstimatedHours,
    }),
    lastStepIdRealized() {
      let transportStepId = null;
      for (let index = 0, len = this.transportSteps.length; index < len; index++) {
        const transportStep = this.transportSteps[index];
        if (!this.isPickupRealized(transportStep) && !this.isDeliveryRealized(transportStep)) {
          break;
        }
        transportStepId = index === this.transportSteps.length - 1 ? null : transportStep.roundTransportStepId;
      }
      return transportStepId;
    },
    associatedSteps() {
      if (!this.value) return [];
      const step = this.transportSteps.find((t) => t.roundTransportStepId === this.value);
      if (!step) return [];

      const missionNumber = step.missionNumber;
      const searchForDeliveries = step.isPickup === true;
      return this.transportSteps.reduce((prev, curr) => {
        if (curr.isPickup && searchForDeliveries) return prev;
        if (!curr.isPickup && !searchForDeliveries) return prev;
        if (curr.missionNumber !== missionNumber) return prev;
        return [...prev, curr.roundTransportStepId];
      }, []);
    },
  },
  methods: {
    select(id) {
      this.$emit('input', id);
    },
    scrollTo() {
      if (!this.value) return;
      this.$nextTick(() => {
        setTimeout(() => {
          const targetElement = this.$el.querySelector(`#detail-${this.value}`);
          if (!targetElement) return;
          this.$refs.detail.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
        }, 200);
      });
    },
    setMaxHeight() {
      this.$refs.detail.style.maxHeight = 'none';
      if (!this.maxHeight) return;
      if (this.$refs.details.$children.length <= this.maxRow) return;
      let height = 0;
      for (let index = 0; index < this.maxRow; index++) {
        const detail = this.$refs.details.$children[index];
        height += detail.$el.offsetHeight;
      }
      this.$refs.detail.style.maxHeight = `${height}px`;
      if (this.hideHeader) return;
      this.$refs.header.style.paddingRight = `${this.$refs.detail.offsetWidth - this.$refs.detail.clientWidth}px`;
    },
    getStepClasses(transportStep) {
      const isSelected = this.value === transportStep.roundTransportStepId;
      const isAssociatedStep = this.associatedSteps.indexOf(transportStep.roundTransportStepId) > -1;
      const isPickup = transportStep.isPickup === true;
      return [
        isSelected ? 'active' : '',
        isAssociatedStep ? 'associated' : '',
        isPickup ? 'pickup' : 'delivery',
        this.getTimelineStatusBorderColor(transportStep),
      ];
    },
  },

  watch: {
    transportSteps() {
      this.setMaxHeight();
      this.scrollTo();
    },
    value() {
      this.scrollTo();
    },
    maxRow() {
      this.setMaxHeight();
    },
  },
};
</script>
<style lang="scss">
.round-detail-timeline.associated {
  &.pickup {
    background-color: var(--v-round-detail-associated-base);
  }

  &.delivery {
    background-color: var(--v-round-detail-associated-base);
  }
}

.round-detail-timeline.active {
  .v-timeline-item__divider {
    border-left: 3px solid;
    border-left-color: var(--v-round-detail-active-base);
    border-right: 3px solid;
    border-right-color: transparent;
  }
}
</style>
