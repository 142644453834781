<template>
  <v-layout wrap align-center>
    <v-flex xs12 sm12 md4>
      <h4>{{ label }}</h4>
    </v-flex>
    <v-flex xs12 sm12 md8>
      <v-autocomplete
        v-if="!combobox"
        :value="value"
        @input="$emit('input', $event)"
        @update:search-input="$emit('search', $event)"
        :items="list"
        item-text="text"
        item-value="value"
        :no-data-text="noDataText"
        :hide-no-data="hideNoData"
        :loading="loading"
        cache-items
        :multiple="multiple"
        :chips="multiple"
        :deletable-chips="multiple"
        clearable
        clear-icon="fas fa-times"
      />
      <v-combobox
        v-else
        :value="value"
        @input="$emit('input', $event)"
        @update:search-input="$emit('search', $event)"
        :items="list"
        item-text="text"
        item-value="value"
        :no-data-text="noDataText"
        :hide-no-data="hideNoData"
        :loading="loading"
        cache-items
        :multiple="multiple"
        :chips="multiple"
        :deletable-chips="multiple"
        clearable
        clear-icon="fas fa-times"
      />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'filter-autocomplete',
  props: {
    value: {
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    noDataText: {
      type: String,
      default: '',
    },
    hideNoData: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    combobox: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
