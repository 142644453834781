/* eslint-disable no-undef */

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// eslint-disable-next-line no-unused-vars
export default (vueElement, leafletElement, props, options) => {
  const keys = Object.keys(props);
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];

    // // Tooltip property is a bit particular since it set the marker popup content
    // // and so on the "set method" for this property doesnt exist
    if (key === 'tooltip') {
      vueElement.$watch(key, (newVal) => leafletElement.bindPopup(newVal));
      return;
    }

    const setMethodName = 'set' + capitalizeFirstLetter(key);
    const deepValue = props[key].type === Object || props[key].type === Array || Array.isArray(props[key].type);
    if (props[key].custom) {
      vueElement.$watch(key, (newVal, oldVal) => vueElement[setMethodName](newVal, oldVal), { deep: deepValue });
    } else if (setMethodName == 'setOptions') {
      vueElement.$watch(key, (newVal) => L.setOptions(leafletElement, newVal, { deep: deepValue }));
    } else {
      vueElement.$watch(key, (newVal) => {
        // ignore unknown keys
        if (leafletElement[setMethodName] !== undefined) {
          leafletElement[setMethodName](newVal), { deep: deepValue };
        }
      });
    }
  }
};
