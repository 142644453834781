import Vue from 'vue';
import moment from 'moment';
import GetCitiesModel from './api/models/cities';
import GetCountriesModel from './api/models/countries';
import { GetCustomerReferencesModel, GetCustomersModel } from './api/models/customers';
import GetOrderersModel from './api/models/orderers';
import GetRoundsModel from './api/models/rounds';
import GetAddressesModel from './api/models/addresses';

const getDefaultFilters = () => {
  let defaultModel = getEmptyFilters();
  if (!defaultModel.roundReferenceDateFrom) {
    defaultModel.roundReferenceDateFrom = moment().subtract(1, 'months').format('YYYY-MM-DD');
  }
  return defaultModel;
};

const getEmptyFilters = () => new GetRoundsModel();

const state = () => ({
  filters: getDefaultFilters(),
  /* Customers */
  customers: [],
  customersLoading: false,
  customersPaging: null,
  /* Orderers */
  orderers: [],
  orderersLoading: false,
  orderersPaging: null,
  /* References */
  reference1Label: null,
  reference2Label: null,
  reference3Label: null,
  /* Countries */
  countries: [],
  countriesLoading: false,
  countriesPaging: null,
  /* Addresses */
  addresses: [],
  addressesLoading: false,
  addressesPaging: null,
});

const getters = {
  getEmptyFilters: () => getEmptyFilters,
};

const actions = {
  /*
   * All fields in GetCustomersModel can be passed as request
   * Additional fields :
   * - searchCodeAndLabel
   */
  async getCustomers({ commit, dispatch }, request = new GetCustomersModel()) {
    commit('LOADING_CUSTOMERS');
    let response;
    try {
      response = await dispatch('api/getCustomers', request, { root: true });
    } finally {
      commit('CUSTOMERS_LOADED', response);
    }
  },

  /*
   * All fields in GetOrderersModel can be passed as request
   */
  async getOrderers({ commit, dispatch }, request = new GetOrderersModel()) {
    commit('LOADING_ORDERERS');
    let response;
    try {
      response = await dispatch('api/getOrderers', request, { root: true });
    } finally {
      commit('ORDERERS_LOADED', response);
    }
  },

  /*
   * All fields in GetOrderersModel can be passed through inner request object
   */
  async getReferenceValues({ dispatch, state }, request = new GetCustomerReferencesModel()) {
    let response;
    try {
      const customerUid = state.customers.find((c) => c.code === state.filters.transportCustomerCodes[0])?.uid;
      if (!customerUid) throw new Error('No customer uid to load references');
      response = await dispatch('api/getReferenceValues', { ...request, customerUid }, { root: true });
    } catch {
      response = { data: [], paging: null };
    }
    return response;
  },

  /*
   * All fields in GetAddressesModel can be passed through inner request object
   */
  async getAddresses({ commit, dispatch }, request = new GetAddressesModel()) {
    commit('LOADING_ADDRESSES');
    let response;
    try {
      response = await dispatch('api/getAddresses', request, { root: true });
    } finally {
      commit('ADDRESSES_LOADED', response);
    }
  },

  /*
   * All fields in GetCountriesModel can be passed through inner request object
   * Additional fields :
   * - searchCodeAndLabel
   */
  async getCountries({ commit, dispatch }, request = new GetCountriesModel()) {
    commit('LOADING_COUNTRIES');
    let response;
    try {
      response = await dispatch('api/getCountries', { ...request, searchCodeAndLabel: true }, { root: true });
    } finally {
      commit('COUNTRIES_LOADED', response);
    }
  },

  /*
   * All fields in GetCountriesModel can be passed through inner request object
   * Additional fields :
   * - searchOnBoth
   */
  async getCities({ dispatch }, request = new GetCitiesModel()) {
    let response;
    try {
      response = await dispatch('api/getCities', request, { root: true });
    } catch {
      response = { data: [], paging: null };
    }
    return response;
  },

  resetReferences({ commit }) {
    commit('RESET_REFERENCES_LABELS');
  },
};

const mutations = {
  LOADING_CUSTOMERS(state) {
    state.customersLoading = true;
  },
  CUSTOMERS_LOADED(state, response) {
    state.customersLoading = false;
    if (response) {
      state.customers = response.data || [];
      state.customersPaging = response.paging;
    }
  },

  LOADING_ORDERERS(state) {
    state.orderersLoading = true;
  },
  ORDERERS_LOADED(state, response) {
    state.orderersLoading = false;
    if (response) {
      state.orderers = response.data || [];
      state.orderersPaging = response.paging;
    }
  },

  RESET_REFERENCES_LABELS(state) {
    state.reference1Label = null;
    state.reference2Label = null;
    state.reference3Label = null;
  },

  LOADING_ADDRESSES(state) {
    state.addressesLoading = true;
  },
  ADDRESSES_LOADED(state, response) {
    state.addressesLoading = false;
    if (response) {
      state.addresses = response.data || [];
      state.addressesPaging = response.paging;
    }
  },

  LOADING_COUNTRIES(state) {
    state.countriesLoading = true;
  },
  COUNTRIES_LOADED(state, response) {
    state.countriesLoading = false;
    if (response) {
      state.countries = response.data || [];
      state.countriesPaging = response.paging;
    }
  },

  setFilters(state, filters) {
    state.filters = filters;
  },
  resetFilters(state) {
    state.filters = getEmptyFilters();
  },
  resetFiltersKey(state, key) {
    const defaultFilters = getEmptyFilters();
    if (typeof defaultFilters[key] === 'undefined') return;
    Vue.set(state.filters, key, defaultFilters[key]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
