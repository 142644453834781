class GeographyRequest {
  constructor(token, lang, source) {
    this.Token = token;
    this.Lang = lang;
    this.Source = source;
  }
}

export default class ItineraryRequest extends GeographyRequest {
  constructor({ token, lang, source, addresses }) {
    super(token, lang, source);
    this.Profile = {
      CalculationTypeWanted: 0, // 0 = Optimized for time, 1 = Optimized for distance
      AvoidTollRoad: false,
      AvoidFerries: false,
      FavourHighways: false,
      AvoidUrbanAreas: false,
      AvoidLowEmissionZones: false,
      RoutingStartTime: null,
      VehicleOption: null,
      RoutingDestTime: null,
      UseTruckAttributes: null,
    };
    this.Addresses = addresses;
    this.DownloadTrace = true;
    this.WithRoutePoint = true;
  }
}

export { GeographyRequest, ItineraryRequest };
