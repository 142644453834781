<template>
  <v-dialog
    v-model="isShown"
    fullscreen
    hide-overlay
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
    content-class="rounds-map-dialog"
  >
    <v-card class="rounds-map">
      <v-card-text class="py-0 pr-0 rounds-map-container">
        <div @click="minify">
          <map-tracks
            ref="map"
            :tracks-coordinates="tracksDisplayed"
            :markers-clustered-sets="markers"
            center-to-france-at-init
            :min-zoom="4"
            :max-zoom="18"
            :style="styleMapComponent"
            :tracks-colors="tracksColors"
            zoom-position="bottomright"
            @click:polyline="showRoundInMenu"
            :polyline-selected="polylineSelected"
          >
            <v-icon small slot="fitToBounds-content" class="rounds-map-fit-to-bounds" @click="resetView"
              >fas fa-expand</v-icon
            >
          </map-tracks>
        </div>
        <map-menu v-if="isShown" :mini.sync="mini" />
        <div v-if="loading" class="rounds-map-overlay">
          <v-layout align-center justify-center fill-height>
            <v-spacer />
            <v-flex shrink>
              <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
            </v-flex>
            <v-spacer />
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import MapTracks from '@/components/map/map-tracks.vue';
import StepStatesMixin from '@/mixins/step-states';
import MapMenu from './map-menu';

export default {
  name: 'r-map',
  mixins: [StepStatesMixin],
  components: {
    MapTracks,
    MapMenu,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      polylineSelected: {
        index: null,
        weight: 6,
      },
      mini: true,
      debouncedBringFocusedRoundTrackInFront: null,
    };
  },
  mounted() {
    this.debouncedBringFocusedRoundTrackInFront = debounce(() => this.bringFocusedRoundTrackInFront(), 300);
  },
  computed: {
    ...mapState('map', ['roundsSelected', 'roundIdFocused', 'stepSelected']),
    ...mapGetters('map', ['stepsDisplayed', 'tracksDisplayed', 'tracksColors', 'tracksDriverDisplayed']),

    isShown: {
      get: function () {
        return this.value;
      },
      set: function () {
        this.$emit('input', !this.value);
      },
    },

    styleMapComponent() {
      return {
        height: `${window.innerHeight - 64}px`, // window height - toolbar height
      };
    },

    // Convert the complete list steps to an object like { roundId: steps[] }
    stepsDisplayedByRound() {
      return this.stepsDisplayed.reduce((prev, curr) => {
        if (prev[curr.round.id]) prev[curr.round.id].push(curr);
        else prev[curr.round.id] = [curr];
        return prev;
      }, {});
    },

    // Use the list of computed steps[] to extract for each the next step not realized
    nextStepsIdToRealized() {
      return Object.values(this.stepsDisplayedByRound).reduce((prev, roundSteps) => {
        const firstNotRealizedStep = roundSteps.find(
          (ts, index) => index !== roundSteps.length - 1 && !this.isPickupRealized(ts) && !this.isDeliveryRealized(ts),
        );
        return !firstNotRealizedStep ? prev : [...prev, firstNotRealizedStep.roundTransportStepId];
      }, []);
    },

    // Returns a list of markers for each selected round
    // We have to loop through roundsSelected because order is important
    // and has to be kept in the same order than tracksDisplayed or tracksColors
    markers() {
      return this.roundsSelected.map((roundSelected) =>
        this.stepsDisplayedByRound[roundSelected.id]
          .filter((ts) => ts.stepAddress.longitude && ts.stepAddress.latitude)
          .map((ts) => {
            let { id: roundId, color } = ts.round;
            const stepId = ts.roundTransportStepId;
            const stepDisplayed = this.stepsDisplayed.find(s => s.roundTransportStepId === stepId);
            if (stepDisplayed.stepLateStatus !== this.LATE_STATUS.Undetermined) {
              color = '#9D9D9D';
            }

            const marker = {
              number: ts.stepRowNumber,
              color,
              roundId,
              position: {
                Longitude: ts.stepAddress.longitude,
                Latitude: ts.stepAddress.latitude,
              },
            };

            // Tooltip only for next steps to realized
            marker.tooltip =
              this.roundIdFocused !== roundId || !this.nextStepsIdToRealized.includes(stepId)
                ? null
                : {
                    text: 'Prochaine étape',
                    options: {
                      closeButton: false,
                      closeOnEscapeKey: false,
                      closeOnClick: false,
                      autoClose: false,
                      className: `rounds-map-tooltip-${color}`,
                    },
                    allwaysOpen: true,
                  };
            // Click on marker select associated step
            marker.action = () => {
              const step = {
                id: stepId,
                forceExpand: true,
                bounds: [
                  {
                    Longitude: ts.stepAddress.longitude,
                    Latitude: ts.stepAddress.latitude,
                  },
                ],
              };
              this.selectStep({ id: ts.round.id, step });
            };
            return marker;
          }),
      );
    },
  },
  methods: {
    ...mapActions('map', ['calculateItinerary']),
    ...mapActions('map', ['focusOnRound', 'selectStep', 'resetFocus', 'resetRoundFocus', 'resetSelectedStep']),

    bringFocusedRoundTrackInFront() {
      if (!this.isShown || (!this.stepSelected?.roundId && !this.roundIdFocused)) {
        if (this.tracksDisplayed.length) {
          this.fitToBounds();
        } else {
          this.$refs.map.centerToFrance();
        }
      }
      const trackIndex = this.roundsSelected.findIndex(
        (r) => r.id === this.stepSelected?.roundId || r.id === this.roundIdFocused,
      );
      const changed = this.polylineSelected.index !== trackIndex;
      if (changed) {
        this.polylineSelected.index = trackIndex;
        this.fitToBounds(trackIndex);
      }
      return { changed, trackIndex };
    },
    resetView() {
      this.$nextTick(() => (this.polylineSelected.index = null));
      this.resetFocus();
    },
    minify() {
      if (this.stepSelected) return;
      this.mini = true;
    },
    showRoundInMenu(index) {
      const targetRoundId = this.roundsSelected[index].id;
      if (this.roundIdFocused === targetRoundId) {
        this.resetRoundFocus();
      } else {
        this.focusOnRound(targetRoundId);
        this.resetSelectedStep();
      }
    },
    fitToBounds(trackIndex = -1) {
      if (this.stepSelected) this.$refs.map.fitToBounds(this.stepSelected.bounds, false);
      else if (trackIndex >= 0) this.$refs.map.fitToBounds(this.tracksDisplayed[trackIndex], false);
      else this.$refs.map.fitToBounds();
    },
  },
  watch: {
    stepSelected: {
      deep: true,
      handler(newval) {
        if (!this.debouncedBringFocusedRoundTrackInFront) return;

        const { changed } = this.debouncedBringFocusedRoundTrackInFront();
        if (!changed && newval) this.fitToBounds();
      },
    },
    roundIdFocused() {
      this.debouncedBringFocusedRoundTrackInFront();
    },
    tracksDisplayed() {
      this.debouncedBringFocusedRoundTrackInFront();
    },
    tracksDriverDisplayed() {
      this.debouncedBringFocusedRoundTrackInFront();
    },
  },
};
</script>
