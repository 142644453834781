<template>
  <eureka-maps
    ref="eurekaMaps"
    :map-container-height="height"
    :tracks="tracks"
    :markers="markers"
    :animated-markers="animatedMarkers"
    :poi-markers="poiMarkers"
    :center="defaultCenter"
    @map-is-ready="mapIsReady"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
    :colors-tracks="tracksColors"
    :markersClustered="markersClustered"
    :zoom-position="zoomPosition"
    :polyline-selected="polylineSelected"
    @click:polyline="$emit('click:polyline', $event)"
  >
    <template slot="fitToBoundsContent">
      <slot name="fitToBounds-content">x</slot>
    </template>
  </eureka-maps>
</template>

<script>
import { leaflet as business } from '@/business';
import EurekaMaps from './eureka-maps.vue';

const itemIsNotArray = (i) => !(i instanceof Array);
const itemIsNotObject = (i) => typeof i !== 'object' || i instanceof Array;

export default {
  name: 'map-tracks',
  components: {
    EurekaMaps,
  },
  props: {
    /*
      This list contains an array for each wanted track (i.e an array of arrays (= an array of "track arrays"))
      Each "track array" must contains "coordinates objects" with, for each :
      - a "latitude" property  : Number (and should be named : "Y" || "Latitude"  || "lat")
      - a "longitude" property : Number (and should be named : "X" || "Longitude" || "lng")
    */
    tracksCoordinates: {
      type: Array,
      required: true,
      validator(val) {
        if (!val || !(val instanceof Array)) return false;

        const isKo = !!val.find((tc) => itemIsNotArray(tc) || !!tc.find(itemIsNotObject));
        return !isKo;
      },
    },

    /*
      This list contains an for each wanted set of markers (i.e an array of arrays (= an array of "markers set"))
      Each "markers set" must contains "marker objects" with, for each :
      - a position property : Object containing  :
      -- a "latitude" property  : Number (and should be named : "Y" || "Latitude" || "lat" )
      -- a "longitude" property : Number (and should be named : "X" || "Longitude"|| "lng")
      - a tooltip property (optional) : String || HTML String
      - a useIcon property (optional) : Boolean
      - a color property   (optional) : String
      - an icon property    (optional) : String
    */
    markersSets: {
      type: Array,
      default: () => [],
      validator(val) {
        if (!val || !(val instanceof Array)) return false;

        const isKo = !!val.find((ms) => itemIsNotArray(ms) || !!ms.find(itemIsNotObject));
        return !isKo;
      },
    },

    markersClusteredSets: {
      type: Array,
      default: () => [],
      validator(val) {
        if (!val || !(val instanceof Array)) return false;

        const isKo = !!val.find((ms) => itemIsNotArray(ms) || !!ms.find(itemIsNotObject));
        return !isKo;
      },
    },

    flyToCenter: {
      type: Boolean,
      default: false,
    },

    centerToFranceAtInit: {
      type: Boolean,
      default: false,
    },

    centerAtInit: {
      type: Boolean,
      default: false,
    },

    defaultCenter: {
      type: Object,
      default: () => ({ lat: 47.41322, lng: -1.219482 }),
    },

    animatedMarkers: {
      type: Array,
      default: () => [],
    },

    poiMarkers: {
      type: Array,
      default: () => [],
    },

    height: {
      type: String,
      default: '',
    },
    /*
    Optional color to be applied to all the markers. When specified, it overrides the default colors.
    */
    markersColor: {
      type: String,
      default: '',
    },
    tracksColors: {
      type: Array,
      default: null,
    },
    minZoom: {
      type: Number,
      default: null,
    },
    maxZoom: {
      type: Number,
      default: null,
    },
    zoomPosition: {
      type: String,
      default: 'topleft',
    },
    polylineSelected: {
      type: Object,
      default: () => ({
        index: null,
        weight: 3,
      }),
    },
  },
  mounted() {
    // Invoked in $nextTick for $refs to be initialized
    this.$nextTick(() => {
      // Adjust tiles sizes to container
      this.redraw();

      // Center options
      if (this.centerAtInit) {
        this.center();
      }
      if (this.centerToFranceAtInit) {
        this.centerToFrance();
      }
    });
  },
  data() {
    return {
      icons: ['sign-out-alt', 'sign-in-alt'],
      colors: ['green', 'orange', 'red'],
    };
  },

  computed: {
    tracks() {
      // Transform [ [ {address.GeoXY} ], ... ] to [ [ {Leaflet.LatLng} ], ... ]
      return this.tracksCoordinates.map((trackCoordinates) => business.getLatLongFromCoordinates(trackCoordinates));
    },

    markers() {
      // Markers number sequence is reset for each marker set
      // => example :
      // for markersSets = [ [A, B, C, D] ]   => result in 1 set of markers : 1, 2, 3, 4
      // for marlersSets = [ [A, B], [C, D] ] => result in 2 sets of markers : 1,2 for first track and 1,2 for second track
      return this.markersSets.reduce((result, markersSet) => {
        const markers = markersSet.map((marker, index) => {
          const tooltip = marker.tooltip || null;
          const color = this.markersColor || marker.color || this.colors[index % this.colors.length];
          const latLng = business.getLatLongFromCoordinates(marker.position);

          if (marker.useIcon || marker.icon) {
            const icon = marker.icon || this.icons[index % this.icons.length];
            return { latLng, icon, color, tooltip };
          } else {
            const number = marker.number || index + 1;
            return { latLng, number, color, tooltip };
          }
        });
        return [...result, ...markers];
      }, []);
    },

    markersClustered() {
      return this.markersClusteredSets.reduce((result, markersSet) => {
        const markers = markersSet.map((marker, index) => {
          const tooltip = marker.tooltip || null;
          const color = this.markersColor || marker.color || this.colors[index % this.colors.length];
          const action = marker.action || null;
          const latLng = business.getLatLongFromCoordinates(marker.position);

          if (marker.useIcon || marker.icon) {
            const icon = marker.icon || this.icons[index % this.icons.length];
            return { latLng, icon, color, tooltip, action };
          } else {
            const number = marker.number || index + 1;
            return { latLng, number, color, tooltip, action };
          }
        });
        return [...result, ...markers];
      }, []);
    },

    boundsCoordinates() {
      const markersCoordinates = this.markers.map((m) => m.latLng);
      const tracksCoordinates = this.tracksCoordinates.reduce(
        (result, trackCoordinates) => [...result, ...trackCoordinates],
        [],
      );
      return [...tracksCoordinates, ...markersCoordinates];
    },
  },

  methods: {
    getAnimatedMarkersPositions() {
      if (!this.$refs.eurekaMaps) return [];
      return this.$refs.eurekaMaps.getAnimatedMarkersPositions();
    },

    centerToFrance() {
      const maxBounds = [
        { lat: 41.205523, lng: -5.489264 },
        { lat: 51.31173, lng: 7.86427 },
      ];
      this.center(maxBounds);
    },
    center(coordinates = null, fly = false, doNotUseProp = false) {
      const shouldFly = doNotUseProp ? fly : fly || this.flyToCenter;

      if (shouldFly) {
        this.flyToBounds(coordinates);
      } else {
        this.fitToBounds(coordinates);
      }
    },

    setView(coordinate, zoom) {
      return this.$refs.eurekaMaps.setView(coordinate, zoom);
    },

    flyToBounds(bounds) {
      if (!this.$refs.eurekaMaps) return;

      const toBounds = bounds || this.boundsCoordinates;
      if (!toBounds.length) return;

      this.$refs.eurekaMaps.flyToBounds(toBounds, { maxZoom: 12 });
    },

    fitToBounds(bounds, setDefaultZoom = true) {
      if (!this.$refs.eurekaMaps) return;

      const toBounds = bounds || this.boundsCoordinates;
      if (!toBounds.length) return;

      this.$refs.eurekaMaps.fitBounds(toBounds, null, setDefaultZoom);
    },

    redraw() {
      if (!this.$refs.eurekaMaps) return;
      this.$refs.eurekaMaps.invalidateSize();
    },
    mapIsReady() {
      this.$emit('map-is-ready');
    },
    showFeatureGroupLayerClustered(index) {
      if (!this.markersClusteredSets.length) return;
      this.$refs.eurekaMaps.showFeatureGroupLayer(
        index,
        this.markersClusteredSets[index].map((m) => business.getLatLongFromCoordinates(m.position)),
      );
    },
    showFeatureGroupLayer(index) {
      if (!this.markersSets.length) return;
      this.$refs.eurekaMaps.showFeatureGroupLayer(
        index,
        this.markersSets[index].map((m) => business.getLatLongFromCoordinates(m.position)),
      );
    },
  },
};
</script>
