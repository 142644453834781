<template>
  <v-dialog v-model="isSessionExpired" persistent max-width="600">
    <v-card>
    <v-card-title class="headline">
      <h2 class="text-center sessionExpired-title">Votre session a expiré</h2>
    </v-card-title>
    <v-card-text>
      <h3 class="text-center sessionExpired-message">Veuillez rafraîchir la page</h3>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'session-expired-dialog',
  data() {
    return {
      isSessionExpired: false
    };
  },
  computed: {
    ...mapState({
      apiToken: (state) => state.context.settings.appToken,
    }),
  },
  watch: {
    apiToken(newValue, oldValue) {
      let sessionWasExpired = !oldValue;
      let sessionIsExpired = !newValue;
      this.isSessionExpired = sessionIsExpired;
      console.log(`Session expiration changed : ${sessionWasExpired} -> ${sessionIsExpired}`);
    }
  },
};
</script>
