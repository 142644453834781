import StepStatesMixin from './step-states';

export default {
  mixins: [StepStatesMixin],
  methods: {
    getTimelineStatusColor(step) {
      if (
        step.stepLateStatus === this.LATE_STATUS.NotLate &&
        (this.isPickupRealized(step) || this.isDeliveryRealized(step))
      ) {
        return 'success';
      } else if (step.stepLateStatus === this.LATE_STATUS.LatePlanning && !this.isLateStatusBasedOnEstimatedSchedule) {
        return 'success';
      } else if (step.stepLateStatus === this.LATE_STATUS.LatePlanning) {
        return 'warning';
      } else if (step.stepLateStatus === this.LATE_STATUS.LateContractual) {
        return 'error';
      }
      // LATE_STATUS.Undetermined || LATE_STATUS.NotLate but not realized
      return 'white';
    },
    getTimelineStatusTextColor(step) {
      if (this.isStepRealized(step)) return 'white--text';
      return 'black--text';
    },
    getTimelineStatusBorderColor(step) {
      if (this.isStepRealized(step)) return '';
      return 'bordered';
    },
  },
};
