import Vue from 'vue';
import Vuex from 'vuex';
import Api from './api';
import Context from './context';
import Filters from './filters';
import Map from './map';
import Rounds from './rounds';
import Files from './files';

Vue.use(Vuex);

const getters = {};

const actions = {};

export default new Vuex.Store({
  state: {},
  getters,
  actions,
  mutations: {},
  modules: {
    context: Context,
    rounds: Rounds,
    // Namespaced modules
    filters: Filters,
    api: Api,
    map: Map,
    files: Files,
  },
});
