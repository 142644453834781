import BusinessStatus from './business-status';

export default {
  mixins: [BusinessStatus],
  methods: {
    getChipColorFromStatus(status) {
      switch (status) {
        case this.STATUS.Planned:
          return 'chip-status-planned';
        case this.STATUS.InProgress:
          return 'chip-status-in-progress';
        case this.STATUS.Over:
          return 'chip-status-over';
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case this.STATUS.Planned:
          return 'Planifiée';
        case this.STATUS.InProgress:
          return 'En cours';
        case this.STATUS.Over:
          return 'Terminée';
      }
    },
    getLateStatusLabel(status) {
      switch (status) {
        case this.LATE_STATUS.NotLate:
          return "A l'heure";
        case this.LATE_STATUS.LatePlanning:
          if (this.isLateStatusBasedOnEstimatedSchedule) {
            return 'Retard planification';
          } else {
            return "A l'heure";
          }
        case this.LATE_STATUS.LateContractual:
          return 'Retard effectif';
      }
      // Undetermined state is not displayed
      return '';
    },
    getClockColorFromStatus(status) {
      switch (status) {
        case this.LATE_STATUS.NotLate:
          return 'late-clock-not-late';
        case this.LATE_STATUS.LatePlanning:
          if (this.isLateStatusBasedOnEstimatedSchedule) {
            return 'late-clock-late-planning';
          } else {
            return 'late-clock-not-late';
          }
        case this.LATE_STATUS.LateContractual:
          return 'late-clock-late-contractual';
      }
      // Clock is not displayed on case this.LATE_STATUS.Undetermined
      return '';
    },
    isItemHasLateStatus(item) {
      return (
        item.lateStatus === this.LATE_STATUS.LateContractual ||
        (item.lateStatus === this.LATE_STATUS.LatePlanning && this.isLateStatusBasedOnEstimatedSchedule)
      );
    },
  },
};
