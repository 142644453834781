/* eslint-disable no-undef */
import geolib from 'geolib';
import moment from 'moment';

L.AnimatedMarker = L.Marker.extend({
  options: {
    // meters
    distance: 25,
    // ms
    interval: 100,
    // animate on add?
    autoStart: true,
    // callback onend
    onEnd: function () {},
    clickable: false,
    //in degrees 0-360
    rotationAngle: 0,
    rotationOrigin: 'center center',
  },

  initialize: function (latlngs, options) {
    this._previousDirection = 999;
    // Set interval before setLine because it will call animate() method
    // otherwise default value will be used
    if (options.interval) this.options.interval = options.interval;
    this.setLine(latlngs);
    L.Marker.prototype.initialize.call(this, latlngs[0], options);
  },

  onAdd: function (map) {
    L.Marker.prototype.onAdd.call(this, map);

    var self = this;
    map.on('zoomstart movestart', function () {
      self._zooming = true;
      self.disableTransitions();
    });
    map.on('zoomend moveend', function () {
      self._zooming = false;
      self.enableTransitions();
    });

    if (!this._rotatingIcon) {
      this._icon.classList.remove('leaflet-div-icon');
      this._rotatingIcon = document.createElement('img');
      this._rotatingIcon.src = this.options.markerUrl;
      this._rotatingIcon.className = 'wrapper animated-marker ' + this._icon.className;
      this._rotatingIcon.style[L.DomUtil.TRANSFORM + 'Origin'] = this.options.rotationOrigin;
      this._rotatingIcon.style.marginLeft = this.options.markerAnchor.left + 'px';
      this._rotatingIcon.style.marginTop = this.options.markerAnchor.top + 'px';
      this._icon.appendChild(this._rotatingIcon);
    }

    // Start animating when added to the map
    if (this.options.autoStart) {
      this.start();
    }
  },

  animate: function () {
    var self = this,
      len = this._latlngs.length,
      speed = this.options.interval;

    // Normalize the transition speed from vertex to vertex
    if (this._i < len && this._i > 0) {
      var p1 = this._latlngs[this._i],
        p2 = this._latlngs[this._i - 1];

      //            speed = this._latlngs[this._i - 1].distanceTo(this._latlngs[this._i]) / this.options.distance * this.options.interval;
      let duration = Math.abs(moment.duration(p2.date.diff(p1.date)).asMilliseconds());
      speed = duration;
      this.rotateMaker(p1, p2);
      this.enableTransitions(speed);
    }
    // Move to the next vertex
    this.setLatLng(this._latlngs[this._i]);

    if (this.options.centerCallback) {
      this.options.centerCallback(this.getLatLng());
    }

    this._i++;
    // Queue up the animation to the next next vertex
    this._tid = setTimeout(function () {
      if (self._tid) clearTimeout(self._tid);
      if (self._i === len) {
        self.options.onEnd.apply(self, Array.prototype.slice.call(arguments));
      } else {
        self.animate();
      }
    }, speed);
  },

  rotateMaker: function (p1, p2) {
    // Only if CSS3 transitions are supported
    if (L.DomUtil.TRANSITION) {
      if (this._rotatingIcon) {
        this._rotatingIcon.style[L.DomUtil.TRANSITION] = 'transform ' + 300 + 'ms linear';
      }
    }
    var newAngle =
      (180 +
        geolib.getBearing(
          {
            latitude: p1.lat,
            longitude: p1.lng,
          },
          {
            latitude: p2.lat,
            longitude: p2.lng,
          },
        )) %
      360;
    var diff = newAngle - this._previousDirection;
    if (Math.abs(diff) > 180) {
      if (diff < 0) newAngle = 360 - newAngle;
      else newAngle = -(360 - newAngle);
    }
    if (this._previousDirection === 999) this.options.rotationAngle = newAngle;
    else this.options.rotationAngle = newAngle;
    this._previousDirection = newAngle;
    this._rotatingIcon.style[L.DomUtil.TRANSFORM] = ' rotateZ(' + this.options.rotationAngle + 'deg)';
  },

  setTransition: function (element, transition) {
    if (element) {
      element.style[L.DomUtil.TRANSITION] = transition;
    }
  },

  enableTransitions: function (speed) {
    // Only if CSS3 transitions are supported
    if (L.DomUtil.TRANSITION) {
      let transitionDuration = speed || this.options.interval;

      if (transitionDuration > 1000) transitionDuration = transitionDuration / 1000.0 + 's';
      else transitionDuration += 'ms';

      var transition = 'transform ' + transitionDuration + ' linear';
      this.setTransition(this._icon, transition);
      this.setTransition(this._shadow, transition);
    }
  },

  disableTransitions: function () {
    this.setTransition(this._icon, '');
    this.setTransition(this._shadow, '');
  },

  // Start the animation
  start: function () {
    this.animate();
  },

  // Stop the animation in place
  stop: function () {
    this.disableTransitions();
    if (this._tid) {
      clearTimeout(this._tid);
    }
  },

  setLine: function (latlngs) {
    this.stop();
    this.options.rotationAngle = 0;
    if (L.DomUtil.TRANSITION) {
      // No need to to check up the line if we can animate using CSS3
      this._latlngs = latlngs;
    }
    this._i = 0;
    if (this._latlngs.length > 0) this.start();
  },
});

L.animatedMarker = function (latlngs, options) {
  return new L.AnimatedMarker(latlngs, options);
};
