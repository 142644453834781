<template>
  <v-layout wrap align-center>
    <v-flex xs12 sm12 md4>
      <h4>{{ label }}</h4>
    </v-flex>
    <v-flex xs12 sm12 md8>
      <v-layout align-center>
        <v-flex v-for="(item, index) in list" :key="index">
          <v-checkbox
            v-model="model"
            :label="item.text"
            :value="item.value"
            @change="change"
            color="primary"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'filter-checkbox-group',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  computed: {},
  methods: {
    change() {
      this.$emit('input', this.model);
    },
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
};
</script>
