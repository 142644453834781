<template>
  <v-layout fill-height align-center>
    <filter-dialog
      @apply-filters="saveFilters"
      v-model="saveOnLocalStorage"
      :local-storage-available="localStorageAvailable"
    />
    <filter-results
      :filters-result="filters"
      @reset:filter="resetOneFilter"
      @reset:all="resetAllFilters"
      :show-space="false"
    />
  </v-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import FilterResults from './filter-results';
import FilterDialog from './filters-dialog';

export default {
  name: 'r-filter',
  components: {
    FilterResults,
    FilterDialog,
  },
  props: {
    reloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false,
      retry: 0,
      maxRetry: 5,
      timeoutHandler: null,
      saveOnLocalStorage: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getLocalStorage();
      this.debounceSetFilters = debounce((filters) => this.setFilters(filters), 1000);
    });
  },
  computed: {
    ...mapState({
      filters: (state) => state.filters.filters,
      localStorageAvailable: (state) => state.context.localStorageAvailable,
    }),
  },
  methods: {
    ...mapMutations('filters', ['setFilters', 'resetFilters', 'resetFiltersKey']),

    saveFilters($event) {
      this.setLocalStorage($event.filters);
      this.setFilters($event.filters);
    },
    resetOneFilter(key) {
      this.resetFiltersKey(key);
      this.setLocalStorage(this.filters);
    },
    resetAllFilters() {
      this.resetFilters();
      this.setLocalStorage(this.filters);
    },
    // TODO: store localStorage keys in business
    setLocalStorage(filters) {
      if (!this.localStorageAvailable) return;

      if (this.saveOnLocalStorage) {
        localStorage.setItem('filters', JSON.stringify(filters));
        localStorage.setItem('saveOnLocalStorage', this.saveOnLocalStorage);
      } else {
        localStorage.removeItem('filters');
        localStorage.removeItem('saveOnLocalStorage');
      }
    },
    getLocalStorage() {
      if (!this.localStorageAvailable) return;

      const saveOnLocalStorage = localStorage.getItem('saveOnLocalStorage');
      if (saveOnLocalStorage) {
        this.saveOnLocalStorage = true;
        this.setFilters(JSON.parse(localStorage.getItem('filters')));
      }
    },
  },
};
</script>
