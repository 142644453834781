import moment from 'moment';
import { SearchPatternQuery } from './query';
import GetRoundsModel from '../models/rounds';

export default class GetRoundsQuery extends SearchPatternQuery {
  constructor(params) {
    if (!params.fields) params.fields = '*';
    super(params);
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.Exact;
    this.searchPatterns['roundLabel'] = SearchPatternQuery.SearchPatterns.StartsWith;
    this.searchPatterns['packageBarCode'] = SearchPatternQuery.SearchPatterns.Exact;
    this.applySearchPatternTo.push(
      'roundLabel',
      'transportReference1',
      'transportReference2',
      'transportReference3',
      'transportPickupStepStreetName',
      'transportPickupStepPostCode',
      'transportPickupStepCityName',
      'transportPickupStepCountryCode',
      'transportDeliveryStepStreetName',
      'transportDeliveryStepPostCode',
      'transportDeliveryStepCityName',
      'transportDeliveryStepCountryCode',
      'packageCode',
      'packageBarCode',
      'packageNature',
      'packageReference1',
      'packageReference2',
      'packageReference3',
      'packageReference4',
      'packageReference5',
      'packageReference6',
      'packageReference7',
      'packageReference8',
    );
    this.setPropsFromModel(new GetRoundsModel(params));
  }

  /*
   * Extend toQs behavior to apply end dates exclusive behavior.
   * Add one day to end dates fields :
   * The displayed end date is the included end date, the api searchs with an exclusive end date.
   */
  getCloneIterator(key, value, clonedObj) {
    const endDatesKeys = ['roundReferenceDateTo', 'roundEstimatedStartDateTo', 'roundEstimatedEndDateTo'];
    if (endDatesKeys.includes(key) && clonedObj[key]) {
      const newDate = moment(clonedObj[key]);
      newDate.add(1, 'd');
      clonedObj[key] = newDate.format('YYYY-MM-DD');
    }
    super.getCloneIterator(key, value, clonedObj);
  }
}
