<template>
  <div>
    <v-toolbar color="primary" class="rounds-toolbar">
      <template v-if="isAllowedToSwitchToMapView">
        <v-btn icon @click="$emit('update:show-map', !showMap)" color="white">
          <v-icon v-if="!showMap">fas fa-globe-europe</v-icon>
          <v-icon v-else>fas fa-list</v-icon>
        </v-btn>
      </template>
      <v-icon v-else class="mr-2" color="white">fas fa-list</v-icon>
      <v-divider class="mx-1" inset vertical></v-divider>
      <template v-if="isAllowedToDownloadRoundSheet">
        <v-tooltip top class="rounds-toolbar-tooltip" nudge-left="10" nudge-top="4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!roundsSelected.length"
              icon
              @click="downloadRoundsSheets"
              color="white"
            >
              <v-icon>fas fa-download</v-icon>
            </v-btn>
          </template>
          <span>Télécharger les feuilles de tournées</span>
        </v-tooltip>
        <v-tooltip top class="round-files-toolbar-tooltip" nudge-left="10" nudge-top="4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="openOrCloseFilesDownloadDialog" color="white">
              <v-icon>fas fa-arrow-up-right-from-square</v-icon>
            </v-btn>
          </template>
          <span>Afficher le résultat du téléchargement des feuilles de tournées</span>
        </v-tooltip>
        <v-divider class="mx-1" inset vertical></v-divider>
      </template>
      <r-filter />
      <label class="label-switch-toolbar">Afficher les retards de planification</label>
      <v-switch
        :input-value="lateStatusPlannedVisible"
        @change="$emit('update:late-status-planned-visible', $event)"
        color="toggle-late-status-planned-toolbar"
        inset
        hide-details
      />
      <label class="label-switch-toolbar">Rafraichir automatiquement</label>
      <v-switch
        :input-value="autoRefreshEnabled"
        @change="$emit('update:auto-refresh-enabled', $event)"
        color="toggle-refresh-toolbar"
        inset
        hide-details
      />
    </v-toolbar>
    <files-download-dialog v-model="openFilesDownloadDialog" ref="filesDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RFilter from '@/components/filters/index.vue';
import FilesDownloadDialog from './files-dowload-dialog.vue';

export default {
  name: 'r-toolbar',
  components: {
    RFilter,
    FilesDownloadDialog,
  },
  props: {
    showMap: {
      type: Boolean,
      required: true,
    },
    lateStatusPlannedVisible: {
      type: Boolean,
      required: true,
    },
    autoRefreshEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openFilesDownloadDialog: false,
      askingGeneration: false,
    };
  },
  computed: {
    ...mapState({
      roundsSelected: (state) => state.rounds.roundsSelected,
      isAllowedToDownloadRoundSheet: (state) => state.context.rights.downloadRoundSheet,
      isAllowedToSwitchToMapView: (state) => state.context.rights.mapAccess,
    }),
  },
  methods: {
    downloadRoundsSheets() {
      this.openFilesDownloadDialog = true;
      if (this.$refs.filesDialog) {
        this.$refs.filesDialog.launchRoundsSheetsGeneration();
      }
    },
    openOrCloseFilesDownloadDialog() {
      this.openFilesDownloadDialog = !this.openFilesDownloadDialog;
    },
  },
};
</script>
<style lang="scss"></style>
