export default class GetAddressesModel {
  constructor({
    addressesIds,
    addressName,
    streetNumber,
    streetName,
    postCode,
    cityName,
    countryCode,
    externalAddressCode,
    agencyCode,
    sectorCode,
    operationZoneCode,
    onlyFavoritesOfCustomerCode,
    restrictToAgencyCode,
  } = {}) {
    this.addressesIds = addressesIds || [];
    this.addressName = addressName || null;
    this.streetNumber = streetNumber || null;
    this.streetName = streetName || null;
    this.postCode = postCode || null;
    this.cityName = cityName || null;
    this.countryCode = countryCode || null;
    this.externalAddressCode = externalAddressCode || null;
    this.agencyCode = agencyCode || null;
    this.sectorCode = sectorCode || null;
    this.operationZoneCode = operationZoneCode || null;
    this.onlyFavoritesOfCustomerCode = onlyFavoritesOfCustomerCode || null;
    this.restrictToAgencyCode = restrictToAgencyCode || null;
  }
}

class GetAddressModel {
  constructor({ addressId } = {}) {
    this.addressId = addressId || null;
  }
}

export { GetAddressesModel, GetAddressModel };
