import { mapState } from 'vuex';
import { status, lateStatus } from '@/business';

export default {
  computed: {
    ...mapState({
      isLateStatusBasedOnEstimatedSchedule: (state) => state.context.settings.isLateStatusBasedOnEstimatedSchedule,
    }),

    STATUS() {
      return status;
    },

    LATE_STATUS() {
      return lateStatus;
    },
  },
};
