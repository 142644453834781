/* eslint-disable no-undef */
import axios from 'axios';

L.TileLayer.EurekaMapsWms = L.TileLayer.WMS.extend({
  createTile(coords, done) {
    const url = this.getTileUrl(coords);
    const tile = document.createElement('img');
    tile.alt = '';
    tile.setAttribute('role', 'presentation');
    let headers = {};
    if (this.options.token) {
      headers.Authorization = `Bearer ${this.options.token}`;
    }
    axios
      .get(url, {
        headers: headers,
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        tile.src = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        done(null, tile);
      })
      .catch(function (error) {
        // Tile loading errors are not sent to Azure AppInsights
        // No need to use the GlobalLogger here
        console.error(error);
      });
    return tile;
  },
});

L.tileLayer.eurekaMapsWms = function (url, options) {
  return new L.TileLayer.EurekaMapsWms(url, options);
};
