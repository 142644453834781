import GetOrderersModel from '../models/orderers';
import { SearchPatternQuery } from './query';

export default class GetOrderersQuery extends SearchPatternQuery {
  constructor(params) {
    if (!params.fields) params.fields = ['code', 'name'];
    super(params);
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.StartsWith;
    this.applySearchPatternTo.push('code', 'name');
    this.setPropsFromModel(new GetOrderersModel(params));
  }
}
