import Vue from 'vue';
import moment from 'moment';
import { defaultColors } from '@/business/colors';
import { status, lateStatus } from '@/business';

const state = () => ({
  // Keep in which order user selected the round and the associated color
  roundsSelected: [
    // { id, color, lastUpdate: moment }
  ],

  roundsDriverSelected: [
    // { id, color, lastUpdate: moment }
  ],

  // Keep track on selected round to adjust view when opening menu detail
  roundIdFocused: 0,

  // Keep track on step selected to zoom on map point or scroll in detail list
  stepSelected: null,

  // Keep tracks in cache
  tracks: {
    // id: RoutePoints[]
  },

  driverTracks: {
    // id: RoutePoints[]
  },
});

const getters = {
  getNewColor: (_, getters) => () => Object.values(defaultColors).find((dc) => !getters.tracksColors.includes(dc)),

  stepsDisplayed: (state, _, __, rootGetters) =>
    state.roundsSelected.reduce((prev, curr) => {
      const transportSteps = rootGetters.rounds.find((r) => r.id === curr.id)?.transportSteps || [];
      return [...prev, ...transportSteps.map((t) => ({ ...t, round: { ...curr } }))];
    }, []),
  tracksDisplayed: (state) => {
    return state.roundsSelected.reduce((prev, curr) => {
      const track = state.tracks[curr.id] || [];
      const driverTrack = state.driverTracks[curr.id] || [];
      if (track.length && driverTrack.length)
        return [...prev, track, driverTrack];
      else if (track.length)
        return [...prev, track];
      else if (driverTrack.length)
        return [...prev, driverTrack];
      return prev;
		}, [])
  },
  tracksDriverDisplayed: (state) => state.roundsDriverSelected.map((r) => state.driverTracks[r.id] || []),
  tracksColors: (state) => {
    return state.roundsSelected.reduce((prev, curr) => {
      let color = curr.color;
      const found = state.roundsDriverSelected.find((el) => el.id === curr.id);
      if (found) {
        return [...prev, color, found.color];
      } else {
        return [...prev, color];
			}
    }, [])
  },
};

const actions = {
  async calculateItinerary({ dispatch }, bounds) {
    const response = await dispatch(
      'api/calculateItinerary',
      bounds.map((c) => ({
        Coordinates: { Y: c.Latitude, X: c.Longitude },
      })),
      { root: true },
    );
    return response?.RoutePoints || [];
  },

  async updateRoundItinerary({ dispatch, commit }, round) {
    if (!round) return;

    const bounds = (round.transportSteps || [])
      .filter((ts) => ts.stepAddress.longitude && ts.stepAddress.latitude)
      .map((ts) => ({
        Longitude: ts.stepAddress.longitude,
        Latitude: ts.stepAddress.latitude,
      }));
    const tracks = await dispatch('calculateItinerary', bounds);
    commit('UPDATE_TRACKS', { id: round.id, tracks });
    return tracks;
  },

  async selectRound({ dispatch, commit, state, getters, rootGetters }, id) {
    const round = (rootGetters.rounds || []).find((r) => r.id === id);
    if (!round) throw new Error('Round id not found ' + id);
    if (state.roundsSelected.find((r) => r.id === id)) return state.tracks[id];

    commit('ADD_SELECTED_ROUND', { id, color: getters.getNewColor() });
    return state.tracks[id] || (await dispatch('updateRoundItinerary', round));
  },

  deselectRound({ commit, state, dispatch }, id) {
    commit('REMOVE_SELECTED_ROUND', id);
    commit('REMOVE_SELECTED_DRIVER_ROUND', id);
    // Remove tracks data if selected more than X minutes ago ?
    if (state.roundIdFocused === id) dispatch('resetFocus');
  },

  async focusOnRound({ dispatch, commit }, id) {
    commit('UPDATE_FOCUSED_ROUND', id);
    return await dispatch('selectRound', id);
  },

  selectStep({ dispatch, commit }, { id, step }) {
    commit('UPDATE_SELECTED_STEP', { roundId: id, ...step });
    dispatch('focusOnRound', id);
  },

  resetRoundFocus({ commit }) {
    commit('UPDATE_FOCUSED_ROUND', 0);
  },

  resetSelectedStep({ commit }) {
    commit('UPDATE_SELECTED_STEP', null);
  },

  resetFocus({ dispatch }) {
    dispatch('resetRoundFocus');
    dispatch('resetSelectedStep');
  },

  async getDriverSmoothRoute({ dispatch, commit, state, rootGetters }, id) {
    const round = (rootGetters.rounds || []).find((r) => r.id === id);
    if (!round) throw new Error('Round id not found ' + id);

    let firstStepRealized = null;
    let lastStepRealized = null;
    let startDate = null;
    let endDate = null;
    if (round.status === status.InProgress) {
      firstStepRealized = round.transportSteps.find((step, index) => step.stepLateStatus !== lateStatus.Undetermined && index > 0);
      startDate = firstStepRealized.stepDate.effectiveDate;
      endDate = moment().format('yyyy-MM-DDTHH:mm:sszzz');
    }

    if (round.status === status.Over) {
      firstStepRealized = round.transportSteps[0];
      lastStepRealized = round.transportSteps[round.transportSteps.length - 1];
      startDate = firstStepRealized.stepDate.effectiveDate;
      endDate = lastStepRealized.stepDate.effectiveDate;
		}

    if (!startDate || !endDate) {
      throw new Error('Round date is invalid ' + id);
		}
    const response = await dispatch('api/getDriverSmoothRoute', { id: id, startDate: startDate, endDate: endDate }, { root: true });
    const bounds = (response.positions.data || [])
      .filter((ts) => ts.longitude && ts.latitude)
      .map((ts) => ({
        Longitude: ts.longitude,
        Latitude: ts.latitude,
      }));

    const tracks = await dispatch('calculateItinerary', bounds);
    if (state.roundsDriverSelected.find((r) => r.id === id)) {
      commit('UPDATE_TRACKS_DRIVER', { id: id, tracks });
      return tracks;
    }
    commit('UPDATE_FOCUSED_ROUND', id);
    commit('ADD_SELECTED_DRIVER_ROUND', { id: id, color: '#000000' });
    commit('UPDATE_TRACKS_DRIVER', { id: id, tracks });
    return tracks;
  },

  deselectSelectedDriverRound({ commit }, id) {
    commit('REMOVE_SELECTED_DRIVER_ROUND', id);
  },
};

const mutations = {
  UPDATE_TRACKS(state, { id, tracks }) {
    Vue.set(state.tracks, id, tracks);
    state.roundsSelected.find((r) => r.id === id).lastUpdate = moment();
  },

  UPDATE_TRACKS_DRIVER(state, { id, tracks }) {
    Vue.set(state.driverTracks, id, tracks);
    state.roundsDriverSelected.find((r) => r.id === id).lastUpdate = moment();
  },

  ADD_SELECTED_ROUND(state, { id, color }) {
    state.roundsSelected.push({ id, color, lastUpdate: null });
  },
  ADD_SELECTED_DRIVER_ROUND(state, { id, color }) {
    state.roundsDriverSelected.push({ id, color, lastUpdate: null });
  },
  REMOVE_SELECTED_ROUND(state, id) {
    const index = state.roundsSelected.findIndex((r) => r.id === id);
    state.roundsSelected.splice(index, 1);
  },
  REMOVE_SELECTED_DRIVER_ROUND(state, id) {
    const index = state.roundsDriverSelected.findIndex((r) => r.id === id);
    if (index > -1) {
      state.roundsDriverSelected.splice(index, 1);
      Vue.set(state.driverTracks, id, []);
    }
  },

  UPDATE_FOCUSED_ROUND(state, id) {
    state.roundIdFocused = id;
  },

  UPDATE_SELECTED_STEP(state, step) {
    if (!step) state.stepSelected = null;
    else state.stepSelected = { ...step };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
