import { GetAddressesModel, GetAddressModel } from '../models/addresses';
import { SearchPatternQuery, ProjectableQueryBase } from './query';

export default class GetAddressesQuery extends SearchPatternQuery {
  constructor(params) {
    if (!params.fields) {
      params.fields = ['addressId', 'name', 'streetName', 'city(postCode,name,country(code,label))'];
    }
    super(params);
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.Exact;
    this.searchPatterns['addressName'] = SearchPatternQuery.SearchPatterns.Contains;
    this.applySearchPatternTo.push(
      'addressName',
      'streetNumber',
      'streetName',
      'postCode',
      'cityName',
      'countryCode',
      'externalAddressCode',
      'agencyCode',
      'sectorCode',
      'operationZoneCode',
    );
    this.setPropsFromModel(new GetAddressesModel(params));
  }
}

class GetAddressQuery extends ProjectableQueryBase {
  constructor(params) {
    if (!params.fields) {
      params.fields = ['addressId', 'name', 'streetName', 'city(postCode,name,country(code,label))'];
    }
    super(params);
    this.setPropsFromModel(new GetAddressModel(params));
  }
}

export { GetAddressesQuery, GetAddressQuery };
