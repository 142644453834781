import GetCitiesModel from '../models/cities';
import { SearchPatternQuery } from './query';

export default class GetCitiesQuery extends SearchPatternQuery {
  constructor(params) {
    if (params.searchOnBoth) {
      params.pattern = params.postCode || params.cityName;
      params.patternFields = ['postCode', 'cityName'];
      params.postCode = null;
      params.cityName = null;
    }
    if (params.fields) params.fields = ['postCode', 'name'];
    super(params);
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.StartsWith;
    this.applySearchPatternTo.push('postCode', 'cityName');
    this.setPropsFromModel(new GetCitiesModel(params));
  }
}
