function checkReferenceValueWithRestrictions({ value, date, maxDays, minChars }) {
  // if no value then it is valid
  if (!value || !value.length) return { success: true, error: '' };
  let index = value.indexOf('%');
  // if no % or % at the value string's end then no need to check
  if (index < 0 || index === value.length - 1) return { success: true, error: '' };
  //if it goes here there is at least one % into the value string
  let result = {
    success: false,
    error: {
      maxDaysReached: false,
      minCharsReached: false,
    },
  };
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  // filter.FromDate can be either a moment object or string /Date(milliseconds)/
  let fromDate = date;
  if (fromDate) {
    if (typeof fromDate === 'string') {
      fromDate = fromDate.match(/Date\(([0-9]+)[+-][0-9]+\)/)[1];
    } else {
      fromDate = fromDate.valueOf();
    }
    // convert dates into milliseconds and divide the result by number of milliseconds in a day
    let daysBetween = Math.round(Math.abs(today.getTime() - fromDate) / 8.64e7);
    // compare the days between the two dates to the maximum days restriction
    if (daysBetween > maxDays) {
      result.error.maxDaysReached = true;
      return result;
    }
  }
  // replace all % in value string and compare length to min character restriction
  let cleanValue = value.replace(/%/g, '');
  if (!cleanValue || cleanValue.length < minChars) {
    result.error.minCharsReached = true;
    return result;
  }
  result.success = true;
  return result;
}

export default { checkReferenceValueWithRestrictions };
