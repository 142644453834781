import moment from 'moment';
import GenerateRoundDocumentReportRequest from './api/models/reports';

const GenerationStatus = {
  Unknown: 'Unknown',
  Pending: 'Pending',
  Completed: 'Completed',
  Error: 'Error',
};

const state = {
  // Number of files that can be generated in parallel
  fileGenerationMaxDegreeOfParallelism: 2,
  filesToProcessTimer: null,
  filesToProcess: [
    // { roundId, ordererCode, customerCodes }
  ],

  pollingIntervalSec: 5,
  filesProcessingTimer: null,
  filesProcessing: [
    // { uid, roundId, status, error, lastUpdate, generationStartDate, }
  ],

  filesOnError: [
    // { uid, roundId, status, error, lastUpdate, generationStartDate, }
  ],

  // Contains all the generated file and a cache of data result if retrieved once
  filesGenerated: [
    // { uid, roundId, data, type, lastUpdate }
  ],

  // Contains all files generated uid for which we're currently loading the result data
  loadingFileData: [],
};

const getters = {
  isFileGenerated: (state) => (uid) => state.filesGenerated.find((f) => f.uid === uid),
  isFileGenerationPending: () => (f) =>
    !f.status || f.status === GenerationStatus.Unknown || f.status === GenerationStatus.Pending,
  isRoundFileGenerationAlreadyInProgress: (state) => (roundId) =>
    !![...state.filesToProcess, ...state.filesProcessing].find(
      (f) => f.roundId === roundId,
    ),
  pollingIntervalMs: (state) => state.pollingIntervalSec * 1000,

  getFilesProcessingWithUids: (state) => (fileUids) =>
  state.filesProcessing.filter(file => fileUids.includes(file.uid)),
};

const actions = {
  askRoundsSheetsGeneration({ commit, getters, dispatch }, { roundsIds, ordererCode = null, customerCodes = [] }) {
    const requests = roundsIds
      .filter((f) => !getters.isRoundFileGenerationAlreadyInProgress(f))
      .map((roundId) => new GenerateRoundDocumentReportRequest({ roundId, ordererCode, customerCodes }));
    commit('ADD_FILES_TO_PROCESS', requests);
    return dispatch('processFiles', roundsIds);
  },

  async downloadRoundSheetFile({ state, commit, dispatch }, uid) {
    const result = state.filesGenerated.find((f) => f.uid === uid);
    if (!result) return null;

    try {
      commit('LOADING_FILE_DATA', uid);
      const { data, type } = await dispatch('api/getRoundSheetFile', uid, { root: true });
      return new Blob([data], { type: type });
    } finally {
      commit('FILE_DATA_LOADED', { uid });
    }
  },

  async createRoundSheet({ state, commit, dispatch }, request = new GenerateRoundDocumentReportRequest()) {
    const existing = state.filesProcessing.find((f) => f.roundId === request.roundId);
    if (existing) {
      console.warn(`Generation is already in progress for round ${request.roundId} : ${existing.uid}`);
      return;
    }

    const response = await dispatch('api/generateRoundSheet', request, { root: true });
    commit('PROCESSING_FILE', { uid: response.roundDocumentReportUid, roundId: request.roundId });
    return response.roundDocumentReportUid;
  },

  async updateRoundSheetStatus({ state, commit, dispatch }, uid) {
    const existing = state.filesProcessing.find((f) => f.uid === uid);
    if (!existing) {
      console.warn(`Generation has not been initialized for ${uid}`);
      return;
    }

    const response = await dispatch('api/getRoundSheetGenerationStatus', uid, { root: true });
    if (response.status === GenerationStatus.Completed) {
      commit('FILE_GENERATED', { uid, roundId: existing.roundId });
      try {
        await dispatch('downloadGeneratedFile', {roundId: existing.roundId, fileUid: uid});
      } catch (err) {
        console.log('Impossible de télécharger la feuille de tournée.', err);
      }
    } else {
      commit('UPDATE_PROCESSING_FILE', { uid, ...response });
    }
  },

  async downloadGeneratedFile({ dispatch }, {roundId, fileUid}) {
      const fileName = `Tournée ${roundId}`;
      const blob = await dispatch('downloadRoundSheetFile', fileUid);
      if (blob) {
        //For IE and Edge
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          link.click();
          URL.revokeObjectURL(url);
        }
      }
  },

  async processFiles({ state, dispatch, getters }, roundsIds) {
    // Split rounds in chunks that will be processed synchronously
    // Used to avoid generating and polling too many reports in parallel, because it can lead to http error code 429: too many requests
    const chunkSize = state.fileGenerationMaxDegreeOfParallelism;
    const roundIdsToProcessChunks = [];
    for (let i = 0; i < roundsIds.length; i += chunkSize) {
      const chunk = roundsIds.slice(i, i + chunkSize);
      roundIdsToProcessChunks.push(chunk);
    }

    for (let i = 0; i < roundIdsToProcessChunks.length; i++) {
      const chunk = roundIdsToProcessChunks[i];
      let filesToProcessChunk = state.filesToProcess.filter(file => chunk.includes(file.roundId));
      let fileUids = [];
      if (filesToProcessChunk.length) {
        // creating all requests for this chunk synchronously (no need for parallelism for this part)
        for (let j = 0; j < filesToProcessChunk.length; j++) {
          const file = filesToProcessChunk[j];
          const fileUid = await dispatch('createRoundSheet', new GenerateRoundDocumentReportRequest(file));
          fileUids.push(fileUid);
        }
        let filesToUpdateChunk = getters.getFilesProcessingWithUids(fileUids);
        
        if (filesToUpdateChunk.length) {
          // wait 1 second before start polling (generally, a report is generated in less than 1 second)
          await new Promise(resolve => { setTimeout(resolve, 1000); });
          // polling all requests for this chunk in parallel until they are processed
          const pollStatusFilesLoop = async () => {
            await Promise.all(getters.getFilesProcessingWithUids(fileUids).map((f) => dispatch('updateRoundSheetStatus', f.uid)));
            if (getters.getFilesProcessingWithUids(fileUids).length) {
              await new Promise((resolve) =>
                setTimeout(async () => resolve(await pollStatusFilesLoop()), getters.pollingIntervalMs),
              );
            }
          };
          await pollStatusFilesLoop();
        }
      }
    }
  },

  removeFileToProcess({commit}, roundId) {
    commit('REMOVE_FILE_TO_PROCESS', roundId);
  },

  stopProcessingFile({commit}, uid) {
    commit('STOP_PROCESSING_FILE', uid);
  },

  clearProcessedFiles({commit}) {
    commit('CLEAR_PROCESSED_FILES');
  }
};

const mutations = {
  ADD_FILES_TO_PROCESS(
    state,
    files = [
      /*{ roundId, ordererCode, customerCodes }*/
    ],
  ) {
    state.filesToProcess.push(...files);
  },

  PROCESSING_FILE(state, { uid, roundId }) {
    state.filesProcessing.push({
      uid,
      roundId,
      status: GenerationStatus.Unknown,
      error: null,
      lastUpdate: null,
      generationStartDate: null,
    });
    const index = state.filesToProcess.findIndex((f) => f.roundId === roundId);
    if (index > -1) state.filesToProcess.splice(index, 1);
  },

  UPDATE_PROCESSING_FILE(
    state,
    { uid, status = GenerationStatus.Unknown, errorDescription = null, reportGenerationStartDate = null },
  ) {
    const file = state.filesProcessing.find((f) => f.uid === uid);
    file.status = status;
    file.generationStartDate = reportGenerationStartDate;
    file.error = errorDescription;
    file.lastUpdate = moment();

    if (status === GenerationStatus.Error) {
      state.filesOnError.push({ ...file });
      state.filesProcessing.splice(state.filesProcessing.indexOf(file), 1);
    }
  },

  FILE_GENERATED(state, { uid, roundId }) {
    state.filesGenerated.push({ uid, roundId, data: null, lastUpdate: moment() });
    const index = state.filesProcessing.findIndex((f) => f.uid === uid);
    if (index > -1) state.filesProcessing.splice(index, 1);
  },

  LOADING_FILE_DATA(state, uid) {
    state.loadingFileData.push(uid);
  },

  FILE_DATA_LOADED(state, { uid }) {
    state.loadingFileData.splice(
      state.loadingFileData.findIndex((f) => f.uid === uid),
      1,
    );
  },

  REMOVE_FILE_TO_PROCESS(state, roundId) {
    const index = state.filesToProcess.findIndex((f) => f.roundId === roundId);
    if (index > -1) state.filesToProcess.splice(index, 1);
  },

  STOP_PROCESSING_FILE(state, uid) {
    const index = state.filesProcessing.findIndex((f) => f.uid === uid);
    if (index > -1) state.filesProcessing.splice(index, 1);
  },

  CLEAR_PROCESSED_FILES(state) {
    state.filesOnError.splice(0, state.filesOnError.length);
    state.filesGenerated.splice(0, state.filesGenerated.length);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
