export default class GetCitiesModel {
  constructor({ postCode, cityName, agencyCode, countryCode, countryFamilyCode, missionEntryAvailability } = {}) {
    this.postCode = postCode || null;
    this.cityName = cityName || null;
    this.agencyCode = agencyCode || null;
    this.countryCode = countryCode || null;
    this.countryFamilyCode = countryFamilyCode || null;
    this.missionEntryAvailability = missionEntryAvailability || null;
  }
  static get EntityAvailability() {
    return {
      Yes: 'Yes',
      No: 'No',
      Any: 'Any',
    };
  }
}
