var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 rounds-grid",attrs:{"options":_vm.dataPagination,"footer-props":_vm.footerProps,"headers":_vm.tableHeaders,"items":_vm.rounds,"loading":!_vm.loaded,"item-key":"id","server-items-length":_vm.serverItemsCount,"show-select":_vm.isAllowedToDownloadRoundSheet,"fixed-header":""},on:{"update:options":_vm.setDataPagination},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":props.value,"indeterminate":props.indeterminate,"color":"primary","label":("(" + (_vm.roundsSelected.length) + ")"),"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll.apply(null, arguments)}}})]}},{key:"header.transportSteps",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","align-start":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-icon',{staticClass:"rounds-grid-timeline-header-planned",attrs:{"small":"","color":"white"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"ml-1"},[_vm._v("Planifiée")])],1),_c('v-flex',{attrs:{"shrink":"","pl-1":""}},[_c('v-icon',{staticClass:"rounds-grid-timeline-header-realized",attrs:{"small":"","color":"success"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"ml-1"},[_vm._v("Réalisée")])],1),(_vm.isLateStatusBasedOnEstimatedSchedule)?_c('v-flex',{attrs:{"shrink":"","pl-1":""}},[_c('v-icon',{staticClass:"rounds-grid-timeline-header-lateplanning",attrs:{"small":"","color":"warning"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"ml-1"},[_vm._v("Retard planification")])],1):_vm._e(),_c('v-flex',{attrs:{"shrink":"","pl-1":""}},[_c('v-icon',{staticClass:"rounds-grid-timeline-header-late",attrs:{"small":"","color":"error"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"ml-1"},[_vm._v("En retard")])],1)],1)]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.loaded ? 'white' : 'primary',"indeterminate":"","height":"1"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var isExpanded = ref.isExpanded;
var select = ref.select;
var expand = ref.expand;
return [_c('tr',{staticClass:"rounds-grid-row",attrs:{"active":isSelected}},[(_vm.isAllowedToDownloadRoundSheet)?_c('td',{on:{"click":function($event){return select(!isSelected)}}},[_c('v-checkbox',{staticClass:"ml-3 mt-0",attrs:{"input-value":isSelected,"color":"primary","hide-details":""}})],1):_vm._e(),_c('td',{staticClass:"rounds-grid-row-caret",on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(!isExpanded ? 'fas fa-angle-right' : 'fas fa-angle-down')}})],1),_c('td',{staticClass:"text-center special-badge"},[_c('v-badge',{attrs:{"color":"transparent","overlap":""}},[(item.lastStepLateStatus !== _vm.LATE_STATUS.Undetermined)?_c('template',{slot:"badge"},[_c('v-icon',{attrs:{"color":_vm.getClockColorFromStatus(item.lastStepLateStatus),"small":""}},[_vm._v(" fas fa-clock ")]),(_vm.isItemHasLateStatus(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":_vm.getClockColorFromStatus(item.lateStatus),"small":""}},'v-icon',attrs,false),on),[_vm._v(" fas fa-exclamation ")])]}}],null,true)},[_c('span',[_vm._v("Une étape est en retard")])]):_vm._e()],1):_vm._e(),_c('v-chip',{staticClass:"rounds-grid-status",attrs:{"color":_vm.getChipColorFromStatus(item.status),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getStatusLabel(item.status))+" ")])],2)],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.label))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getDate(item.estimatedStartDateTime))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getTime(item.estimatedStartDateTime)))])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getDate(item.estimatedEndDateTime))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getTime(item.estimatedEndDateTime)))])]),(_vm.hasDriverData)?_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.driverLabel))]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.stepCount))]),_c('td',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"rounds-grid-progress",attrs:{"value":item.progressPercentage * 100,"color":"grey darken-2","rotate":-90,"size":"38"}},[_vm._v(" "+_vm._s(_vm.getPercentage(item.progressPercentage))+" ")])],1),_c('td',[_c('timeline',{attrs:{"transport-steps":item.transportSteps},on:{"point-click":function($event){return expand(true)}},model:{value:(_vm.targetId),callback:function ($$v) {_vm.targetId=$$v},expression:"targetId"}})],1),(_vm.isAllowedToSwitchToMapView)?_c('td',{staticClass:"rounds-grid-row-action text-center",on:{"click":function($event){return _vm.showMap(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fas fa-route")])]}}],null,true)},[_c('span',[_vm._v("Voir sur la carte")])])],1):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail',{staticClass:"rounds-grid-row-expand",attrs:{"transport-steps":item.transportSteps,"max-height":_vm.rounds.indexOf(item) < _vm.rounds.length - 1},model:{value:(_vm.targetId),callback:function ($$v) {_vm.targetId=$$v},expression:"targetId"}})],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true),model:{value:(_vm.roundsSelected),callback:function ($$v) {_vm.roundsSelected=$$v},expression:"roundsSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }