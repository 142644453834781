const getDefaultUser = () => ({
  auth: false,
  login: '',
  name: '',
  email: '',
  lang: '',
  phone: '',
  // EMPTY
  code: '',
  agencyCode: '',
});

const state = () => ({
  user: getDefaultUser(),
  settings: {
    refreshInMinutes: 5,
    inactivityDelayInMinutes: 55,
    showThemeButton: false,
    themeFileUrl: '', // optional link to a folder containing a theme.json file with the same structure as defaultColors in vuetify.js
    maxDaysSearchLimit: 90,
    minCharsSearchWithPercent: 2,
    appToken: null,
    isLateStatusBasedOnEstimatedSchedule: true,
  },
  rights: {
    mapAccess: false,
    downloadRoundSheet: true,
    displayRealizedAndEstimatedHours: true,
  },
  localStorageAvailable: true,
});

const getters = {
  userCode: (state) => state.user.code,
  agencyCode: (state) => state.user.agencyCode,
  lang: (state) => state.user.lang || 'fr-FR',
  hasMapAccess: (state) => !!state.rights.mapAccess,
  apiToken: (state) => state.settings.appToken,
};

const actions = {
  disconnect({ commit }) {
    commit('DISCONNECT');
  },

  async initContext({ dispatch, commit, state }) {
    const token = await dispatch('getToken');
    if (!token) {
      commit('DISCONNECT');
      return;
    }
    commit('SET_TOKEN', token);
    window.history.pushState({}, document.title, '/');

    if (state.localStorageAvailable) {
      const isVisible = localStorage.getItem('isLateStatusBasedOnEstimatedSchedule');
      if (isVisible === null) {
        dispatch('toggleLateStatusPlannedVisibility', state.settings.isLateStatusBasedOnEstimatedSchedule);
      } else {
        const value = isVisible === 'true' ? true : false;
        dispatch('toggleLateStatusPlannedVisibility', value);
      }
    }

    const userInfo = await dispatch('api/getAccount');
    if (!userInfo) {
      commit('DISCONNECT');
    } else {
      commit('SET_USER', userInfo);
    }
    await dispatch('filters/getCountries');
  },

  getToken({ commit }) {
    let token = null;

    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      token = urlParams.get('roundAppToken');
    }
    try {
      if (token) {
        localStorage.setItem('appToken', token);
        return token;
      }
      return localStorage.getItem('appToken');
    } catch {
      // LocalStorage is not accessible through an iframe
      commit('LOCAL_STORE_UNAVAILABLE');
      return token;
    }
  },

  toggleLateStatusPlannedVisibility({ commit, state }, value) {
    commit('SET_LATE_STATUS_BASE_ON_ESTIMATED_SCHEDULE', value);
    if (state.localStorageAvailable) {
      try {
        localStorage.setItem('isLateStatusBasedOnEstimatedSchedule', value);
      } catch {
        // LocalStorage is not accessible through an iframe
        commit('LOCAL_STORE_UNAVAILABLE');
      }
    }
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.settings.appToken = token;
  },
  DISCONNECT(state) {
    state.user = getDefaultUser();
    state.rights.mapAccess = false;
    state.settings.appToken = null;
  },
  SET_USER(state, userInfo) {
    state.user.auth = true;
    state.user.login = userInfo.login;
    state.user.name = userInfo.name;
    state.user.phone = userInfo.phone;
    state.user.lang = userInfo.locale;
    state.user.email = userInfo.email;
    state.rights.mapAccess = userInfo.hasAccessToEurekaMaps;
  },
  LOCAL_STORE_UNAVAILABLE(state) {
    state.localStorageAvailable = false;
  },
  SET_LATE_STATUS_BASE_ON_ESTIMATED_SCHEDULE(state, value) {
    state.settings.isLateStatusBasedOnEstimatedSchedule = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
