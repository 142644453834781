<template>
  <v-layout align-center fill-height>
    <v-flex shrink v-if="hasFilters && showScroller">
      <v-btn text icon small @click="scroll(false)">
        <v-icon>fas fa-chevron-circle-left</v-icon>
      </v-btn>
    </v-flex>
    <v-flex
      shrink
      class="oderer-rounds-filter-results"
      ref="scroll"
      :ml-3="!showScroller && showSpace"
      v-if="filtersResult"
    >
      <v-layout align-center fill-height ref="results">
        <template v-for="key in filterKeys">
          <v-flex :key="key" v-if="filters[key].type === 'number' || filters[key].type === 'string'" shrink>
            <v-chip close @click:close="resetFilter(key)">
              <b>{{ filters[key].label }}&nbsp;:&nbsp;</b>{{ filtersResult[key] }}
            </v-chip>
          </v-flex>
          <!-- No boolean filter used for now -->
          <v-flex :key="key" v-else-if="filters[key].type === 'boolean'" shrink>
            <v-chip close @click:close="resetFilter(key)">
              <b>{{ filters[key].label }}&nbsp;:&nbsp;</b
              >{{ getBooleanValues(filtersResult[key], filters[key].trueLabel, filters[key].falseLabel) }}
            </v-chip>
          </v-flex>
          <!-- -->
          <v-flex :key="key" v-else-if="filters[key].type === 'array'" shrink>
            <v-chip close @click:close="resetFilter(key)">
              <b>{{ filters[key].label }}&nbsp;:&nbsp;</b
              >{{ getArrayValues(filtersResult[key], filters[key].labelValues, filters[key].maxLength) }}
            </v-chip>
          </v-flex>
          <v-flex :key="key" v-else-if="filters[key].type === 'date'" shrink>
            <v-chip
              close
              @click:close="
                resetFilter(key + 'From');
                resetFilter(key + 'To');
              "
            >
              <b>{{ filters[key].label }}&nbsp;:&nbsp;</b>
              {{ getDate(filtersResult[key + 'From']) }}
              <span v-if="getDate(filtersResult[key + 'To'])"> - {{ getDate(filtersResult[key + 'To']) }}</span>
            </v-chip>
          </v-flex>
          <template v-else-if="filters[key].type === 'address'">
            <v-flex :key="key + 'StreetName'" v-if="filtersResult[key + 'StreetName']" shrink>
              <v-chip close @click:close="resetFilter(key + 'StreetName')">
                <b>{{ filters[key].label }} - Rue&nbsp;:&nbsp;</b>{{ filtersResult[key + 'StreetName'] }}
              </v-chip>
            </v-flex>
            <v-flex :key="key + 'PostCode'" v-if="filtersResult[key + 'PostCode']" shrink>
              <v-chip close @click:close="resetFilter(key + 'PostCode')">
                <b>{{ filters[key].label }} - Code postal&nbsp;:&nbsp;</b>{{ filtersResult[key + 'PostCode'] }}
              </v-chip>
            </v-flex>
            <v-flex :key="key + 'CityName'" v-if="filtersResult[key + 'CityName']" shrink>
              <v-chip close @click:close="resetFilter(key + 'CityName')">
                <b>{{ filters[key].label }} - Ville&nbsp;:&nbsp;</b>{{ filtersResult[key + 'CityName'] }}
              </v-chip>
            </v-flex>
            <v-flex :key="key + 'CountryCode'" v-if="filtersResult[key + 'CountryCode']" shrink>
              <v-chip close @click:close="resetFilter(key + 'CountryCode')">
                <b>{{ filters[key].label }} - Pays&nbsp;:&nbsp;</b
                >{{ getCountryCodeLabel(filtersResult[key + 'CountryCode']) }}
              </v-chip>
            </v-flex>
            <v-flex :key="key + 'AddressId'" v-if="filtersResult[key + 'AddressId']" shrink>
              <v-chip close @click:close="resetFilter(key + 'AddressId')">
                <b>{{ filters[key].label }} - Id adresse&nbsp;:&nbsp;</b>
                {{ filtersResult[key + 'AddressId'] }}
              </v-chip>
            </v-flex>
          </template>
        </template>
      </v-layout>
    </v-flex>
    <v-flex shrink v-if="hasFilters && showScroller">
      <v-btn text icon small @click="scroll(true)">
        <v-icon>fas fa-chevron-circle-right</v-icon>
      </v-btn>
    </v-flex>
    <v-flex grow>
      <v-btn text @click="resetAll" v-if="hasFilters">Supprimer</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import StatusMixin from '@/mixins/status';
import MomentMixin from '@/mixins/moment';

export default {
  name: 'filter-results',
  mixins: [StatusMixin, MomentMixin],
  props: {
    filtersResult: {
      type: Object,
      default: null,
    },
    showSpace: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filters: {
        roundLateStatuses: {
          label: 'Etat',
          type: 'array',
          labelValues: {},
          maxLength: 3,
        },
        roundStatuses: {
          label: 'Statut',
          type: 'array',
          labelValues: {},
          maxLength: 3,
        },
        roundLabel: {
          label: 'Tournée - Libellé',
          type: 'string',
        },
        roundId: {
          label: 'Numéro de tournée',
          type: 'number',
        },
        roundReferenceDate: {
          label: 'Date de référence',
          type: 'date',
        },
        roundEstimatedStartDate: {
          label: 'Date estimée de début',
          type: 'date',
        },
        roundEstimatedEndDate: {
          label: 'Date estimée de fin',
          type: 'date',
        },
        transportMissionNumber: {
          label: 'Numéro de mission',
          type: 'number',
        },
        transportOrdererCodes: {
          label: "Donneur d'ordre",
          type: 'array',
        },
        transportCustomerCodes: {
          label: 'Clients',
          type: 'array',
        },
        transportReference1: {
          label: 'Transport - Référence 1',
          type: 'string',
        },
        transportReference2: {
          label: 'Transport - Référence 2',
          type: 'string',
        },
        transportReference3: {
          label: 'Transport - Référence 3',
          type: 'string',
        },
        transportPickupStep: {
          label: 'Enlèvement',
          type: 'address',
        },
        transportDeliveryStep: {
          label: 'Livraison',
          type: 'address',
        },
        packageCode: {
          label: 'Colisage - Code',
          type: 'string',
        },
        packageBarCode: {
          label: 'Colisage - Code à barres',
          type: 'string',
        },
        packageNature: {
          label: 'Colisage - Nature',
          type: 'string',
        },
        packageReference1: {
          label: 'Colisage - Référence 1',
          type: 'string',
        },
        packageReference2: {
          label: 'Colisage - Référence 2',
          type: 'string',
        },
        packageReference3: {
          label: 'Colisage - Référence 3',
          type: 'string',
        },
        packageReference4: {
          label: 'Colisage - Référence 4',
          type: 'string',
        },
        packageReference5: {
          label: 'Colisage - Référence 5',
          type: 'string',
        },
        packageReference6: {
          label: 'Colisage - Référence 6',
          type: 'string',
        },
        packageReference7: {
          label: 'Colisage - Référence 7',
          type: 'string',
        },
        packageReference8: {
          label: 'Colisage - Référence 8',
          type: 'string',
        },
      },
      showScroller: false,
      index: 0,
    };
  },
  mounted() {
    const statusLabels = {};
    Object.values(this.STATUS).map((v) => (statusLabels[v] = this.getStatusLabel(v)));
    this.filters.roundStatuses.labelValues = statusLabels;

    const lateStatusLabels = {};
    Object.values(this.LATE_STATUS)
      .map((v) => (lateStatusLabels[v] = this.getLateStatusLabel(v)))
      .filter((s) => !!s);
    this.filters.roundLateStatuses.labelValues = lateStatusLabels;
  },
  computed: {
    ...mapState('filters', ['countries']),

    filterKeys() {
      return Object.keys(this.filters).filter((k) => this.match(k));
    },
    filtersResultKeys() {
      return Object.keys(this.filtersResult);
    },
    hasFilters() {
      return !!Object.values(this.filtersResult).find((val) => val && (val > 0 || val.length > 0));
    },
  },
  methods: {
    getCountryCodeLabel(countryCode) {
      return this.countries.find((c) => c.Code === countryCode)?.label || countryCode;
    },
    resetFilter(prop) {
      this.$emit('reset:filter', prop);
    },
    resetAll() {
      this.$emit('reset:all');
    },

    // Not used for now
    getBooleanValues(value, trueLabel, falseLabel) {
      if (value === true) return trueLabel ? trueLabel : 'Oui';
      return falseLabel ? falseLabel : 'Non';
    },

    getArrayValues(list, labels, maxLength = null) {
      if (maxLength && list.length === maxLength) return 'Tout';
      if (!labels) return list.join(', ');
      return list.map((l) => labels[l]).join(', ');
    },
    match(key) {
      const keysFind = this.filtersResultKeys.filter((k) => k.indexOf(key) >= 0);
      if (!keysFind.length) return false;
      let match = false;
      for (let index = 0; index < keysFind.length; index++) {
        const keyFind = keysFind[index];
        switch (this.filters[key].type) {
          case 'number':
          case 'string':
            if (this.filtersResult[keyFind]) match = true;
            break;
          case 'array':
            if (this.filtersResult[keyFind].length) match = true;
            break;
          case 'date':
            if (this.filtersResult[keyFind]) match = true;
            break;
          case 'address':
            if (this.filtersResult[keyFind]) match = true;
            break;
          // Not used for now
          case 'boolean':
            if (this.filtersResult[keyFind] === false || this.filtersResult[keyFind] === true) match = true;
            break;
        }
      }
      return match;
    },
    scroll(next) {
      this.index += next ? 1 : -1;
      if (this.index >= this.$refs.results.children.length - 1) this.index = this.$refs.results.children.length - 1;
      if (this.index < 0) this.index = 0;
      let scrollDistance = 0;
      for (let index = 0; index < this.index; index++) {
        const children = this.$refs.results.children[index];
        scrollDistance += children.offsetWidth;
      }
      this.$refs.scroll.scroll({
        top: 0,
        left: scrollDistance,
        behavior: 'smooth',
      });
    },
  },
  watch: {
    filtersResult: {
      handler() {
        this.$nextTick(() => {
          const scrollWidth = this.$refs.scroll.offsetWidth;
          let resultsWidth = -100; // 2 buttons width
          for (let index = 0; index < this.$refs.results.children.length; index++) {
            const children = this.$refs.results.children[index];
            resultsWidth += children.offsetWidth;
          }
          this.showScroller = resultsWidth > scrollWidth;
          if (!this.showScroller) this.index = 0;
        });
      },
      deep: true,
    },
  },
};
</script>
