import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n';
import { en, fr } from 'vuetify/lib/locale';

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
  fr: {
    $vuetify: {
      ...fr,
      dataIterator: {
        noResultsText: 'Aucune donnée, vérifiez vos critères de recherche',
        loadingText: 'Chargement des données...',
      },
    },
  },
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        noResultsText: 'No data, please check your search criteria',
        loadingText: 'Loading data...',
      },
    },
  },
};

const i18n = new VueI18n({
  locale: 'fr',
  messages,
});

const defaultColors = {
  primary: '#51b8a6',
  secondary: '#2A9AF3',
  accent: '#A1C51F',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  ['badge-step-pickup']: '#FFC107',
  ['badge-step-delivery']: '#FF6600',
  ['chip-status-planned']: '#51b8a6', // primary
  ['chip-status-in-progress']: '#2196F3', // info
  ['chip-status-over']: '#4CAF50', // success
  ['late-clock-not-late']: '#4CAF50', // success,
  ['late-clock-late-planning']: '#FFC107', // warning,
  ['late-clock-late-contractual']: '#FF5252', // error
  ['toggle-refresh-toolbar']: '#245095',
  ['toggle-late-status-planned-toolbar']: '#245095',
  ['round-detail-associated']: '#EAEAEA',
  ['round-detail-active']: '#616161',
};

const theme = {
  options: { customProperties: true },
  dark: false,
  themes: {
    light: {
      ...defaultColors,
    },
    dark: {
      ...defaultColors,
    },
  },
};

const icons = {
  iconfont: 'fa',
};

export default new Vuetify({
  theme,
  icons,
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
