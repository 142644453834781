<template>
  <div style="display: none">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import propsBinder from '../utils/propsBinder';
import findRealParent from '../utils/findRealParent';
import './animated-marker';

const props = {
  draggable: {
    type: Boolean,
    custom: true,
    default: false,
  },
  visible: {
    type: Boolean,
    custom: true,
    default: true,
  },
  latLng: {
    type: [Object, Array],
    custom: true,
  },
  icon: {
    type: String,
  },
  zIndexOffset: {
    type: Number,
    custom: false,
  },
  displayedNumber: {
    type: Number,
    default: -1,
  },
  color: {
    type: String,
    default: 'blue',
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  route: {
    type: Array,
  },
  map: {
    type: Object,
    default: false,
  },
};
export default {
  name: 'LMarker',
  props: props,
  data() {
    return {
      ready: false,
      defaultDriverIcon: require('@/assets/truck.png'),
    };
  },
  mounted() {
    const options = this.options;
    options.draggable = this.draggable;

    this.instantiateMarker(options);

    L.DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  methods: {
    instantiateMarker(options) {
      var myIcon = L.divIcon({});

      options.icon = myIcon;
      options.markerAnchor = { left: -16, top: -32 };
      options.markerUrl = this.icon ? this.icon : this.defaultDriverIcon;
      options.centerCallback = this.centerOnMarker;

      this.mapObject = L.animatedMarker(this.route, options);
    },
    centerOnMarker(coordinate) {
      if (coordinate && !this.map.getBounds().contains(coordinate)) {
        this.map.setView(coordinate);
      }
    },
    // eslint-disable-next-line no-unused-vars
    setDraggable(newVal, oldVal) {
      if (this.mapObject.dragging) {
        newVal ? this.mapObject.dragging.enable() : this.mapObject.dragging.disable();
      }
    },
    setVisible(newVal, oldVal) {
      if (newVal == oldVal) return;
      if (this.mapObject) {
        if (newVal) {
          this.parentContainer.addLayer(this);
        } else {
          this.parentContainer.removeLayer(this);
        }
      }
    },
    setLatLng(newVal) {
      if (newVal == null) {
        return;
      }
      if (this.mapObject) {
        let oldLatLng = this.mapObject.getLatLng();
        let newLatLng = {
          lat: newVal[0] || newVal.lat,
          lng: newVal[1] || newVal.lng,
        };
        if (newLatLng.lat != oldLatLng.lat || newLatLng.lng != oldLatLng.lng) {
          this.mapObject.setLatLng(newLatLng);
        }
      }
    },
    routeLength() {
      if (!this.route) return 0;
      return this.route.length;
    },
  },
  watch: {
    route: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.mapObject.setLine(newVal);
        }
      },
      deep: true,
    },
  },
};
</script>
