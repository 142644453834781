const THEME_KEYS = [
  'primary',
  'secondary',
  'accent',
  'error',
  'info',
  'success',
  'warning',
  'badge-step-pickup',
  'badge-step-delivery',
  'chip-status-planned',
  'chip-status-in-progress',
  'chip-status-over',
  'late-clock-not-late',
  'late-clock-late-planning',
  'late-clock-late-contractual',
  'toggle-refresh-toolbar',
  'round-detail-associated',
  'round-detail-active',
];

const ICONS = {
  theme: 'fas fa-palette',
  submit: 'fas fa-check-circle',
  clear: 'fas fa-times',
  copy: 'fas fa-copy',
};

export default { THEME_KEYS, ICONS };
