const defaultColors = {
  blue: '#38a9dc',
  red: '#d43e2a',
  orange: '#f59630',
  green: '#71af26',
  purple: '#d051b8',
  pink: '#ff91e8',
  darkred: '#a13336',
  darkblue: '#0065a0',
  darkgreen: '#718123',
  darkpurple: '#5a386a',
  lightred: '#ff8d7e',
  lightblue: '#89dbff',
  lightgreen: '#bbf970',
  cadetblue: '#426877',
};

export { defaultColors };
