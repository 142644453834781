import moment from 'moment';

export default {
  methods: {
    getDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : '';
    },
    getTime(date) {
      const time = date ? moment(date).format('HH:mm') : '';
      return time.indexOf('23:59') >= 0 ? '' : time;
    },

    /**
     * Return formatted date and hours if date.plannedStart and date.plannedEnd are both specified. Otherwise,  return date using format 'DD/MM/YYYY'
     */
    getPlannedDate(date) {
      const timeS = date.plannedStart ? moment(date.plannedStart.dateTime).format('HH:mm') : '';
      const timeStart = timeS.indexOf('23:59') >= 0 ? '' : timeS;
      const timeE = date.plannedEnd ? moment(date.plannedEnd.dateTime).format('HH:mm') : '';
      const timeEnd = timeE.indexOf('23:59') >= 0 ? '' : timeE;
      const timeStartCondition = timeStart ? ' à partir de ' : '';
      const timeEndCondition = timeEnd ? ' avant ' : '';
      const dateStart = date.plannedStart ? moment(date.plannedStart.dateTime).format('DD/MM/YYYY') : null;
      const dateEnd = date.plannedEnd ? moment(date.plannedEnd.dateTime).format('DD/MM/YYYY') : null;
      if (dateStart && dateEnd) {
        if (dateStart !== dateEnd) {
          return dateStart +
            timeStartCondition +
            timeStart +
            timeEndCondition +
            dateEnd +
            ' ' +
            timeEnd;
				}
      }
      else if (!dateStart && dateEnd) {
        return dateEnd;
      }
      return dateStart;
    },

    /**
     * Return empty string if date part (without time of day) of plannedStart and plannedEnd are different.
     * Otherwise, return time of day (formatted with hours and minutes) with date's condition.
     */
    getPlannedTime(date) {
      const timeS = date.plannedStart ? moment(date.plannedStart.dateTime).format('HH:mm') : '';
      const timeStart = timeS.indexOf('23:59') >= 0 ? '' : timeS;
      const timeE = date.plannedEnd ? moment(date.plannedEnd.dateTime).format('HH:mm') : '';
      const timeEnd = timeE.indexOf('23:59') >= 0 ? '' : timeE;
      const timeStartCondition = timeStart ? ' à partir de ' : '';
      const timeEndCondition = timeEnd ? ' avant ' : '';
      const dateStart = date.plannedStart ? moment(date.plannedStart.dateTime).format('DD/MM/YYYY') : null;
      const dateEnd = date.plannedEnd ? moment(date.plannedEnd.dateTime).format('DD/MM/YYYY') : null;
      if (dateStart && dateEnd) {
        if (dateStart == dateEnd) {
          if (timeStart == timeEnd) {
            return timeStart;
          } else {
            return timeStartCondition +
              timeStart +
              timeEndCondition +
              timeEnd;
					}
        }
      }
      else if (dateStart && !dateEnd) {
        return timeStartCondition +
          timeStart;
      }
      else if (!dateStart && dateEnd) {
        if (!date.plannedEnd.isTimeOfDayIgnored) {
          return timeEndCondition +
            timeEnd;
        }
      }
      return '';
    },
  },
};
