const digitsAfterComma = 0;
const noCommaValues = [0, 100];

export default {
  methods: {
    getPercentage(percent) {
      let nb = parseFloat(percent * 100);
      nb = isNaN(nb) ? 0 : nb;
      return (noCommaValues.includes(nb) ? nb.toFixed(0) : nb.toFixed(digitsAfterComma)) + '%';
    },
  },
};
