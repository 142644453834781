export default class GetCustomersModel {
  constructor({ code, label, postCode, cityName, countryCode, familyCode, agencyCode, customerUids } = {}) {
    this.code = code || null;
    this.label = label || null;
    this.postCode = postCode || null;
    this.cityName = cityName || null;
    this.countryCode = countryCode || null;
    this.familyCode = familyCode || null;
    this.agencyCode = agencyCode || null;
    this.customerUids = customerUids || [];
  }
}

class GetCustomerReferencesModel {
  constructor({ referenceLevel, value } = {}) {
    this.referenceLevel = referenceLevel || null;
    this.value = value || value;
  }
}

export { GetCustomersModel, GetCustomerReferencesModel };
