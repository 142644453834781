<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('input', false)" class="eureka-card-title--text">
          <v-icon>{{ themeIcon }}</v-icon>
        </v-btn>
        <v-toolbar-title class="eureka-card-title--text">Choix du thème</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn class="eureka-card-title--text" icon dark @click="$emit('input', false)">
            <v-icon>{{ clearIcon }}</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center wrap fill-height>
            <v-flex text-xs-center xs12>
              <span class="display-2">Options du thème</span>
            </v-flex>
            <v-flex text-xs-center xs12 mt-3 :style="{ 'background-color': 'aliceblue' }">
              <v-btn
                v-for="(option, index) in themeKeys"
                :key="`${option}'-'${index}`"
                :color="option"
                @click="selected = option"
                :outlined="option.indexOf('text__') === 0 || option !== selected"
              >
                {{ option }}
                <v-icon class="pl-2">{{ submitIcon }}</v-icon>
              </v-btn>
            </v-flex>

            <v-flex text-xs-center xs12 mt-3>
              <span class="display-2">Choix des couleurs</span>
            </v-flex>

            <v-flex xs12 sm8 mt-3>
              <v-layout align-start justify-center wrap>
                <template v-for="(color, key) in colors">
                  <v-flex xs4 md2 :key="`color-${key}`">
                    <v-btn class="template-color-button-block" block :color="key" text>{{ key }}</v-btn>
                  </v-flex>
                  <v-flex xs8 md10 :key="`${key}`">
                    <v-btn
                      :key="`${key}-${variant}`"
                      v-for="variant in color"
                      class="template-color-button"
                      @click="setColor(variant)"
                      :color="variant"
                      fab
                      small
                    ></v-btn>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>

            <v-flex xs12 sm4 mt-3>
              <v-layout align-start justify-center column>
                <v-flex xs12 sm4 mt-3>
                  <v-card>
                    <v-card-title>
                      <h3 class="headline mb-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" @click="exportToClipboard">
                              Exporter
                              <v-icon class="pl-2">{{ copyIcon }}</v-icon>
                            </v-btn>
                          </template>
                          <span>Copier les valeurs du template</span>
                        </v-tooltip>
                      </h3>
                    </v-card-title>
                    <v-card-text>
                      <pre>{{ customTemplate }}</pre>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-card>
  </v-dialog>
</template>

<script>
import Colors from './colors-mixin.vue';

export default {
  name: 'theme-modal',
  mixins: [Colors],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: Object,
      default: () => ({
        THEME_KEYS: {},
        ICONS: {},
      }),
    },
  },
  data() {
    const data = {
      selected: null,
    };
    this.theme.THEME_KEYS.forEach((k) => (data[k] = ''));
    return data;
  },
  methods: {
    setColor(variant) {
      if (!this.selected) return;
      this.$vuetifyTheme[this.selected] = variant;
      this[this.selected] = variant;
      this.refreshComputed();
    },
    exportToClipboard() {
      if (!this.$clipboard) {
        console.error('Clipboard plugin is not installed');
        return;
      }
      this.$clipboard(JSON.stringify(this.customTemplate));
    },
    refreshComputed() {
      // Hack to force vuetify to refresh theme
      const temp = this.$vuetifyTheme.primary;
      this.$vuetifyTheme.primary = '#FFF';
      this.$vuetifyTheme.primary = temp;
    },
  },
  computed: {
    $vuetifyTheme() {
      if (this.$vuetify.theme.dark) {
        return this.$vuetify.theme.themes.dark;
      } else {
        return this.$vuetify.theme.themes.light;
      }
    },

    clearIcon() {
      return this.theme.ICONS.clear;
    },
    themeIcon() {
      return this.theme.ICONS.theme;
    },
    submitIcon() {
      return this.theme.ICONS.submit;
    },
    copyIcon() {
      return this.theme.ICONS.copy;
    },
    themeKeys() {
      return this.theme.THEME_KEYS;
    },
    customTemplate() {
      return this.themeKeys.reduce((prev, curr) => ({ ...prev, [curr]: this[curr] }), {});
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.themeKeys.forEach((key) => {
          this[key] = this.$vuetifyTheme[key];
        });
      }
    },
  },
};
</script>

<style scoped>
.color-name {
  width: 35px;
}

.template-color-button-block {
  height: 25px !important;
  margin: 2px !important;
}

.template-color-button {
  height: 25px !important;
  width: 25px !important;
  margin: 2px !important;
}
</style>
