<template>
  <div style="display: none">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import propsBinder from '../utils/propsBinder.js';
import findRealParent from '../utils/findRealParent';
import './awesome-number-markers';

const props = {
  draggable: {
    type: Boolean,
    custom: true,
    default: false,
  },
  visible: {
    type: Boolean,
    custom: true,
    default: true,
  },
  latLng: {
    type: [Object, Array],
    custom: true,
  },
  icon: {
    type: String,
  },
  zIndexOffset: {
    type: Number,
    custom: false,
  },
  displayedNumber: {
    type: Number,
    default: -1,
  },
  color: {
    type: String,
    default: 'blue',
  },
  tooltip: {
    type: String,
    default: null,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
};
export default {
  name: 'LMarker',
  props: props,
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    const options = this.options;
    /*if (this.icon) {
      options.icon = this.icon;
    }*/
    options.draggable = this.draggable;

    this.instantiateMarker(options);

    this.mapObject.on('move', (ev) => {
      if (Array.isArray(this.latLng)) {
        this.latLng[0] = ev.latlng.lat;
        this.latLng[1] = ev.latlng.lng;
      } else {
        this.latLng.lat = ev.latlng.lat;
        this.latLng.lng = ev.latlng.lng;
      }
    });

    L.DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  methods: {
    instantiateMarker(options) {
      if ((this.icon && this.color) || (this.displayedNumber && this.color)) {
        let awesomeMarker = null;
        if (this.displayedNumber > 0) {
          awesomeMarker = new L.AwesomeNumberMarkers({
            number: this.displayedNumber,
            markerColor: this.color,
            spin: true,
          });
        } else {
          awesomeMarker = L.AwesomeMarkers.icon({
            icon: this.icon,
            markerColor: this.color,
            prefix: 'fa',
          });
        }
        options.icon = awesomeMarker;
      }
      this.mapObject = L.marker(this.latLng, options);

      if (this.tooltip) {
        this.mapObject.bindPopup(this.tooltip);
      }
    },
    // eslint-disable-next-line no-unused-vars
    setDraggable(newVal, oldVal) {
      if (this.mapObject.dragging) {
        newVal ? this.mapObject.dragging.enable() : this.mapObject.dragging.disable();
      }
    },
    setVisible(newVal, oldVal) {
      if (newVal == oldVal) return;
      if (this.mapObject) {
        if (newVal) {
          this.parentContainer.addLayer(this);
        } else {
          this.parentContainer.removeLayer(this);
        }
      }
    },
    setLatLng(newVal) {
      if (newVal == null) {
        return;
      }
      if (this.mapObject) {
        let oldLatLng = this.mapObject.getLatLng();
        let newLatLng = {
          lat: newVal[0] || newVal.lat,
          lng: newVal[1] || newVal.lng,
        };
        if (newLatLng.lat != oldLatLng.lat || newLatLng.lng != oldLatLng.lng) {
          this.mapObject.setLatLng(newLatLng);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.awesome-marker-icon-default {
  background: none !important;

  > i {
    margin-top: 1px !important;
    position: absolute;
    left: 13px;
    font-style: normal;
    &.large-number {
      left: 8px;
    }
  }
}
</style>
