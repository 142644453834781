<template>
  <v-data-table
    v-model="roundsSelected"
    :options="dataPagination"
    @update:options="setDataPagination"
    :footer-props="footerProps"
    :headers="tableHeaders"
    :items="rounds"
    class="elevation-1 rounds-grid"
    :loading="!loaded"
    item-key="id"
    :server-items-length="serverItemsCount"
    :show-select="isAllowedToDownloadRoundSheet"
    fixed-header
  >
    <template v-slot:[`header.data-table-select`]="{ props }">
      <v-checkbox
        :input-value="props.value"
        :indeterminate="props.indeterminate"
        color="primary"
        :label="`(${roundsSelected.length})`"
        @click.stop="toggleAll"
        hide-details
        class="mt-0"
      ></v-checkbox>
    </template>
    <template v-slot:[`header.transportSteps`]>
      <v-layout wrap align-start>
        <v-flex shrink>
          <v-icon small color="white" class="rounds-grid-timeline-header-planned">fas fa-circle</v-icon
          ><span class="ml-1">Planifiée</span>
        </v-flex>
        <v-flex shrink pl-1
          ><v-icon small color="success" class="rounds-grid-timeline-header-realized">fas fa-circle</v-icon
          ><span class="ml-1">Réalisée</span></v-flex
        >
        <v-flex shrink pl-1 v-if="isLateStatusBasedOnEstimatedSchedule">
          <v-icon small color="warning" class="rounds-grid-timeline-header-lateplanning">fas fa-circle</v-icon
          ><span class="ml-1">Retard planification</span>
        </v-flex>
        <v-flex shrink pl-1>
          <v-icon small color="error" class="rounds-grid-timeline-header-late">fas fa-circle</v-icon
          ><span class="ml-1">En retard</span>
        </v-flex>
      </v-layout>
    </template>

    <template v-slot:progress>
      <v-progress-linear :color="loaded ? 'white' : 'primary'" indeterminate height="1"></v-progress-linear>
    </template>

    <template v-slot:item="{ item, isSelected, isExpanded, select, expand }">
      <tr class="rounds-grid-row" :active="isSelected">
        <td @click="select(!isSelected)" v-if="isAllowedToDownloadRoundSheet">
          <v-checkbox :input-value="isSelected" color="primary" hide-details class="ml-3 mt-0"></v-checkbox>
        </td>
        <td class="rounds-grid-row-caret" @click="expand(!isExpanded)">
          <v-icon v-text="!isExpanded ? 'fas fa-angle-right' : 'fas fa-angle-down'"></v-icon>
        </td>
        <td class="text-center special-badge">
          <v-badge color="transparent" overlap>
            <template slot="badge" v-if="item.lastStepLateStatus !== LATE_STATUS.Undetermined">
              <v-icon :color="getClockColorFromStatus(item.lastStepLateStatus)" small> fas fa-clock </v-icon>
              <v-tooltip v-if="isItemHasLateStatus(item)" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-1" v-bind="attrs" v-on="on" :color="getClockColorFromStatus(item.lateStatus)" small>
                    fas fa-exclamation
                  </v-icon>
                </template>
                <span>Une étape est en retard</span>
              </v-tooltip>
            </template>
            <v-chip :color="getChipColorFromStatus(item.status)" text-color="white" class="rounds-grid-status">
              {{ getStatusLabel(item.status) }}
            </v-chip>
          </v-badge>
        </td>
        <td class="text-center">{{ item.id }}</td>
        <td>{{ item.label }}</td>
        <td class="text-right">
          {{ getDate(item.estimatedStartDateTime) }}<br />
          <b>{{ getTime(item.estimatedStartDateTime) }}</b>
        </td>
        <td class="text-right">
          {{ getDate(item.estimatedEndDateTime) }}<br />
          <b>{{ getTime(item.estimatedEndDateTime) }}</b>
        </td>
        <td class="text-center" v-if="hasDriverData">{{ item.driverLabel }}</td>
        <td class="text-center">{{ item.stepCount }}</td>
        <td class="text-center">
          <v-progress-circular
            :value="item.progressPercentage * 100"
            color="grey darken-2"
            :rotate="-90"
            size="38"
            class="rounds-grid-progress"
          >
            {{ getPercentage(item.progressPercentage) }}
          </v-progress-circular>
        </td>
        <td>
          <timeline :transport-steps="item.transportSteps" @point-click="expand(true)" v-model="targetId" />
        </td>
        <td v-if="isAllowedToSwitchToMapView" @click="showMap(item)" class="rounds-grid-row-action text-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="primary">fas fa-route</v-icon>
            </template>
            <span>Voir sur la carte</span>
          </v-tooltip>
        </td>
      </tr>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length">
        <detail
          :transport-steps="item.transportSteps"
          class="rounds-grid-row-expand"
          :max-height="rounds.indexOf(item) < rounds.length - 1"
          v-model="targetId"
        />
      </td>
    </template>

    <template v-slot:[`footer.page-text`]="props">
      {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import MomentMixin from '@/mixins/moment';
import StatusMixin from '@/mixins/status';
import NumbersMixin from '@/mixins/numbers';
import Detail from './detail';
import Timeline from './timeline';

export default {
  name: 'r-grid',
  mixins: [MomentMixin, StatusMixin, NumbersMixin],
  components: { Detail, Timeline },
  data() {
    return {
      footerProps: {
        'items-per-page-options': [5, 10, 25, 100],
        'items-per-page-text': '',
      },
      targetId: null,
      roundsSelected: [],
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(['hasDriverData', 'sortingFields']),
    ...mapState({
      rounds: (state) => state.rounds.rounds,
      serverItemsCount: (state) => state.rounds.pagination?.totalItemCount || 0,
      pagination: (state) => state.rounds.pagination,
      loaded: (state) => state.rounds.loaded,
      filters: (state) => state.filters.filters,
      isAllowedToDownloadRoundSheet: (state) => state.context.rights.downloadRoundSheet,
      isAllowedToSwitchToMapView: (state) => state.context.rights.mapAccess,
      dataPagination: (state) => state.rounds.dataPagination,
    }),
    tableHeaders() {
      const headers = [{ text: '', value: 'expandButton', sortable: false, width: '36px' }];
      headers.push(
        ...this.sortingFields.reduce((prev, curr) => {
          const current = { ...curr };
          if (current.value === 'status') {
            current.align = 'center';
            current.class = 'rounds-grid-header-status';
          } else if (
            current.value === 'id' ||
            current.value === 'stepCount' ||
            current.value === 'progressPercentage' ||
            current.value === 'driverLabel'
          ) {
            current.align = 'center';
          } else if (current.value === 'label') {
            current.align = 'left';
          } else if (current.value === 'estimatedStartDateTime' || current.value === 'estimatedEndDateTime') {
            current.align === 'right';
          }
          return [...prev, current];
        }, []),
      );
      headers.push({ text: '', value: 'transportSteps', sortable: false, width: '440px', key: 'timeline' });
      if (this.isAllowedToSwitchToMapView) {
        headers.push({ text: '', value: 'actionsButtons', sortable: false, key: 'actions' });
      }
      return headers;
    },
  },
  methods: {
    ...mapActions('map', ['selectRound']),
    ...mapMutations(['setRoundsSelected', 'setDataPagination']),

    showMap(round) {
      this.selectRound(round.id);
      this.$emit('show-map', true);
    },
    toggleAll() {
      if (this.roundsSelected.length) this.roundsSelected = [];
      else this.roundsSelected = this.rounds.slice();
    },
  },
  watch: {
    roundsSelected(newVal) {
      this.setRoundsSelected(newVal);
    },
  },
};
</script>
<style>
.v-data-table .v-data-table__wrapper {
  max-height: 86vh;
}

.v-data-table .v-data-table__wrapper thead.v-data-table-header > tr > th {
  z-index: 3;
}
</style>
