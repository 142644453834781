/* eslint-disable no-undef */
L.Icon.MarkerCluster = L.Icon.extend({
  options: {
    iconSize: new L.Point(30, 30),
    className: 'prunecluster leaflet-markercluster-icon',
  },

  colors: {
    blue: '#38a9dc',
    red: '#d43e2a',
    orange: '#f59630',
    green: '#71af26',
    purple: '#d051b8',
    pink: '#ff91e8',
    darkred: '#a13336',
    darkblue: '#0065a0',
    darkgreen: '#718123',
    darkpurple: '#5a386a',
    lightred: '#ff8d7e',
    lightblue: '#89dbff',
    lightgreen: '#bbf970',
    cadetblue: '#426877',
  },

  pi2: Math.PI * 2,

  createIcon: function () {
    const e = document.createElement('canvas');
    this._setIconStyles(e, 'icon');
    const s = this.options.iconSize;
    e.width = s.x;
    e.height = s.y;
    this.draw(e.getContext('2d'), s.x, s.y);
    return e;
  },

  createShadow: function () {
    return null;
  },
  getColors(stats) {
    return Object.keys(stats).reduce((prev, curr) => {
      if (stats[curr] <= 0) return prev;
      return [...prev, this.colors[curr] || curr];
    }, []);
  },

  areGreyColors(colors) {
    const color = colors[0];
    if (color !== '#9D9D9D' || colors.length !== 1) {
      return false;
		}
    return true;
	},

  // eslint-disable-next-line no-unused-vars
  draw: function (canvas, width, height) {
    const markersColors = this.getColors(this.stats);
    const isOverRound = this.areGreyColors(markersColors);

    if (!markersColors.length) console.warn('No color found for cluster marker', this.stats);
    let start = 0;

    if (isOverRound) {
      canvas.beginPath();
      canvas.moveTo(15, 15);
      canvas.fillStyle = '#9D9D9D';

      canvas.arc(15, 15, 15, 0, this.pi2);
      canvas.lineTo(15, 15);
      canvas.fill();
      canvas.closePath();
    }
    else {
      for (let i = 0; i < markersColors.length; ++i) {
        let size = 1 / markersColors.length;

        if (size > 0) {
          canvas.beginPath();
          canvas.moveTo(15, 15);
          canvas.fillStyle = markersColors[i];
          let from = size === 1 ? start : start + 0.14;
          let to = start + size * this.pi2;

          if (to < from) {
            from = start;
          }
          canvas.arc(15, 15, 15, from, to);

          start = start + size * this.pi2;
          canvas.lineTo(15, 15);
          canvas.fill();
          canvas.closePath();
        }
      }
    }

    if (isOverRound) {
      canvas.fillStyle = '#9D9D9D';
    }
    else {
      canvas.fillStyle = 'white';
    }

    canvas.beginPath();
    canvas.arc(15, 15, 12, 0, this.pi2);
    canvas.fill();
    canvas.closePath();

    // Anciennement affichage point regroup�
    // canvas.fillStyle = 'black';
    // canvas.textAlign = 'center';
    // canvas.textBaseline = 'middle';
    // canvas.font = this.population >= 100 ? 'bold 17px sans-serif' : 'bold 19px sans-serif';

    // canvas.fillText(this.population, 15, 17, 21);

    const img = document.createElement('img');
    if (isOverRound) {
      img.src = require('@/assets/magnifying-glass-location-white.svg');
    }
    else {
      img.src = require('@fortawesome/fontawesome-free/svgs/solid/magnifying-glass-location.svg');
    }
    img.onload = () => canvas.drawImage(img, 6, 6, 18, 18);
  },
});
