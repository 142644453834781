<template>
  <v-app style="min-width: 1515px">
    <v-btn
      v-if="showThemeBtn"
      @click="showThemeModal = true"
      color="accent"
      bottom
      right
      fab
      dark
      fixed
      class="theme-dialog-btn"
    >
      <v-icon>fas fa-palette</v-icon>
    </v-btn>

    <theme-modal v-model="showThemeModal" :theme="themeConfig" />
    <r-toolbar
      :show-map.sync="showMap"
      :late-status-planned-visible.sync="lateStatusPlannedVisible"
      :auto-refresh-enabled.sync="isAutoRefreshEnabled"
      show-download
    />
    <r-grid @show-map="showMap = $event" />
    <r-map v-model="showMap" />
    <session-expired-dialog />
    <v-snackbar v-model="snackBarRefreshIsActivated" :multi-line="true" bottom right timeout="-1" color="primary">
      Le rafraichissement automatique a été désactivé
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackBarRefreshIsActivated = false">Ok</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { theme } from '@/business';
import { cloneDeep } from '@/business/utils';
import RGrid from '@/components/grid/grid';
import RToolbar from '@/components/toolbar.vue';
import SessionExpiredDialog from '@/components/session-expired-dialog.vue';
import RMap from '@/components/rounds-map/index.vue';
import ThemeModal from '@/components/theme-modal/index.vue';

export default {
  name: 'rounds-app',
  components: {
    RGrid,
    RToolbar,
    SessionExpiredDialog,
    RMap,
    ThemeModal,
  },
  data() {
    return {
      showThemeModal: false,
      enableRefreshAuto: false,
      maxRetry: 5,
      getRoundsTimer: null,
      inactivityTimer: null,
      debouncedGetRounds: null,
      innerShowMap: false,
      isAutoRefreshEnabled: false,
      snackBarRefreshIsActivated: false,
    };
  },
  created() {
    this.initContext();
  },
  async mounted() {
    this.debouncedGetRounds = debounce(() => this.registerGetRoundsTimer(), 700);
    await this.initTheme();
  },
  computed: {
    ...mapState({
      filters: (state) => state.filters.filters,
      transportRefreshRate: (state) => state.context.settings.refreshInMinutes * 60000,
      themeFileUrl: (state) => state.context.settings.themeFileUrl,
      pagination: (state) => {
        const pagination = state.rounds.dataPagination;
        const sort = pagination.sortBy.slice(0);
        const desc = pagination.sortDesc.reduce((prev, isDesc, index) => (isDesc ? [...prev, sort[index]] : prev), []);
        return {
          startIndex: pagination.page - 1,
          count: pagination.itemsPerPage,
          sort,
          desc,
        };
      },
      hasMapAccess: (state) => state.context.rights.mapAccess,
      showThemeBtn: (state) => state.context.settings.showThemeButton,
      isLateStatusBasedOnEstimatedSchedule: (state) => state.context.settings.isLateStatusBasedOnEstimatedSchedule,
      inactivityDelayMs: (state) => state.context.settings.inactivityDelayInMinutes * 60000,
    }),
    showMap: {
      get() {
        return this.innerShowMap;
      },
      set(value) {
        this.innerShowMap = !this.hasMapAccess ? false : value;
      },
    },
    lateStatusPlannedVisible: {
      get() {
        return this.isLateStatusBasedOnEstimatedSchedule;
      },
      set(value) {
        this.toggleLateStatusPlannedVisibility(value);
      },
    },
    themeConfig() {
      return theme;
    },
  },
  methods: {
    ...mapActions(['getRounds', 'initContext', 'toggleLateStatusPlannedVisibility']),

    async fetchFile(fileName) {
      if (!fileName || !this.themeFileUrl) return;

      const file = `${this.themeFileUrl}/${fileName}.json`;
      const fetchedFiled = await fetch(file);

      if (!fetchedFiled || fetchedFiled.status === 404) {
        return null;
      }

      return await fetchedFiled.json();
    },

    async initTheme() {
      const theme = await this.fetchFile('theme');
      if (!theme) return;

      this.themeConfig.THEME_KEYS.forEach((key) => {
        this.setThemeValueByKey(key, theme[key]);
      });
    },

    setThemeValueByKey(key, themeValue) {
      if (!themeValue || !key) return;
      if (this.$vuetify.theme.dark) {
        this.$vuetify.theme.themes.dark[key] = themeValue;
      } else {
        this.$vuetify.theme.themes.light[key] = themeValue;
      }
    },

    registerGetRoundsTimer(transportRefreshRate = 0, retry = 0) {
      clearTimeout(this.getRoundsTimer);
      if (retry >= this.maxRetry) return;
      this.getRoundsTimer = setTimeout(async () => {
        try {
          await this.getRounds({
            ...this.pagination,
            ...cloneDeep(this.filters),
          });
          retry = 0;
        } catch (err) {
          console.warn('Error retrieving rounds: ', err);
          retry++;
        } finally {
          if (retry || this.isAutoRefreshEnabled) {
            this.registerGetRoundsTimer(this.transportRefreshRate, retry);
          }
        }
      }, transportRefreshRate);
    },
    registerInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(() => {
        clearTimeout(this.getRoundsTimer);
        this.isAutoRefreshEnabled = false;
      }, this.inactivityDelayMs);
    },
  },
  watch: {
    // Triggers the initial search
    // Because Vuetify data-table triggers an update on pagination config
    pagination: {
      deep: true,
      handler() {
        this.debouncedGetRounds();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.debouncedGetRounds();
      },
    },
    isAutoRefreshEnabled(isEnabled) {
      if (isEnabled) {
        this.snackBarRefreshIsActivated = false;
        this.debouncedGetRounds();
        this.registerInactivityTimer();
      } else {
        this.snackBarRefreshIsActivated = true;
        clearTimeout(this.inactivityTimer);
        clearTimeout(this.getRoundsTimer);
      }
    },
  },
};
</script>
<style lang="scss">
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>
