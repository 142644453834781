import { GetCustomersModel, GetCustomerReferencesModel } from '../models/customers';
import { SearchPatternQuery, SimpleCodeLabelSearchQuery } from './query';
class GetCustomersQuery extends SimpleCodeLabelSearchQuery {
  constructor(params) {
    if (!params.count) params.count = 30;
    super(params);
	this.fields.push('uid');
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.Exact;
    this.applySearchPatternTo.push('postCode', 'cityName', 'countryCode', 'familyCode', 'agencyCode');
    this.setPropsFromModel(new GetCustomersModel(params));
  }
}

class GetCustomerReferencesQuery extends SearchPatternQuery {
  constructor(params) {
    if (!params.count) params.count = 100;
	if (!params.fields) params.fields = ['value'];
    super(params);
    this.defaultSearchPattern = SearchPatternQuery.SearchPatterns.StartsWith;
    this.applySearchPatternTo.push('value');
    this.setPropsFromModel(new GetCustomerReferencesModel(params));
  }
}

export { GetCustomersQuery, GetCustomerReferencesQuery };
